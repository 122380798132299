import { ConnectionBroker } from 'core';

export const installerSystems = {
  state: {
    systems: [],
    currentDevice: null,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setCurrentDevice(state: any, payload: any) {
      return { ...state, currentDevice: payload };
    },
  },
  effects: () => ({
    async listAllSystems() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.listSystems',
      );
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'systems');
      } else {
        // TODO: add error handler
      }
      return response?.body;
    },
    //useful to get current system and refresh through page reloads
    async getDeviceDetails(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'installer.showSystem',
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.setCurrentDevice(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async deleteSystem(data: any) {
      const that: any = this;
      if (data && data.system_id) {
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'installer.deleteSystem',
          data,
        );
        if (response?.statusCode === 200) {
          await that.listAllSystems();
        } else {
          // TODO: add error handler
        }
        return response;
      }
    },
  }),
};
