import { ConnectionBroker } from '../../core';


export const systemSettings = {
  state: {
    systemSettings: [],
    editSystemSettings: null,
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setSystemSettings(state: any, payload: any) {
      return { ...state, systemSettings: payload };
    },
    resetSystemSettings(state: any) {
      return { ...state, editSystemSettings: null };
    },
  },
  effects: (dispatch: any) => ({
    async getAllSystemSettings() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.list',
        null,
        { merge: true },
      );
      if (response?.statusCode === 200) {
        that.updateState(response.body, 'systemSettings');
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async getAllSystemSettingsNoMerge() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.list',
      );
      if (response?.statusCode === 200) {
        return response.body;
      }
      return response?.body ? response.body : response;
    },
    async getSystemSettings(data: any) {
      const that: any = this;
      let _data: any = { id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.show',
        null,
        _data,
      );
      if (response?.statusCode === 200) {
        that.setSystemSettings(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async getSystemSettingsBySysId(data: any) {
      const that: any = this;
      let _data: any = { system_id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.showBySysId',
        null,
        _data,
      );
      if (response?.statusCode === 200) {
        that.setSystemSettings(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async showSystemSettings(data: any) {
      let _data: any = { system_id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.showBySysId',
        null,
        _data,
      );

      if (response?.statusCode === 200) {
        return response.body;
      }

      return response;
    },
    async editSystemSettings(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.update',
        data,
      );
      if (response?.statusCode === 200) {
        that.setSystemSettings(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async handleUpdateSystemSettings(payload: any){
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.update',
        { ...payload },
      );

      if (response?.statusCode === 200) {
        return response.body;
      }

      return null;
    },
    async deleteSystemSettings(data: object) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSettings.delete',
        data,
      );
      if (response?.statusCode === 200) {
        await that.getAllSystemSettings();
      } else {
        // TODO: add error handler
      }
    },
    // Setup calls --  START  --
    async getAllSystemSetup() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSetup.list',
      );
      if (response?.statusCode === 200) {
        return { ...response.body };
      } else {
        // TODO: add error handler
      }
      return null;
    },
    async getSystemSetup(data: any) {
      const that: any = this;
      let _data: any = { id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSetup.show',
        null,
        _data,
      );
      if (response?.statusCode === 200) {
        const setup: object = response?.body?.setup
          ? { ...response.body.setup }
          : {};
        return { ...setup };
      } else {
        // TODO: add error handler
      }
      return null;
    },
    async getSystemSetupBySysId(data: any) {
      const that: any = this;
      let _data: any = { system_id: data.id };
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSetup.showBySysId',
        null,
        _data,
      );
      if (response?.statusCode === 200) {
        const setup: object = response?.body?.setup
          ? { ...response.body.setup }
          : {};
        return { ...setup };
      } else {
        // TODO: add error handler
      }
      return null;
    },
    async editSystemSetup(data: any) {
      const that: any = this;
      const isLocalSetup: boolean = !!(data?.setup?.indoorAir?.hasLocal);
      const _data: any = { id: data.system_id };
      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSetup.update',
        data,
        {
          check: true,
          isLocalSetup: isLocalSetup,
        },
      );
      if (response?.statusCode === 200) {
        let resp: any = await that.getSystemSetupBySysId(_data);
        return resp ? { ...resp } : {};
      } else {
        // TODO: add error handler
      }
      return null;
    },
    async handleUpdateSystemSetup(payload: any){
      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSetup.update',
        { ...payload },
      );

      if (response?.statusCode === 200) {
        return response ? { ...response } : {};
      }

      return null;
    },
    async deleteSystemSetup(data: any) {
      const that: any = this;
      let response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systemSetup.delete',
        data,
      );
      if (response?.statusCode === 200) {
        let resp: any = await that.getSystemSetup(data);
        return resp ? { ...resp } : null;
      } else {
        // TODO: add error handler
      }
    },
    // Setup calls --  END  --

    async updateSystemSettingsFlag(data: any) {
      const that: any = this;
      const { device_id, flagState }: any = data || {};
      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'systems.updateSettingsFlag',
        { flagState },
        { device_id },
      );

      if (response?.statusCode === 200) {
        return response?.data;
      }

      return null;
    },


  }),
};
