import React, { useState, useEffect } from 'react';
import { FaFan } from 'react-icons/fa';
import numeral from 'numeral';
import './styles.scss';



interface FreshConnectStateProps {
  index: number;
  title: string;
  subTitle?: string;
  fan?: any;
  stoped?: any;
  devData: any;
}

const FreshConnectState: React.FC<FreshConnectStateProps> = ({
  index,
  title,
  subTitle,
  fan,
  stoped,
  ...props
}) => {
  const [wattage, setWattage]: any = useState(null);


  useEffect(() => {
    const { irms, vrms }: any = props?.devData || {};

    if(
      irms !== null &&
      irms !== undefined &&
      vrms !== null &&
      vrms !== undefined
    ){
      let wattResult: number = irms * vrms;

      if(wattResult > 0){
        wattResult = numeral(wattResult).format('0.0000');
      }
      setWattage(wattResult);
    }
  }, [props?.devData]);


  return (
    <div key={'conn-' + index} className="fresh-conn-wrapper">
      <div className="widgets--title-wrapper">
        <h4 className="widgets--title">{title}</h4>
        <h6 className="widgets--subtitle">{subTitle}</h6>
      </div>
      <div className="icons-container">
        <FaFan className={fan && !stoped ? 'rotate' : ''} size="2.3rem" />
        <div className="light-on-wrapper"></div>
      </div>
      {
        wattage !== null && wattage !== undefined  &&
          <>
          <h6 className="widgets--wattage">
            {wattage}
          </h6>
          <h6 className="widgets--wattage-unit">
            Watts
          </h6>
        </>
      }
    </div>
  );
};

export default FreshConnectState;
