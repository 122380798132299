import React from 'react';

const isOb = (item: any) => {
  if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
    return true;
  } else return false;
};

const handleImgErr = (event: any) => {
  event.target.src = '/avatar.png';
};

const valIsBool = (val: any) => {
  return typeof val === 'boolean' ? true : false;
};

const renderLargerLabel = (key: string, value: any) => {
  switch (key) {
    case 'station':
    case 'info':
    case 'firmwareVersions':
    case 'fwUpgradeStatus':
    // case 'sensorsStatus':
    case 'sensorsStats':
    case 'hum':
    case 'tmp':
    case 'co2':
    case 'voc':
    case 'pm':
    case 'nearestOutdoorStation':
      return (
        <div className="col-12 pdg inline-label lead lead-sm label-result-sp aliceblue-label">
          <span className="lead span-key-040 bolder">{value}</span>
        </div>
      );

    default:
      return <></>;
  }
};

const getCol = (_k: string) => {
  return _k === 'start' ||
    _k === 'end' ||
    _k === 'sensors' ||
    _k === 'units' ||
    _k === 'datapoints' ||
    _k === 'sensors' ||
    _k === 'units'
    ? '-12'
    : '-6';
};

const valueIsStrOrNum = (val: any) => {
  return typeof val === 'string' || typeof val === 'number' ? true : false;
};

const handleNoValues = (key?: string) => {
  if (
    key &&
    (key === 'address_line_two' ||
      key === 'device_city' ||
      key === 'device_state' ||
      key === 'device_zipcode' ||
      key === 'isSystem')
  ) {
    return (
      <div className="form-control input-alike">
        <span className="lead span-key-040">{`No information to display`}</span>
      </div>
    );
  }
  if (key && key === 'picture') return null;
  return <span className="lead span-key-040 pdg">No values to display</span>;
};

const specSection = (key: string) => {
  return key === 'datapoint' || key === 'airQuality' || key === 'health'
    ? true
    : false;
};

const handleInitials = (itemName: string) => {
  let first: string = '';
  let second: string | undefined | null;

  if (itemName && itemName?.length > 0) {
    first = itemName.split(' ')[0];

    if (itemName.split(' ').length > 1) {
      second = itemName.split(' ')[1];
    }
  }

  return {
    first: first && first?.length > 0 ? first[0] : '',
    second: second && second?.length > 0 ? second[0] : '',
  };
};

export const helpers = {
  isOb: isOb,
  handleImgErr: handleImgErr,
  renderLargerLabel: renderLargerLabel,
  getCol: getCol,
  valueIsStrOrNum: valueIsStrOrNum,
  valIsBool: valIsBool,
  handleNoValues: handleNoValues,
  specSection: specSection,
  handleInitials: handleInitials,
};
