//@ts-nocheck
import { config } from './config';
import moment from 'moment';
const WIFI_CONN_DIFF: number = 30; // in seconds

interface ObjType {
  [key: string]: any;
}

const relevantProps = ['cdvs', 'fcst', 'dmst', 'cfst', 'cfcm'];

//will receive dev?.details?.settings?.conf as parameter
const filterObject = (conf: any) => {
  // console.log('RECEIVED CONF: ', conf);
  return Object.keys(conf)
    .filter((key: string) => relevantProps.includes(key))
    .reduce((obj: ObjType, key) => {
      obj[key] = conf[key];

      // console.log('the resulting object on switches: ', obj);
      return obj; // will return an obj with these props: ['cdvs', 'fcst', 'dmst', 'cfst', 'cfcm']
    }, {});
};

const arrayHasElements = (array: any) => {
  if (array && array?.length > 0) {
    return true;
  }
  return false;
};

const toggleActive = (current: string) => {
  if (config.tabsObject && config.tabsObject.hasOwnProperty(current)) {
    return config.tabsObject[current as keyof typeof config.tabsObject];
  }
};

const connSysReady = (sysWithConnectionInfo: any, systemsQuantity: number) => {
  if (
    sysWithConnectionInfo &&
    systemsQuantity &&
    systemsQuantity === sysWithConnectionInfo.length
  ) {
    return true;
  } else {
    return false;
  }
};

const returnTitle = (section: string) => {
  if (config.sections.hasOwnProperty(section)) {
    return config.sections[section as keyof typeof config.sections];
  }
};

const returnLead = (section: string) => {
  if (config.leads.hasOwnProperty(section)) {
    return config.leads[section as keyof typeof config.leads];
  }
};

const handleAllSensorsList = (
  tempDevices: any,
  foobot: any,
  awair: any,
  airthings: any,
) => {
  let allSensors: any = [];
  if (foobot && foobot.length > 0) {
    allSensors = allSensors.concat(foobot);
  }

  if (airthings && airthings.length > 0) {
    allSensors = allSensors.concat(airthings);
  }

  if (awair && awair.length > 0) {
    allSensors = allSensors.concat(awair);
  }

  if (tempDevices['awair-element'] && tempDevices['awair-element'].length > 0) {
    allSensors = allSensors.concat(tempDevices['awair-element']);
  }

  if (tempDevices['awair-omni'] && tempDevices['awair-omni'].length > 0) {
    allSensors = allSensors.concat(tempDevices['awair-omni']);
  }

  if (
    tempDevices['aircycler-sensor'] &&
    tempDevices['aircycler-sensor'].length > 0
  ) {
    allSensors = allSensors.concat(tempDevices['aircycler-sensor']);
  }

  return allSensors;
};

const isOKResponse = (resp: any) => {
  if (resp && resp.statusCode === 200 && resp?.body?.data) {
    return true;
  } else return false;
};

const handleTimeMessage = (time: any) => {
  let newMsm: any;

  if (time < 60) {
    newMsm = `${time} ${time > 1 ? 'seconds' : 'second'}`;
  } else if (time >= 60) {
    let minCalcs: number = time / 60;
    newMsm = `${parseInt(minCalcs)} ${
      parseInt(minCalcs) > 1 ? 'minutes' : 'minute'
    }`;
  } else if (time >= 60 * 60) {
    let hourCalcs: number = time / 60 / 60;
    newMsm = `${parseInt(minCalcs)} ${
      parseInt(hourCalcs) > 1 ? 'hours' : 'hour'
    }`;
  } else if (time >= 60 * 60 * 24) {
    let daysCalcs: number = time / 60 / 60 / 24;
    newMsm = `${parseInt(daysCalcs)} ${
      parseInt(daysCalcs) > 1 ? 'days' : 'day'
    }`;
  }

  return newMsm;
};

const fillIndoorAirDetails = (sensorsList: any) => {
  let indoorAirDetails: any = [];
  sensorsList.forEach((element: any) => {
    indoorAirDetails.push({
      deviceId: element.device_id, //sensor Id
      pm: element.details.data.datapoints[0][1],
      co2: element.details.data.datapoints[0][4],
      tVoc: element.details.data.datapoints[0][5],
    });
  });

  return indoorAirDetails;
};

const handleSettings = (
  devSett: any,
  sensorsList: any,
  sensorsAreLoaded: boolean,
) => {
  let data = {};
  let checkIfSensorExistsInList: any = [];
  //some additional validation to confront to existing sensors list,
  // just to make sure that we do not pass deleted sensors, and prevent errors

  if (devSett?.body?.data.hasOwnProperty('message')) {
    data = {
      sensorName: '',
      sensorId: '',
    };

    return data;
  } else if (Object.keys(devSett?.body?.data?.settings).length === 0) {
    data = { ...data, sensorName: 'MachineShop' };
    return data;
  } else {
    let _settings: any = Object.entries(devSett?.body?.data?.settings)[0][1];

    if (_settings && _settings.hasOwnProperty('add_sensor_id')) {
      //confront this info with the sensors list because sometimes there are information about already deleted sensors

      if (sensorsAreLoaded) {
        checkIfSensorExistsInList = sensorsList.filter(
          (item: any) => item.device_id === _settings['add_sensor_id'],
        );

        if (checkIfSensorExistsInList.length === 0) {
          data = { ...data, sensorId: '', sensorName: '' };
          return data;
        }
      }
    }

    let ob: any = Object.entries(devSett?.body?.data?.settings)[0][1];

    if (ob.hasOwnProperty('add_sensor')) {
      data = { ...data, sensorName: ob['add_sensor'] };
    }
    if (ob.hasOwnProperty('add_sensor_id')) {
      data = { ...data, sensorId: ob['add_sensor_id'] };
    }

    return data;
  }
};

const formatZipCodes = (zipCodes: any) => {
  return zipCodes.map((zpCode: any) => {
    const { zip } = zpCode;
    return {
      ...zpCode,
      value: zip,
      label: zip,
    };
  });
};

const formatLocations = (data: any) => {
  return data.map((item: any) => {
    const { name, isoCode } = item;
    return {
      ...item,
      label: name,
      value: isoCode ? isoCode : name,
    };
  });
};

const mapSensorType = (devType: string) => {
  switch (devType) {
    case 'aircycler-local':
      return 'aircycler-sensor';
    default:
      return devType;
  }
};

const filterDevicesByType = (_devices: any) => {
  let filterdDevices: any = {};

  if (_devices && Array.isArray(_devices)) {
    _devices.forEach((_device: any) => {
      const tempType: string = helpers.mapSensorType(_device?.device_type);

      if (!filterdDevices[tempType]) {
        filterdDevices[tempType] = [];
      }
      filterdDevices[tempType].push({ ..._device });
    });
  }

  return filterdDevices;
};

const handleImgErr = (event: any) => {
  event.target.src = '/avatar.png';
};

const handleField = (field: any) => {
  switch (field) {
    case 'user_state':
      return 'state';
    case 'user_city':
      return 'city';
    case 'user_zipcode':
      return 'zipcode';
    default:
      return '';
  }
};

const hasValidDate = (elem: any) => {
  const { createdDate } = elem;
  const nowDate: any = moment();
  const devDate: any = moment(new Date(createdDate));
  return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
};

const extractPeripherals = (_details: any) => {
  const { conf } = _details?.settings || {};
  const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];

  return {
    ventConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerVC'),
    ),
    fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
    damper: true,
    pressureConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerPC'),
    ),
    freshConnect: cdvsClone.filter(
      (item: any) =>
        item.cdid.startsWith('AirCyclerFH') ||
        item.cdid.startsWith('AirCyclerFT'),
    ),
    centralFan: true,
  };
};

const DEFAULT_EXTREMS = {
  pm25: {
    max: 500,
    min: 0,
  },
  co2: {
    max: 4000,
    min: 0,
  },
  tVoc: {
    max: 2000,
    min: 0,
  },
};

const prepareCkboxStates = (connectedDevs: any) => {
  let tempStates: any = {};
  if (Object.values(connectedDevs).length > 0) {
    Object.keys(connectedDevs).forEach((key: string) => {
      if (key === 'fanConnect') {
        tempStates.fanConnect = false;
      } else if (key === 'damper') {
        tempStates.dmst = false;
      } else if (key === 'centralFan') {
        tempStates.cfcm = false;
      } else {
        connectedDevs[key].forEach((cDev: any) => {
          const { cdid } = cDev;
          if (!tempStates[key]) tempStates[key] = {};
          tempStates[key][cdid] = false;
        });
      }
    });
  }
  return tempStates;
};

const prepareCkboxStatesBySection = (connectedDevs: any) => {
  const defaultSections = Object.keys(DEFAULT_EXTREMS).map(
    (section: string) => section,
  );
  let cksDefaults: any = {};
  defaultSections.forEach((prop: string) => {
    cksDefaults[prop] = prepareCkboxStates(connectedDevs);
  });

  return cksDefaults;
};

const settingsExist = (statusCode: number, sysSettings: any) => {
  if (
    statusCode === 200 &&
    sysSettings &&
    sysSettings?.body &&
    sysSettings?.body?.data &&
    sysSettings?.body?.data?.settings &&
    Object.keys(sysSettings?.body?.data?.settings).length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const helpers = {
  arrayHasElements: arrayHasElements,
  connSysReady: connSysReady,
  fillIndoorAirDetails: fillIndoorAirDetails,
  isOKResponse: isOKResponse,
  returnTitle: returnTitle,
  returnLead: returnLead,
  toggleActive: toggleActive,
  handleAllSensorsList: handleAllSensorsList,
  handleTimeMessage: handleTimeMessage,
  handleSettings: handleSettings,
  formatZipCodes: formatZipCodes,
  formatLocations: formatLocations,
  mapSensorType: mapSensorType,
  filterDevicesByType: filterDevicesByType,
  handleImgErr: handleImgErr,
  handleField: handleField,
  hasValidDate: hasValidDate,
  extractPeripherals: extractPeripherals,
  prepareCkboxStatesBySection: prepareCkboxStatesBySection,
  settingsExist: settingsExist,
  filterObject: filterObject,
};
