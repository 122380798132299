import React from 'react';
import { connect } from 'react-redux';
import { SummaryProps } from './interface';
import _ from 'lodash';
import numeral from 'numeral';
import { TempDisplay } from '../';
import { TooltipView, SwitchBtn, Alert } from 'business/modules/admin/common';
import { BsQuestionCircle } from 'react-icons/bs';
import { CiWarning } from 'react-icons/ci';
import Skeleton from '@material-ui/lab/Skeleton';

const Summary: React.FC<SummaryProps> = ({
  connection,
  config,
  endedLoadingSysSetts,
  clickWarningSign,
  mayDisplayBtn,
  systemsCurrentStatus,
  enabled,
  toggleSection,
  currentFoDev,
  currentFDChecked,
  sensorsAreLoaded,
  sensorsList,
  renderIndoorAirValues,
  device,
  isSensorSet,
  widgetStates,
  getSensorName,
}) => {
  return (
    <div className={`container admin-section-container i-air-accordion`}>
      <div className="row acc-row mh-55">
        <div
          className={`col-xs-12 col-sm-12 col designation-side ${
            !connection ? 'no-connection' : ''
          }`}
        >
          <div className="row designation">
            <div className="col-auto acc-row main-label">
              <p className="title-lbl">{config.indoorAir.strings.title}</p>
            </div>
            <div className="col-auto acc-row cnt-mt">
              <div className="a-i">
                <div className="b-i">
                  <TooltipView
                    title={
                      <>
                        <span className="tooltip-ft">
                          {config?.strings?.title}
                        </span>
                        <p className="text-p">{config?.strings?.p}</p>
                      </>
                    }
                  >
                    <span className="icon-tooltip">
                      <BsQuestionCircle className="" size="0.8rem" />
                    </span>
                  </TooltipView>
                </div>
              </div>
            </div>
            <div className="col-auto acc-row sensor-designation">
              <div className="local-data">
                {connection && <span className="">{getSensorName()}</span>}
              </div>
            </div>
            <div className="col-auto ci-warn">
              {!isSensorSet && endedLoadingSysSetts && (
                <TooltipView
                  title={
                    <>
                      <span className="tooltip-ft">
                        {config?.tooltip?.title}
                      </span>
                      <p className="text-p">{config?.tooltip?.str1}</p>
                    </>
                  }
                >
                  <span>
                    <CiWarning
                      className="ci-warn-icon"
                      size="1rem"
                      onClick={clickWarningSign}
                    />
                  </span>
                </TooltipView>
              )}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-auto acc-row variable-width">
          <TooltipView
            title={
              <>
                <span className="tooltip-ft">{config.tooltip?.title1}</span>
                <p className="text-p">{config.tooltip.str3}</p>
              </>
            }
          >
            <div className="row switch--btn">
              {mayDisplayBtn ? (
                <SwitchBtn
                  disabled={
                    systemsCurrentStatus &&
                    Object.keys(systemsCurrentStatus).length > 0 &&
                    systemsCurrentStatus.hasOwnProperty(device?.device_id) &&
                    systemsCurrentStatus[device?.device_id].statusIsLoaded &&
                    systemsCurrentStatus[device?.device_id].stopped
                      ? true
                      : false
                  }
                  id={``}
                  value={enabled}
                  onClick={toggleSection}
                  className={`enable-disable-admin btn-mt`}
                />
              ) : (
                <>
                  <div className="xs-and-sm-screens-only">
                    <Skeleton variant="text" height={30} width={'20%'} />
                  </div>
                  <div className="md-and-above-screens-only">
                    <Skeleton variant="text" height={30} width={'60%'} />
                  </div>
                </>
              )}
            </div>
          </TooltipView>

          <div
            id="switch-box-ia"
            className={`row containing-switch ${
              connection ? `with-conn` : `no-conn1`
            }`}
          >
            {!connection && null}
          </div>
        </div>
      </div>
      <div className="row">
        {!currentFoDev && !currentFDChecked ? (
          <TempDisplay
            item={
              <Skeleton
                variant="text"
                height={60}
                width={'100%'}
                style={{ float: 'left' }}
              />
            }
          />
        ) : connection && sensorsAreLoaded && sensorsList.length > 0 ? (
          <>
            {renderIndoorAirValues(device?.device_id) === '' ? (
              <div className="container acc-row">
                <div className="row">
                  {config.noIndoorValues.map((elem: any) => {
                    return (
                      <div className={elem.divClass} key={elem.id}>
                        <span
                          className={`
                          ${elem.spanClass}
                          ${enabled ? `` : `sensor--vals--off`}
                          `}
                        >
                          {elem.child}
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div className="row">
                  {config.indoorAirLabels.map((elem: any) => {
                    return (
                      <div className={elem.divClass} key={elem.id}>
                        <span
                          className={`${elem.spanClass} ${
                            enabled ? `` : `sensor--vals--off`
                          }`}
                        >
                          {elem.child}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                {mayDisplayBtn && sensorsAreLoaded && (
                  <div className="container acc-row">
                    <div className="row">
                      <div className={`col-4 left-side acc-row`}>
                        <span
                          className={`${
                            enabled
                              ? `values-label-v-001 left-labels`
                              : `values-label-v-001-off left-labels`
                          }`}
                        >
                          {isSensorSet ? (
                            <>
                              {numeral(widgetStates?.pm25?.current).format(
                                config.formats.ugm3,
                              )}{' '}
                              {config?.units?.ugm}
                            </>
                          ) : (
                            <> _ _ {config?.units?.ugm}</>
                          )}
                        </span>
                      </div>
                      <div className={`col-4 acc-row`}>
                        <span
                          className={`${
                            enabled
                              ? `values-label-v-001`
                              : `values-label-v-001-off`
                          }`}
                        >
                          {isSensorSet ? (
                            <>
                              {' '}
                              {numeral(widgetStates?.co2?.current).format(
                                config.formats.ppm,
                              )}{' '}
                              {config?.units?.ppm}
                            </>
                          ) : (
                            <>_ _ {config?.units?.ppm}</>
                          )}
                        </span>{' '}
                      </div>
                      <div className={`col-4 right-side acc-row`}>
                        <span
                          className={`${
                            enabled
                              ? `values-label-v-001 right-labels`
                              : `values-label-v-001-off right-labels`
                          }`}
                        >
                          {isSensorSet ? (
                            <>
                              {' '}
                              {numeral(widgetStates?.tVoc?.current).format(
                                config.formats.ppb,
                              )}{' '}
                              {config?.units?.ppb}{' '}
                            </>
                          ) : (
                            <>_ _ {config?.units?.ppb} </>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      {config.indoorAirLabels.map(
                        (elem: any, index: number) => {
                          return (
                            <div className={elem.divClass} key={index}>
                              <span
                                className={`                                          
                                 ${
                                   elem.spanClass
                                 }                                          
                                ${enabled ? `` : `sensor--vals--off`} `}
                              >
                                {elem.child}
                              </span>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : connection && !sensorsAreLoaded ? (
          <TempDisplay
            item={
              <Skeleton
                variant="text"
                height={60}
                width={'100%'}
                style={{ float: 'left' }}
              />
            }
          />
        ) : connection && sensorsAreLoaded && sensorsList.length === 0 ? (
          <Alert>No foobots to show.</Alert>
        ) : null}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminDevicesState } = state;
  return {
    endedLoadingSysSetts: adminDevicesState.endedLoadingSysSetts,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminSectionsState } = dispatch;
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
