import React, { useState, useEffect, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { SearchResultsProps } from './interface';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Template, List, Alert, Modal } from 'business/modules/admin/common';
import { ResultDetails, Collapsible, SensorData, Content } from './components';
import { FaUserCircle, FaFan } from 'react-icons/fa';
import { RxMagnifyingGlass } from 'react-icons/rx';
import {
  MdOutlineSensors,
  MdBusinessCenter,
  MdErrorOutline,
} from 'react-icons/md';
import './styles.scss';

const SearchResults: React.FC<SearchResultsProps> = ({
  searchResults,
  setCurrentResult,
  currentResult,
  defResult,
  resultsLoaded,
  inputSearch,
  errorOnResults,
  orderedCurrResult,
}) => {
  const history = useHistory();

  const resultType = {
    company: <MdBusinessCenter className="result-icon" />,
    user: <FaUserCircle className="result-icon" />,
    device: <FaFan className="result-icon" />,
    sensor: <MdOutlineSensors className="result-icon" />,
  };

  const [accStates, setAccStates] = useState({});

  const handleView = async (element: any) => {
    defResult(element);
  };

  const displayResultTypeIcon = (item: any) => {
    return (
      resultType[handleResultType(item) as keyof typeof resultType] || <></>
    );
  };

  const handleResultType = (item: any) => {
    if (item.hasOwnProperty('account_id')) {
      return 'user';
    }
    if (item.hasOwnProperty('device_id')) {
      return item?.hasOwnProperty('uuid') ||
        (item?.hasOwnProperty('details') &&
          item?.details &&
          item?.details?.hasOwnProperty('uuid')) ||
        item.hasOwnProperty('health') ||
        item?.hasOwnProperty('mac') ||
        item.hasOwnProperty('datapoint')
        ? 'sensor'
        : 'device';
    }
    if (item.hasOwnProperty('company_id')) {
      return 'company';
    } else return '';
  };

  const handleLocation = (item: any, resultType: string) => {
    switch (resultType) {
      case 'user':
        return item &&
          item?.addresses?.length > 0 &&
          item.addresses[0]?.state &&
          item?.addresses[0]?.city ? (
          <>
            {item.addresses[0]?.city}, {item.addresses[0]?.state}{' '}
          </>
        ) : (
          ''
        );

      case 'device':
        return item &&
          item?.device_state &&
          item?.device_state?.name &&
          item?.device_city &&
          item?.device_city?.name ? (
          <>
            {item?.device_city?.name}, {item?.device_state?.name}{' '}
          </>
        ) : (
          'No device location available'
        );

      case 'sensor':
        return 'No location available';

      case 'company':
        return item && item?.state && item?.city ? (
          <>
            {item?.city}, {item?.state}
          </>
        ) : (
          ''
        );

      default:
        return '';
    }
  };

  const handleName = (item: any) => {
    return handleResultType(item) === 'user' &&
      item?.hasOwnProperty('first_name') &&
      item?.hasOwnProperty('last_name')
      ? item?.first_name.concat(' ').concat(item?.last_name)
      : handleResultType(item) === 'device'
      ? item?.hasOwnProperty('device_name')
        ? item?.device_name
        : ''
      : handleResultType(item) === 'sensor'
      ? item && item?.hasOwnProperty('device_name')
        ? item?.device_name
        : item && item.hasOwnProperty('name')
        ? item?.name
        : ''
      : handleResultType(item) === 'company'
      ? item?.name
      : '';
  };

  //check if this validation is enough
  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return handleName(item);

      case '2':
        return handleResultType(item) === 'user'
          ? item?.account_id
          : handleResultType(item) === 'device' ||
            handleResultType(item) === 'sensor'
          ? item?.device_id
          : handleResultType(item) === 'company'
          ? item?.company_id
          : '';

      case '3':
        return handleLocation(item, handleResultType(item));

      case '4':
        return handleResultType(item);

      default:
        return '';
    }
  };

  const returnAccordionDetails = (item: any) => {
    return (
      <Fragment>
        <ResultDetails
          item={item}
          name={handleName(item)}
          resultType={handleResultType(item)}
        />
        {item &&
          item?.hasOwnProperty('device_id') &&
          !item?.hasOwnProperty('uuid') && (
            <Collapsible
              item={item}
              collapsibleState={
                accStates[item?.device_id as keyof typeof accStates]
              }
            />
          )}

        {item && item?.hasOwnProperty('uuid') && <SensorData item={item} />}
      </Fragment>
    );
  };

  useEffect(() => {
    if (resultsLoaded) {
      let states: any = {};

      let _devs = searchResults
        .filter((elem: any) => elem.hasOwnProperty('device_id'))
        .map((item: any) => item?.device_id);

      _devs.forEach((dev: any) => {
        states[dev] = { open: false };
      });

      setAccStates(states);
    }
  }, [resultsLoaded]);

  return (
    <Template
      id="search-results-list"
      title={config.strings.title}
      goBack={() => {
        history.push('/admin/dashboard');
      }}
    >
      <BreadcrumbsItem to={config?.strings?.breadcrumbItemTo}>
        {config?.strings?.breadcrumbTitle}
      </BreadcrumbsItem>
      {errorOnResults ? (
        <div id="errors-display--search-res">
          <div className="alert alert-info" role="alert">
            <MdErrorOutline className="alert-icon" />
            <span className="lead err-string">
              Something went wrong while fetching the results. Please, try again
              later!
            </span>
          </div>
        </div>
      ) : (
        <>
          {inputSearch && searchResults.length > 0 ? (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Alert alertClass="results-alert">
                    <span className="lead alert-device">
                      Currently showing results for "{inputSearch}".
                    </span>
                  </Alert>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <List
            iconWithInits={true}
            displayResultTypeIcon={displayResultTypeIcon}
            listWithoutHeader={true}
            rowClasses={config.rowClasses}
            id="search--results--list"
            list={searchResults}
            loaded={resultsLoaded}
            itemsPerPage={10}
            manageListOrder={() => {}}
            orderedList={true}
            orders={{}}
            sectionCounter={{}}
            sectionCount={0}
            updateOrderState={() => {}}
            incremented={{}}
            current={``}
            unorderedDefault={searchResults}
            handles={{
              handleView,
            }}
            config={config}
            firstFun={(item: any) => {
              handleView(currentResult);
            }}
            setItem={(item: any) => {
              setCurrentResult(item);
            }}
            retrieveColValue={retrieveColValue}
            returnAccordionDetails={returnAccordionDetails}
            helperOrderData={[]}
          />
        </>
      )}

      <Modal
        config={config}
        id={`view-search-result`}
        title={config?.modal?.view?.title}
        icon={<RxMagnifyingGlass className="edit" />}
        body={
          <Content
            config={config}
            id="the-card-res-info"
            resultType={currentResult ? handleResultType(currentResult) : ''}
            orderedCurrResult={orderedCurrResult}
          />
        }
        onClick={() => {}}
        modalMustClose={false}
        handleModalState={() => {}}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={``}
        hasConfirmBtn={false}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { searchState } = state;
  return {
    searchResults: searchState.searchResults,
    currentResult: searchState.currentResult,
    searchTermInput: searchState.searchTermInput,
    resultsLoaded: searchState.resultsLoaded,
    inputSearch: searchState.inputSearch,
    errorOnResults: searchState.errorOnResults,
    orderedCurrResult: searchState.orderedCurrResult,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { searchState } = dispatch;
  return {
    setCurrentResult: searchState.setCurrentResult,
    defResult: searchState.defResult,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
