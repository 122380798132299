import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import { InstallerTemplate } from 'business/modules/common';
import { ActionsMenu, EmptyList } from './components';
import { List, Notification } from 'react-components';
import './styles.scss';
import config from './config';

interface LoadingProps {
  start: any;
  stop: any;
}

interface AllZonesProps {
  isLoading?: boolean;
  systems: any;
  deleteSystem: Function;
  setCurrentDevice: Function;
  listAllSystems: any;
  getSystemLogs: Function;
  loading: LoadingProps;
}

const WIFI_CONN_DIFF: number = 30; // in seconds
const REQUEST_LOGS_INTERVAL: number = 60 * 1000 * 1;

const AllZones: React.FC<AllZonesProps> = ({
  isLoading,
  systems,
  ...props
}) => {
  const history = useHistory();
  let intervals: any = [];

  const [formatedSystems, setFormatedSystems]: any = useState([]);
  const [listConfig, setListConfig]: any = useState({
    text: ['device_name'],
    status: { prop: 'isWifiConn', activeState: 'active' },
  });
  const [showDeleteAlert, setShowDeleteAlert]: any = useState(false);
  const [systemToDelete, setSystemToDelete]: any = useState(null);

  const onLoadPage = async () => {
    props.loading.start();
    await props.listAllSystems();
    props.loading.stop();
  };

  const goToDeviceDetails = (device: any) => {
    props.setCurrentDevice({ ...device });
    history.push(`/installer/systems/details?id=${device?.device_id}`);
  };

  const onSystemClick = (rowItem: any, data: any) => {
    goToDeviceDetails(rowItem);
  };

  const onDeleteSystem = (system: any, event: any, _defaults: any) => {
    setSystemToDelete(system);
    setShowDeleteAlert(true);
  };

  const deleteSystem = async () => {
    setShowDeleteAlert(false);

    if (systemToDelete) {
      props.loading.start();
      await props.deleteSystem({
        system_id: systemToDelete.device_id,
      });

      await props.listAllSystems();
      setSystemToDelete(null);
      props.loading.stop();

      Notification({
        title: 'Success',
        message: config.notifications.delete.msm,
        type: 'success',
      });
    }
  };

  const resetIntervals = () => {
    if (intervals && intervals.length > 0) {
      intervals.forEach((ival: any) => {
        clearInterval(ival);
      });
    }
  };

  const hasValidDate = (item: any) => {
    const { createdAt, createdDate } = item;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdDate));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };

  const isSystemConnected = async (_system: any) => {
    const { api_key } = _system;
    const resp: any = await props.getSystemLogs({ deviceKey: api_key });
    return resp && resp?.Items?.length > 0
      ? hasValidDate(resp.Items[0])
      : false;
  };

  const updateSystemsStates = async (_systems: any) => {
    for (let index = 0; index < _systems.length; index++) {
      let isConn: boolean = await isSystemConnected(_systems[index]);
      _systems[index].isWifiConn = isConn ? 'active' : 'inactive';
    }
    return _systems;
  };

  const startStateListener = async (_systems: any) => {
    let updatedSystemsClone: any = _.cloneDeep(_systems);
    let tempInterval: any = setInterval(async () => {
      updatedSystemsClone = await updateSystemsStates(updatedSystemsClone);
      setFormatedSystems(_.cloneDeep(updatedSystemsClone));
    }, REQUEST_LOGS_INTERVAL);
    intervals.push(tempInterval);
  };

  const initSystemsStates = async (_systems: any) => {
    let tempSystems: any = [];
    for (let index = 0; index < systems.length; index++) {
      let isConnect: boolean = await isSystemConnected(_systems[index]);
      let systemClone: any = _.cloneDeep(_systems[index]);
      systemClone.isWifiConn = isConnect ? 'active' : 'inactive';
      tempSystems.push(systemClone);
    }
    setFormatedSystems(tempSystems);
    startStateListener(tempSystems);
  };

  useEffect(() => {
    if (systems && systems.length > 0) {
      initSystemsStates(systems);
    } else {
      resetIntervals();
    }

    return () => {
      resetIntervals();
    };
  }, [systems]);

  useEffect(() => {
    onLoadPage();
  }, []);

  return (
    <InstallerTemplate id="all-systems" title={'Systems'}>
      <div className="all-systems--btn-wrapper">
        <div className="btn-wrapper"></div>
      </div>
      {systems && systems.length > 0 ? (
        <List
          name="systems-list"
          items={formatedSystems || []}
          onRowClick={onSystemClick}
          config={listConfig}
          rowType="device"
          status={false}
          Component={(props: any) => {
            return <ActionsMenu {...props} onDelete={onDeleteSystem} />;
          }}
        ></List>
      ) : (
        <EmptyList title="systems" />
      )}
      <SweetAlert
        danger
        show={showDeleteAlert}
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title={`Are you sure you want to delete system: "${systemToDelete?.device_name}"?`}
        onConfirm={deleteSystem}
        onCancel={() => setShowDeleteAlert(false)}
        focusCancelBtn
      >
        {config.alerts.delete.msm}
      </SweetAlert>
    </InstallerTemplate>
  );
};

function mapStateToProps(state: any) {
  const { loader, installerSystems } = state;
  return {
    isLoading: loader.loading,
    systems: installerSystems.systems,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, logs, installerSystems } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    deleteSystem: installerSystems.deleteSystem,
    listAllSystems: installerSystems.listAllSystems,
    setCurrentDevice: installerSystems.setCurrentDevice,
    getSystemLogs: logs.getDeviceLogs,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllZones);
