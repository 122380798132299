import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import { UserTemplate } from 'business/modules/common';
import { ActionsMenu, EmptyList } from './components';
import { useAuth } from '../../../sessions/hooks/auth';
import { List, Notification } from '../../../../../react-components';
import { DevicesZonesUtil } from '../../../../../core/utils';
import './styles.scss';
import config from './config';




interface LoadingProps {
  start: any;
  stop: any;
}

interface AllSystemsProps {
  isLoading?: boolean;
  systems: any;
  userAccount: any;
  deleteSystem: Function;
  resetStoreSystems: Function;
  setCurrentDevice: Function;
  devices: any;
  getSystems: any;
  getDevices: any;
  getSystemLogs: Function;
  getSystemStatus: Function;
  loading: LoadingProps;
}

const TOKEN_LABEL: string = '@aircyler:token';
const WIFI_CONN_DIFF: number = 30; // in seconds
// const REQUEST_LOGS_INTERVAL: number = 60 * 1000 * 1;
const REQUEST_LOGS_INTERVAL: number = (60 * 1000);
let SYSTEMS_LIST: any = [];
let INTERVALS: any = [];

const AllSystems: React.FC<AllSystemsProps> = ({
  systems,
  devices,
  ...props
}) => {
  const history = useHistory();
  const { token } = useAuth();
  const [formatedSystems, setFormatedSystems]: any = useState([]);
  const [listConfig, setListConfig]: any = useState({
    text: ['device_name'],
    status: { prop: 'isWifiConn', activeState: 'active' },
  });
  const [showDeleteAlert, setShowDeleteAlert]: any = useState(false);
  const [systemToDelete, setSystemToDelete]: any = useState(null);





  const onLoadPage = async () => {
    props.loading.start('Loading Systems...');
    await props.getSystems();
    props.loading.stop();
  };

  const goToDeviceDetails = (device: any) => {
    props.setCurrentDevice({ ...device });
    history.push(`/dashboard/systems/details?id=${device?.device_id}`);
  };

  const onSystemClick = (rowItem: any, data: any) => {
    goToDeviceDetails(rowItem);
  };

  const onAddSystem = () => {
    // history.push(`/dashboard/systems/add`);
  };

  const onEditSystem = async (system: any, event: any, _defaults: any) => {
    const { device_id } = system;
    props.setCurrentDevice({ ...system });
    history.push(`/dashboard/system-settings/${device_id}`);
  };


  const onDeleteSystem = (system: any, event: any, _defaults: any) => {
    setSystemToDelete(system);
    setShowDeleteAlert(true);
  };

  const deleteSystem = async () => {
    setShowDeleteAlert(false);
    if (systemToDelete) {
      props.loading.start();
      await props.deleteSystem({
        device_id: systemToDelete.device_id,
      });
      await props.getSystems();
      setSystemToDelete(null);
      props.loading.stop();
      Notification({
        title: 'Success',
        message: config.notifications.delete.msm,
        type: 'success',
      });
    }
  };

  const resetIntervals = () => {
    if (INTERVALS && INTERVALS.length > 0) {

      INTERVALS.forEach((ival: any) => {
        clearInterval(ival);
      });

      INTERVALS = [];
    }
  };

  const hasValidDate = (item: any) => {
    const { createdAt, createdDate } = item;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdDate));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };

  const isSystemConnected = async (_system: any) => {
    const { api_key, device_id } = _system || {};
    const res: any = await props.getSystemStatus({
      system_id: device_id,
    });

    return res?.stoped ? false : true;
    // const resp: any = await props.getSystemLogs({ deviceKey: api_key });

    // return resp && resp?.Items?.length > 0
    //   ? hasValidDate(resp.Items[0])
    //   : false;
  };

  const updateSystemsStates = async (_systems: any) => {
    for (let index = 0; index < _systems.length; index++) {
      let isConn: boolean = await isSystemConnected(_systems[index]);
      _systems[index].isWifiConn = isConn ? 'active' : 'inactive';
    }
    return _systems;
  };


  const startStateListener = async (_systems: any) => {
    resetIntervals();
    let updatedSystemsClone: any = SYSTEMS_LIST ? _.cloneDeep(SYSTEMS_LIST) : _.cloneDeep(_systems);

    let tempInterval: any = setInterval(async () => {
      updatedSystemsClone = await updateSystemsStates(
        SYSTEMS_LIST ? SYSTEMS_LIST : updatedSystemsClone
      );
      setFormatedSystems(_.cloneDeep(updatedSystemsClone));
    }, REQUEST_LOGS_INTERVAL);

    INTERVALS.push(tempInterval);
  };


  const initSystemsStates = async (_systems: any) => {
    let tempSystems: any = [];
    props.loading.start('Loading Systems...');

    for (let index = 0; index < systems.length; index++) {
      let isConnect: boolean = await isSystemConnected(_systems[index]);
      let systemClone: any = _.cloneDeep(_systems[index]);

      systemClone.isWifiConn = isConnect ? 'active' : 'inactive';

      tempSystems.push(systemClone);
    }

    SYSTEMS_LIST = _.cloneDeep(tempSystems);
    setFormatedSystems(tempSystems);
    startStateListener(tempSystems);

    props.loading.stop();
  };








  useEffect(() => {
    if (systems && systems.length > 0) {
      initSystemsStates(systems);
    } else {
      SYSTEMS_LIST = [];
      setFormatedSystems([]);
      resetIntervals();
    }
  }, [systems]);


  useEffect(() => {
    onLoadPage();

    return () => {
      resetIntervals();
      setFormatedSystems([]);
      props.resetStoreSystems();
    };
  }, []);


  useEffect(() => {

    return () => {
      setTimeout(() => {
        const _token: any = localStorage.getItem(TOKEN_LABEL);

        if(_token === null || _token === undefined){
          SYSTEMS_LIST = [];
          resetIntervals();
          setFormatedSystems([]);
          props.resetStoreSystems();
        }
      }, 2000);
    };
  }, []);





  return (
    <UserTemplate id="all-systems" title="Systems">
      <div className="all-systems--btn-wrapper">
        <div className="btn-wrapper">
          <button
            type="button"
            className="small-btn btn-rounded"
            onClick={onAddSystem}
          >
            + Add System
          </button>
        </div>
      </div>
      {formatedSystems && formatedSystems.length > 0 ? (
        <List
          name="systems-list"
          items={formatedSystems || []}
          onRowClick={onSystemClick}
          config={listConfig}
          rowType="device"
          status={true}
          Component={(props: any) => {
            return (
              <ActionsMenu
                {...props}
                onDelete={onDeleteSystem}
                onEdit={onEditSystem}
              />
            );
          }}
        ></List>
      ) : (
        <EmptyList title="systems" />
      )}
      <SweetAlert
        danger
        show={showDeleteAlert}
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title={`Are you sure you want to delete system: "${systemToDelete?.device_name}"?`}
        onConfirm={deleteSystem}
        onCancel={() => setShowDeleteAlert(false)}
        focusCancelBtn
      >
        {config.alerts.delete.msm}
      </SweetAlert>
    </UserTemplate>
  );
};

function mapStateToProps(state: any) {
  const { devices, loader, profile } = state;
  return {
    isLoading: loader.loading,
    devices: devices.devices,
    systems: devices.systems,
    userAccount: profile.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { devices, loader, logs } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    deleteSystem: devices.deleteDevice,
    getDevices: devices.getAllDevices,
    getSystems: devices.getSystems,
    resetStoreSystems: devices.resetSystems,
    setCurrentDevice: devices.setCurrentDevice,
    getSystemLogs: logs.getDeviceLogs,
    getSystemStatus: devices.getSystemStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllSystems);
