import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Divider,
  Chip,
  Collapse,
  NativeSelect,
} from '@material-ui/core';
import {
  Row,
  Col,
  Modal,
  Form,
  Container,
} from 'react-bootstrap';
import { FcDataConfiguration } from 'react-icons/fc';
import moment from 'moment';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ActionsMenu,
  DetailsChip,
  HeaderMenu,
} from '../../';
import {
  Convertions,
  ConfigurationBroker,
} from 'core/utils';
import { Notification, Button } from 'react-components';
import {
  FanIcon,
  ConnectedIcon,
  DisconnectedIcon,
  AirCyclerLogo,
} from '../../../../../../../../../assets/device-icons';
import config from './config';
import './styles.scss';




const WIFI_CONN_DIFF = 30; // in seconds
const SYSTEM_PROPS: any = { ...config.systemProps };
const FRESH_CONNECT_LABELS: any = {
  ...config.connectedDevicesLabels.freshConnect,
};
const VENT_CONNECT_LABELS: any = {
  ...config.connectedDevicesLabels.ventConnect,
};
const PRESSURE_CONNECT_LABELS: any = {
  ...config.connectedDevicesLabels.pressureConnect,
};
const AIR_LOCAL_LABELS: any = {
  ...config.connectedDevicesLabels.airLocalSensor,
};
const LOCAL_SENSOR_TEMPLATE: any = {
  ...config.localSensorTemplate
};
let ROOT_DEVICE: any = null;



interface AircyclerProps {
  loading: any;
  device: any;
  setPeripheral: any;
  getDeviceLogs: any;
  listUserConfigs: any;
  showSystemSettings: any;
  handleUpdateSystemSetup: any;
  handleUpdateSystemSettings: any;
}

const Aircycler: React.FC<AircyclerProps> = ({ device, ...props }) => {
  const history = useHistory();
  let intervals: any = [];
  const [deviceSettings, setDeviceSettings]: any = useState(null);
  const [initRootDevice, setInitRootDevice]: any = useState(false);
  const [connDevices, setConnDevices]: any = useState([]);
  const [localSensors, setLocalSensors]: any = useState([]);
  const [localSensorExpanded, setLocalSensorExpanded]: any = useState({});
  const [menuOptions, setMenuOptions]: any = useState(
    config?.menu?.options || [],
  );
  const [expanded, setExpanded]: any = useState(false);
  const [fhExpanded, setFhExpanded]: any = useState({});
  const [vcExpanded, setVcExpanded]: any = useState({});
  const [pcExpanded, setPcExpanded]: any = useState({});
  const [upDevice, setUpDevice]: any = useState({ ...device });
  const [deviceWifiSignal, setDeviceWifiSignal]: any = useState(null);

  const [applyConfigModal, setApplyConfigModal]: any = useState(false);
  const [modalSelectConfig, setSelectConfig]: any = useState();
  const [modalSeletedCtrl, setSeletedCtrl]: any = useState();
  const [configsList, setConfigsList]: any = useState([]);
  const [targetOptions, setTargetOptions]: any = useState([]);
  const [enableApplyConfig, setEnableApplyConfig]: any = useState(false);



  const onClickConnDevice = async (rowDevice: any, event: any) => {
    props.setPeripheral({ ...rowDevice });
    history.push(
      `/dashboard/systems/details/${device.device_id}/peripheral/setup`,
      {
        device_id: device?.device_id,
        peripheral_id: rowDevice?.dmac,
      },
    );
  };


  const handleExpandPanel = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleExpandLines = (devData: any, states: any, option?: number) => {
    let currentClass: string =
      option && option === 2 ? 'open-details' : 'expanded-line';
    const { cdid } = devData;

    if (cdid && states.hasOwnProperty(cdid)) {
      if (!states[cdid]) currentClass = '';
    }

    return currentClass;
  };


  const handleOpenedLine = (devData: any, states: any) => {
    let lineClass: string = '';
    const { cdid }: any = devData || {};

    if (cdid && cdid !== null && cdid !== undefined) {
      if (states && states.hasOwnProperty(cdid) && states[cdid]) {
        lineClass = 'open-details-aux';
      }
    }

    return lineClass;
  };


  const handleExpandFreshConnect = (fhDevice: any, event: any) => {
    const { cdid }: any = fhDevice || {};
    if (cdid && cdid !== null && cdid !== undefined) {
      if (fhExpanded.hasOwnProperty(cdid)) {
        setFhExpanded((prevVals: any) => {
          return {
            ...prevVals,
            [cdid]: !prevVals[cdid],
          };
        });
      }
    }
  };


  const handleExpandPressureConnect = (pcDevice: any, event: any) => {
    const { cdid }: any = pcDevice || {};
    if (cdid && cdid !== null && cdid !== undefined) {
      if (pcExpanded.hasOwnProperty(cdid)) {
        setPcExpanded((prevVals: any) => {
          return {
            ...prevVals,
            [cdid]: !prevVals[cdid],
          };
        });
      }
    }
  };


  const handleExpandVentConnect = (vcDevice: any, event: any) => {
    const { cdid }: any = vcDevice || {};

    if (cdid && cdid !== null && cdid !== undefined) {
      if (vcExpanded.hasOwnProperty(cdid)) {
        setVcExpanded((prevVals: any) => {
          return {
            ...prevVals,
            [cdid]: !prevVals[cdid],
          };
        });
      }
    }
  };


  const handleExpandLocalSensorRow = (_localSensor: any, event: any) => {
    const { cdid }: any = _localSensor || {};

    if (cdid && cdid !== null && cdid !== undefined) {
      if (localSensorExpanded.hasOwnProperty(cdid)) {
        setLocalSensorExpanded((prevVals: any) => {
          return {
            ...prevVals,
            [cdid]: !prevVals[cdid],
          };
        });
      }
    }
  };


  const hasValidDate = (item: any) => {
    const { createdAt, createdDate } = item;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdDate));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };


  const isDeviceConnected = async (_device: any) => {
    const { api_key, device_name } = _device;
    const resp: any = await props.getDeviceLogs({ deviceKey: api_key });
    return resp && resp?.Items?.length > 0
      ? hasValidDate(resp.Items[0])
      : false;
  };


  const processSpecialProp = (prop: string, values: any, oldValues: any) => {
    switch (prop) {
      case 'ctmp':
        return values[prop] !== -99 && values[prop] !== oldValues[prop]
          ? values[prop]
          : oldValues[prop];
      case 'crhr':
      case 'cflw':
        return values[prop] !== -1 && values[prop] !== oldValues[prop]
          ? values[prop]
          : oldValues[prop];
      default:
        return;
    }
  };


  const reduceDeviceDetails = (origin: any, log: any) => {
    let mergedData: any = _.cloneDeep(log);
    let { conf }: any = origin?.details?.settings || {};
    let logConf: any = log?.details?.settings?.conf || {};

    SYSTEM_PROPS.toIgnore.forEach((prop: string) => {
      if (conf.hasOwnProperty(prop) && logConf.hasOwnProperty(prop)) {
        switch (prop) {
          case 'ctmp':
            mergedData.details.settings.conf[prop] =
              logConf[prop] !== -99 && logConf[prop] !== conf[prop]
                ? logConf[prop]
                : conf[prop];
            break;
          case 'crhr':
          case 'cflw':
            mergedData.details.settings.conf[prop] =
              logConf[prop] !== -1 && logConf[prop] !== conf[prop]
                ? logConf[prop]
                : conf[prop];
            break;
          default:
            break;
        }
      }
    });

    return { ...mergedData };
  };


  const updateDeviceState = async (_device: any) => {
    let isConn: boolean = await isDeviceConnected(_device);
    _device.isWifiConn = isConn ? 'active' : 'inactive';
    let devClone: any = _.cloneDeep(ROOT_DEVICE);
    let reduceSettings: any = devClone
      ? reduceDeviceDetails(devClone, _device)
      : devClone;

    setUpDevice(reduceSettings ? { ...reduceSettings } : { ..._device });
    return reduceSettings ? { ...reduceSettings } : { ..._device };
    // setUpDevice({..._device});
    // return _device;
  };


  const startStateListener = async (_device: any) => {
    let updatedDevice: any = { ..._device };
    let tempInterval: any = setInterval(async () => {
      await updateDeviceState({ ...updatedDevice });
    }, 15 * 1000); // 30secs
    intervals.push(tempInterval);
  };

  const resetIntervals = () => {
    if (intervals && intervals.length > 0) {
      intervals.forEach((ival: any) => {
        clearInterval(ival);
      });
    }
  };

  const defineDevType = (data: any) => {
    const { cdid }: any = data || {};
    let devType: string = '';

    if (cdid && cdid !== '') {
      if (cdid.startsWith('AirCyclerVC')) {
        devType = 'vent';
      } else if (cdid.startsWith('AirCyclerFH')) {
        devType = 'fresh';
      } else if (cdid.startsWith('AirCyclerFT')) {
        devType = 'fresh2';
      } else if (cdid.startsWith('AirCyclerPC')) {
        devType = 'press';
      }
    }

    return devType;
  };

  const parseConnectedDeviceIndex = (devData: any) => {
    const { cidx }: any = devData || {};
    let parsedValue: number = 0;

    if (cidx !== null && cidx !== undefined) {
      if (typeof cidx === 'string') parsedValue = parseInt(cidx);
      else parsedValue = cidx;
    }

    return parsedValue;
  };

  const prepareConnectVents = (_system: any) => {
    const { settings } = _system?.details || {};
    const vcStatesClone: any = _.cloneDeep(vcExpanded);
    const fhStatesClone: any = _.cloneDeep(fhExpanded);
    const pcStatesClone: any = _.cloneDeep(pcExpanded);

    let hasFanConnect: boolean =
      settings?.conf?.fcsp === 1 || settings?.conf?.fcsp === '1';
    let tempFreshConnect: any;
    let tempVentConnect: any;
    let tempPressConnect: any;
    const filteredConnDevs: any =
    settings?.conf?.cdvs?.length > 0
      ? settings.conf.cdvs.filter((item: any) => {
          return (
            item &&
            item.hasOwnProperty('cdid') &&
            !item.cdid.includes('AirCyclerAQ')
          );
        })
      : [];
    let ventConnects: any =
      filteredConnDevs?.length > 0
        ? filteredConnDevs.map((ventConn: any, index: number) => {
            let connectDeviceType: string = defineDevType(ventConn);
            let tempIndex: any = parseConnectedDeviceIndex(ventConn);
            const { cdid } = ventConn;

            if (
              connectDeviceType &&
              (connectDeviceType === 'fresh' || connectDeviceType === 'fresh2')
            ) {
              if (!tempFreshConnect) tempFreshConnect = {};
              tempFreshConnect[cdid] = false;
            } else if (connectDeviceType && connectDeviceType === 'vent') {
              if (!tempVentConnect) tempVentConnect = {};
              tempVentConnect[cdid] = false;
            } else if (connectDeviceType && connectDeviceType === 'press') {
              if (!tempPressConnect) tempPressConnect = {};
              tempPressConnect[cdid] = false;
            }

            return {
              ...ventConn,
              type:
                connectDeviceType && connectDeviceType !== ''
                  ? connectDeviceType
                  : 'vent',
              index: tempIndex + 1,
            };
          })
        : [];

    if (tempFreshConnect) {
      const mergedFhStates: any = _.merge(tempFreshConnect, fhStatesClone);
      setFhExpanded(mergedFhStates);
    }
    if (tempPressConnect) {
      const mergedPressStates: any = _.merge(tempPressConnect, pcStatesClone);
      setPcExpanded(mergedPressStates);
    }
    if (tempVentConnect) {
      const mergedVcStates: any = _.merge(tempVentConnect, vcStatesClone);
      setVcExpanded(mergedVcStates);
    }

    if (hasFanConnect) {
      ventConnects.unshift({
        type: 'fan',
        isWifiConn: 'active',
        cdid: 'FanConnect',
        cail: '',
      });
    }

    return ventConnects;
  };


  const mapPropUnits = (prop: string) => {
    switch (prop) {
      case 'aqst':
        return 'ºF';
      case 'aqsh':
        return '%';
      case 'cco2':
        return 'ppm';
      case 'cvoc':
        return 'ppb';
      case 'cp25':
        return 'ug/m3';
      case 'irms':
        return 'A';
      case 'vrms':
        return 'V';
      case 'watt':
        return 'W';
      default:
        return '';
    }
  };


  const processConnectedDevValue = (
    prop: string,
    value: any,
    data?: any
  ) => {

    switch (prop) {
      case 'dmac':
        return value && value !== '' ? value : 'N/A';
      case 'aqst':
      case 'aqsh':
      case 'cco2':
      case 'cvoc':
      case 'cp25':
      case 'irms':
      case 'vrms':
        return value !== null && value !== undefined && (!isNaN(value) || value !== '')
          ? `${value} ${mapPropUnits(prop)}`
          : 'N/A';
      case 'watt':
          const { vrms, irms }: any = data || {};
          const vrmsCopy: number = vrms !== null && vrms !== undefined ? vrms : 0;
          const irmsCopy: number = irms !== null && irms !== undefined ? irms : 0;

          return `${vrmsCopy * irmsCopy} ${mapPropUnits(prop)}`;
      default:
        const propType: any = typeof value;
        if (propType === 'number') {
          return value !== null && value !== undefined ? value : 'N/A';
        } else {
          return value && value !== '' ? value : 'N/A';
        }
    }
  };


  const keyFormatter = (connDev: any, index: number, side: string) => {
    const { cdid }: any = connDev || {};
    let compKey: string =
      cdid && cdid !== ''
        ? `${side}--${cdid}-${index}`
        : `index--${side}-${index}`;
    return compKey;
  };




  const _mapConnectedLabel = (devId: any) => {
    let label: string = '';

    if (devId !== null && devId !== undefined) {
      if (devId.startsWith('AirCyclerVC')) label = 'VentConnect';
      if (devId.startsWith('AirCyclerPC')) label = 'PressureConnect';
      if (devId.startsWith('AirCyclerFH')) label = 'FreshConnect';
      if (devId.startsWith('AirCyclerFT')) label = 'FreshConnect';
    }

    return label;
  };


  const mapPeripheralsAsOptions = (connDevs: any) => {
    let formatedPeripherals: any = [];
    const filteredDevicesTmp = connDevs && connDevs.length > 0 ? connDevs.filter((item: any) => {
        const { cdid }: any = item || {};
        return (
          cdid !== '' &&
          !cdid.includes('AirCyclerAQ') &&
          !cdid.includes('AirCyclerFT') &&
          !cdid.includes('AirCyclerFH')
        );
      }) : [];

    if(filteredDevicesTmp?.length > 0){
      filteredDevicesTmp.forEach((item: any) => {
        const { cdid, cail, dmac } = item || {};
        const tempDev: any = {
          ...item,
          cdid: cdid,
          cail: cail,
          dmac: dmac,
          label: cdid,
          value: cdid,
          vent: _mapConnectedLabel(cdid),
        };
        formatedPeripherals.push(tempDev);
      });
    }

    const fanIndex: number = formatedPeripherals.findIndex(
      (periph: any) => periph?.cdid === 'fanConnect'
    );

    if(fanIndex === -1){
      formatedPeripherals.push({
        cail: 'FanConnect',
        cdid: 'fanConnect',
        dmac: '',
        label: 'FanConnect',
        value: 'fanConnect',
        vent: 'FanConnect',
      });
    }else{
      formatedPeripherals[fanIndex].vent = 'FanConnect';
    }

    return formatedPeripherals;
  };


  const handleListConfigurations = async () => {
    const _configsList: any = await props.listUserConfigs();

    let tempMappedConfigs: any = [{
      config_id: '0',
      name: 'Current Configuration',
      settings: {},
      setup: {},
      label: 'Current Configuration',
      value: '0',
    }];

    if(_configsList?.length > 0){
      tempMappedConfigs = tempMappedConfigs.concat(_configsList);
      setSelectConfig(tempMappedConfigs[0]);
    }

    setConfigsList(tempMappedConfigs);
  };


  const handleTargetOptions = (currSys: any) => {
    const { cdvs }: any = currSys?.details?.settings?.conf || {};

    const _targetOptions: any = mapPeripheralsAsOptions(cdvs);

    if(_targetOptions?.length > 0){
      setSeletedCtrl(_targetOptions[0]);
    }

    setTargetOptions(_targetOptions);
  };


  const applyConfiguration = async (_event: any) => {
    props.loading.start('Loading Configurations data...');
    await handleListConfigurations();
    handleTargetOptions(device);
    props.loading.stop();

    setApplyConfigModal(true);
  };


  const handleCloseApplyModal = () => {
    setApplyConfigModal(false);
  };



  const handleSendConfiguration = async (
    _system: any,
    config: any,
    target: any,
  ) => {
    const sysClone: any = _.cloneDeep(_system);
    const selectedConfigClone: any = _.cloneDeep(config);
    const selectedTargetObj: any = _.cloneDeep(target);
    const { device_id }: any = sysClone || {};
    const sysSettingsResult: any = await props.showSystemSettings({
      id: device_id,
    });

    if(sysSettingsResult?.settings){
      let activeSystemSettings = {
        settings: ConfigurationBroker.prepareSystemSettings(
          sysSettingsResult, selectedConfigClone
        ),
        system_id: device_id,
      };
      const activeSysSetup: any = ConfigurationBroker.prepareWidgetsSetups(
        device_id, selectedConfigClone
      );

      if(activeSysSetup?.ok){
        let clonePayload: any = _.cloneDeep(activeSysSetup);
        delete clonePayload.ok;

        await props.handleUpdateSystemSetup(
          clonePayload
        );
      }

      const mergedSettings: any = ConfigurationBroker.mergeCtrlTargetValues(
        activeSystemSettings, selectedTargetObj
      );

      ConfigurationBroker.backgroundSystemSettingsUpdate(
        props.handleUpdateSystemSettings,
        (mergedSettings ? mergedSettings : activeSystemSettings)
      );
    }else{
      let _activeSystemSettings = ConfigurationBroker.prepareSystemNoneSettings(
        device_id, selectedConfigClone, selectedTargetObj?.cdid
      );
      const _activeSysSetup: any = ConfigurationBroker.prepareWidgetsSetups(
        device_id, selectedConfigClone
      );

      if(_activeSysSetup?.ok){
        let _clonePayload: any = _.cloneDeep(_activeSysSetup);
        delete _clonePayload.ok;

        await props.handleUpdateSystemSetup(
          _clonePayload
        );
      }

      const _mergedSettings: any = ConfigurationBroker.mergeCtrlTargetValues(
        _activeSystemSettings, selectedTargetObj
      );

      ConfigurationBroker.backgroundSystemSettingsUpdate(
        props.handleUpdateSystemSettings,
        (_mergedSettings ? _mergedSettings : _activeSystemSettings)
      );
    }

  };



  const handleApplyConfiguration = async () => {
    if(
      modalSeletedCtrl?.cdid &&
      modalSelectConfig?.config_id &&
      enableApplyConfig
    ){
      handleCloseApplyModal();
      props.loading.start('Apply Configuration...');
      await handleSendConfiguration(
        device,
        modalSelectConfig,
        modalSeletedCtrl,
      );
      props.loading.stop();
    }
  };


  const handleSelectVentMode = (_event: any) => {
    const selectedMode: any = _event.target.value;

    if(configsList.length > 0){
      let foundConfig: any = configsList.find((item: any) => {
        return item.config_id === selectedMode;
      });

      if(foundConfig?.settings){
        Object.keys(foundConfig.settings).forEach((key: string) => {
          if(key === 'ventilation_setup'){
            foundConfig.settings.vent_set = foundConfig.settings[key];
            delete foundConfig.settings[key];
          }
        });
      }

      setSelectConfig(foundConfig);
    }
  };


  const handleSelectTargetCtrl = (_event: any) => {
    const selectedTarget: any = _event.target.value;

    if(targetOptions?.length > 0){
      const foundTarget: any = targetOptions.find((item: any) => {
        return item.cdid === selectedTarget;
      });

      setTargetOptions(foundTarget);
    }
  };









  useEffect(() => {
    setEnableApplyConfig(
      modalSeletedCtrl?.cdid && modalSelectConfig?.config_id && modalSelectConfig?.config_id !== '0'
    );
  }, [modalSeletedCtrl, modalSelectConfig]);


  useEffect(() => {
    if (device?.details?.settings) {
      const { hrss, lsnm, conf } = device?.details?.settings || {};

      if (deviceSettings) {
        setDeviceSettings((prevSettings: any) => {
          let settingsClone: any = _.cloneDeep(device?.details.settings);
          if (
            settingsClone &&
            settingsClone.hasOwnProperty('conf') &&
            prevSettings
          ) {
            SYSTEM_PROPS.toIgnore.forEach((prop: string) => {
              if (settingsClone.conf.hasOwnProperty(prop)) {
                settingsClone.conf[prop] = processSpecialProp(
                  prop,
                  settingsClone.conf,
                  prevSettings.conf,
                );
              }
            });
          }

          return settingsClone;
        });
      } else {
        setDeviceSettings({ ...device.details.settings });
      }

      if (device.details.settings?.conf?.cdvs) {
        setConnDevices(prepareConnectVents(device));
      }

      if (hrss !== null && hrss !== undefined) {
        setDeviceWifiSignal(hrss);
      }

      if (!initRootDevice) {
        ROOT_DEVICE = _.cloneDeep(device);
        setInitRootDevice(true);
      }


      if(
        conf?.lsnm &&
        conf.lsnm !== '' &&
        conf.lsnm.includes('AirCyclerAQ')
      ){
        const { lsnm, cdvs }: any = conf || {};
        let sensorTemplate: any = _.cloneDeep(LOCAL_SENSOR_TEMPLATE);
        let localSensorsCopy: any = _.cloneDeep(localSensors);

        if(
          localSensorsCopy.findIndex(
            (item: any) => item.cdid === lsnm
          ) === -1
        ){
          if(
            cdvs &&
            cdvs.length > 0 &&
            cdvs.findIndex(
              (item: any) => item?.cdid === lsnm
            ) > -1
          ){
            const sensorIndex: number = cdvs.findIndex(
              (item: any) => item?.cdid === lsnm
            );
            const localSensorClone: any = _.cloneDeep(cdvs[sensorIndex]);

            localSensorsCopy.push(localSensorClone);
          }else{
            sensorTemplate.cdid = lsnm;
            sensorTemplate.cail = lsnm;

            localSensorsCopy.push(sensorTemplate);
          }

          setLocalSensors(localSensorsCopy);
          setLocalSensorExpanded((prevVals: any) => {
            return {
              ...prevVals,
              [lsnm]: false,
            };
          });
        }
      }

    }
    startStateListener(device);
    updateDeviceState({ ...device });

    return () => {
      resetIntervals();
    };
  }, [device]);


  useEffect(() => {
    return () => {
      setDeviceSettings(null);
      ROOT_DEVICE = null;
      setInitRootDevice(false);
    };
  }, []);



  return (
    <div id="device-details-aircycler">
      <Grid container>
        <Grid item xs={12} sm={12} className="device-details--name">
          <div className="device-details--header">
            <h4>
              {device?.device_name}
              {deviceWifiSignal && (
                <span className="device-details--header-signal">
                  RSSI: {deviceWifiSignal}
                </span>
              )}
            </h4>
            <HeaderMenu
              data={device}
              options={menuOptions}
              enabled={true}
              functions={{
                applyConfiguration: applyConfiguration,
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className="device-container-grid"
        style={{ marginTop: 0 }}
      >
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.peripherals}</h5>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="dmst"
              type="status"
              label={config?.strings?.labels?.damper}
              value={deviceSettings?.conf?.dmst}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="crhr"
              type="humidity"
              label={config?.strings?.labels?.relativeHumid}
              value={deviceSettings?.conf?.crhr}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="device-details--values">
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="ctmp"
              type="temp"
              label={config?.strings?.labels?.currentTemp}
              value={deviceSettings?.conf?.ctmp}
            />
          </Grid>
          <Grid sm={12} item className="device-details--values-row">
            <DetailsChip
              id="cflw"
              type="flow"
              label={config?.strings?.labels?.currentFlow}
              value={deviceSettings?.conf?.cflw}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="device-container-grid">
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.connDevices}</h5>
          </div>
        </Grid>
        <Grid item md={12} className="device-details--values">
          {connDevices.length > 0 ? (
            <List id="conn-devices-list">
              {connDevices.map((dev: any, _index: number) => {
                const { type, index, cdss } = dev || {};

                if (type && type === 'fan') {
                  return (
                    <ListItem
                      key={`fan-conn${_index}`}
                      alignItems="flex-start"
                      // onClick={onClickFanConnect}
                      onClick={() => {}}
                    >
                      <ListItemAvatar className="conn-devices-avatar">
                        <FanIcon />
                      </ListItemAvatar>
                      <ListItemText>
                        <div className="vent-connect-wrapper">
                          <div className="vent-connect-l-display">
                            {'FanConnect'}
                          </div>
                          <div className="vent-connect-r-display">
                            ( <span className="vent-connect-id">{'Wired'}</span>{' '} )
                          </div>
                        </div>
                      </ListItemText>
                      <ListItemAvatar className="expand-line-wrapper">
                        <div></div>
                      </ListItemAvatar>
                      <ListItemAvatar className="conn-devices-avatar">
                        <Tooltip
                          title={
                            upDevice.isWifiConn === 'active'
                              ? 'online'
                              : 'offline'
                          }
                          placement="top"
                        >
                          {upDevice ? <ConnectedIcon /> : <DisconnectedIcon />}
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemSecondaryAction>
                        <ActionsMenu data={dev} parent={device} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                } else if (type && type === 'fresh') {
                  return (
                    // TODO: replace by reusable component
                    <div key={dev?.dmac} className="fresh-connect--wrapper">
                      <ListItem
                        alignItems="flex-start"
                        className={handleOpenedLine(dev, fhExpanded)}
                        onClick={handleExpandFreshConnect.bind(null, dev)}
                      >
                        <ListItemAvatar className="conn-devices-avatar">
                          <FanIcon />
                        </ListItemAvatar>
                        <ListItemText>
                          <div className="vent-connect-wrapper">
                            <div className="vent-connect-l-display">
                              {dev?.cail ? dev.cail : dev?.cdid}
                            </div>
                            <div className="vent-connect-r-display">
                              ({' '}
                              <span className="vent-connect-id">
                                {'FreshConnect '}
                              </span>{' '}
                              )
                            </div>
                            {cdss && (
                              <div className="vent-connect-rssi">
                                RSSI: {cdss}
                              </div>
                            )}
                          </div>
                        </ListItemText>
                        <ListItemAvatar className="expand-line--wrapper-icon">
                          <div className="">
                            <ExpandMoreIcon
                              className={`${handleExpandLines(
                                dev,
                                fhExpanded,
                              )}`}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemAvatar className="conn-devices-avatar">
                          <Tooltip
                            title={
                              upDevice.isWifiConn === 'active'
                                ? 'online'
                                : 'offline'
                            }
                            placement="top"
                          >
                            {upDevice ? (
                              <ConnectedIcon />
                            ) : (
                              <DisconnectedIcon />
                            )}
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <ActionsMenu data={dev} parent={device} />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <div
                        className={`fresh-connect--details ${handleExpandLines(
                          dev,
                          fhExpanded,
                          2,
                        )}`}
                      >
                        <div className="fresh-connect--details-container">
                          <div className="fresh-connect--col-50">
                            {FRESH_CONNECT_LABELS?.left &&
                              Object.keys(FRESH_CONNECT_LABELS.left).map(
                                (key: string, index: number) => {
                                  const leftKey: string = keyFormatter(
                                    dev,
                                    index,
                                    'left',
                                  );

                                  return (
                                    <div
                                      key={leftKey}
                                      className="fresh-connect--details-label"
                                    >
                                      <span className="fresh-connect--label">
                                        {FRESH_CONNECT_LABELS.left[key]}:
                                      </span>
                                      <span className="fresh-connect--values">
                                        {dev !== null &&
                                          dev !== undefined &&
                                          processConnectedDevValue(
                                            key,
                                            dev[key],
                                            dev,
                                          )}
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                          <div className="fresh-connect--col-50">
                            {FRESH_CONNECT_LABELS?.right &&
                              Object.keys(FRESH_CONNECT_LABELS.right).map(
                                (key: string, index: number) => {
                                  const rightKey: string = keyFormatter(
                                    dev,
                                    index,
                                    'right',
                                  );

                                  return (
                                    <div
                                      key={rightKey}
                                      className="fresh-connect--details-label"
                                    >
                                      <span className="fresh-connect--label">
                                        {FRESH_CONNECT_LABELS.right[key]}:
                                      </span>
                                      <span className="fresh-connect--values">
                                        {dev &&
                                          dev.hasOwnProperty(key) &&
                                          processConnectedDevValue(
                                            key,
                                            dev[key],
                                          )}
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (type && type === 'fresh2') {
                  return (
                    // TODO: replace by reusable component
                    <div key={dev?.dmac} className="fresh-connect--wrapper">
                      <ListItem
                        alignItems="flex-start"
                        className={handleOpenedLine(dev, fhExpanded)}
                        onClick={handleExpandFreshConnect.bind(null, dev)}
                      >
                        <ListItemAvatar className="conn-devices-avatar">
                          <FanIcon />
                        </ListItemAvatar>
                        <ListItemText>
                          <div className="vent-connect-wrapper">
                            <div className="vent-connect-l-display">
                              {dev?.cail ? dev.cail : dev?.cdid}
                            </div>
                            <div className="vent-connect-r-display">
                              ({' '}
                              <span className="vent-connect-id">
                                {'FreshConnect '}
                              </span>{' '}
                              )
                            </div>
                            {cdss && (
                              <div className="vent-connect-rssi">
                                RSSI: {cdss}
                              </div>
                            )}
                          </div>
                        </ListItemText>
                        <ListItemAvatar className="expand-line--wrapper-icon">
                          <div className="">
                            <ExpandMoreIcon
                              className={`${handleExpandLines(
                                dev,
                                fhExpanded,
                              )}`}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemAvatar className="conn-devices-avatar">
                          <Tooltip
                            title={
                              upDevice.isWifiConn === 'active'
                                ? 'online'
                                : 'offline'
                            }
                            placement="top"
                          >
                            {upDevice ? (
                              <ConnectedIcon />
                            ) : (
                              <DisconnectedIcon />
                            )}
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <ActionsMenu data={dev} parent={device} />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <div
                        className={`fresh-connect--details ${handleExpandLines(
                          dev,
                          fhExpanded,
                          2,
                        )}`}
                      >
                        <div className="fresh-connect--details-container">
                          <div className="fresh-connect--col-50">
                            {FRESH_CONNECT_LABELS?.left &&
                              Object.keys(FRESH_CONNECT_LABELS.left).map(
                                (key: string, index: number) => {
                                  const leftKey: string = keyFormatter(
                                    dev,
                                    index,
                                    'left',
                                  );

                                  return (
                                    <div
                                      key={leftKey}
                                      className="fresh-connect--details-label"
                                    >
                                      <span className="fresh-connect--label">
                                        {FRESH_CONNECT_LABELS.left[key]}:
                                      </span>
                                      <span className="fresh-connect--values">
                                        {dev !== null &&
                                          dev !== undefined &&
                                          processConnectedDevValue(
                                            key,
                                            dev[key],
                                            dev,
                                          )}
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                          <div className="fresh-connect--col-50">
                            {FRESH_CONNECT_LABELS?.right &&
                              Object.keys(FRESH_CONNECT_LABELS.right).map(
                                (key: string, index: number) => {
                                  const rightKey: string = keyFormatter(
                                    dev,
                                    index,
                                    'right',
                                  );

                                  return (
                                    <div
                                      key={rightKey}
                                      className="fresh-connect--details-label"
                                    >
                                      <span className="fresh-connect--label">
                                        {FRESH_CONNECT_LABELS.right[key]}:
                                      </span>
                                      <span className="fresh-connect--values">
                                        {dev &&
                                          dev.hasOwnProperty(key) &&
                                          processConnectedDevValue(
                                            key,
                                            dev[key],
                                          )}
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (type && type === 'press') {
                  return (
                    // TODO: replace by reusable component
                    <div key={dev?.dmac} className="fresh-connect--wrapper">
                      <ListItem
                        alignItems="flex-start"
                        className={handleOpenedLine(dev, pcExpanded)}
                        onClick={handleExpandPressureConnect.bind(null, dev)}
                      >
                        <ListItemAvatar className="conn-devices-avatar">
                          <FanIcon />
                        </ListItemAvatar>
                        <ListItemText>
                          <div className="vent-connect-wrapper">
                            <div className="vent-connect-l-display">
                              {dev?.cail ? dev.cail : dev?.cdid}
                            </div>
                            <div className="vent-connect-r-display">
                              ({' '}
                              <span className="vent-connect-id">
                                {'PressureConnect'}
                              </span>{' '}
                              )
                            </div>
                            {cdss && (
                              <div className="vent-connect-rssi">
                                RSSI: {cdss}
                              </div>
                            )}
                          </div>
                        </ListItemText>
                        <ListItemAvatar className="expand-line--wrapper-icon">
                          <div className="">
                            <ExpandMoreIcon
                              className={`${handleExpandLines(
                                dev,
                                pcExpanded,
                              )}`}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemAvatar className="conn-devices-avatar">
                          <Tooltip
                            title={
                              upDevice.isWifiConn === 'active'
                                ? 'online'
                                : 'offline'
                            }
                            placement="top"
                          >
                            {upDevice ? (
                              <ConnectedIcon />
                            ) : (
                              <DisconnectedIcon />
                            )}
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <ActionsMenu data={dev} parent={device} />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <div
                        className={`fresh-connect--details ${handleExpandLines(
                          dev,
                          pcExpanded,
                          2,
                        )}`}
                      >
                        <div className="fresh-connect--details-container">
                          <div className="fresh-connect--col-50">
                            {PRESSURE_CONNECT_LABELS?.left &&
                              Object.keys(PRESSURE_CONNECT_LABELS.left).map(
                                (key: string, index: number) => {
                                  const leftKey: string = keyFormatter(
                                    dev,
                                    index,
                                    'left',
                                  );

                                  return (
                                    <div
                                      key={leftKey}
                                      className="fresh-connect--details-label"
                                    >
                                      <span className="fresh-connect--label">
                                        {PRESSURE_CONNECT_LABELS.left[key]}:
                                      </span>
                                      <span className="fresh-connect--values">
                                        {dev &&
                                          dev.hasOwnProperty(key) &&
                                          processConnectedDevValue(
                                            key,
                                            dev[key],
                                          )}
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                          <div className="fresh-connect--col-50">
                            {PRESSURE_CONNECT_LABELS?.right &&
                              Object.keys(PRESSURE_CONNECT_LABELS.right).map(
                                (key: string, index: number) => {
                                  const rightKey: string = keyFormatter(
                                    dev,
                                    index,
                                    'right',
                                  );

                                  return (
                                    <div
                                      key={rightKey}
                                      className="fresh-connect--details-label"
                                    >
                                      <span className="fresh-connect--label">
                                        {PRESSURE_CONNECT_LABELS.right[key]}:
                                      </span>
                                      <span className="fresh-connect--values">
                                        {dev &&
                                          dev.hasOwnProperty(key) &&
                                          processConnectedDevValue(
                                            key,
                                            dev[key],
                                          )}
                                      </span>
                                    </div>
                                  );
                                },
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }

                return (
                  <div key={dev?.dmac} className="vent-connect--wrapper">
                    <ListItem
                      key={dev?.dmac}
                      alignItems="flex-start"
                      className={handleOpenedLine(dev, vcExpanded)}
                      onClick={handleExpandVentConnect.bind(null, dev)}
                    >
                      <ListItemAvatar className="conn-devices-avatar">
                        <FanIcon />
                      </ListItemAvatar>
                      <ListItemText>
                        <div className="vent-connect-wrapper">
                          <div className="vent-connect-l-display">
                            {dev?.cail ? dev.cail : dev?.cdid}
                          </div>
                          <div className="vent-connect-r-display">
                            ({' '}
                            <span className="vent-connect-id">
                              {'VentConnect '}
                            </span>{' '}
                            )
                          </div>
                          {cdss && (
                            <div className="vent-connect-rssi">
                              RSSI: {cdss}
                            </div>
                          )}
                        </div>
                      </ListItemText>
                      <ListItemAvatar className="expand-line--wrapper-icon">
                        <div className="">
                          <ExpandMoreIcon
                            className={`${handleExpandLines(dev, vcExpanded)}`}
                          />
                        </div>
                      </ListItemAvatar>
                      <ListItemAvatar className="conn-devices-avatar">
                        <Tooltip
                          title={
                            upDevice.isWifiConn === 'active'
                              ? 'online'
                              : 'offline'
                          }
                          placement="top"
                        >
                          {upDevice ? <ConnectedIcon /> : <DisconnectedIcon />}
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemSecondaryAction>
                        <ActionsMenu data={dev} parent={device} />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <div
                      className={`vent-connect--details ${handleExpandLines(
                        dev,
                        vcExpanded,
                        2,
                      )}`}
                    >
                      <div className="vent-connect--details-container">
                        <div className="vent-connect--col-50">
                          {VENT_CONNECT_LABELS?.left &&
                            Object.keys(VENT_CONNECT_LABELS.left).map(
                              (key: string, index: number) => {
                                const leftKey: string = keyFormatter(
                                  dev,
                                  index,
                                  'left',
                                );

                                return (
                                  <div
                                    key={leftKey}
                                    className="vent-connect--details-label"
                                  >
                                    <span className="vent-connect--label">
                                      {VENT_CONNECT_LABELS.left[key]}:
                                    </span>
                                    <span className="vent-connect--values">
                                      {dev &&
                                        dev.hasOwnProperty(key) &&
                                        processConnectedDevValue(key, dev[key])}
                                    </span>
                                  </div>
                                );
                              },
                            )}
                        </div>
                        <div className="vent-connect--col-50">
                          {VENT_CONNECT_LABELS?.right &&
                            Object.keys(VENT_CONNECT_LABELS.right).map(
                              (key: string, index: number) => {
                                const rightKey: string = keyFormatter(
                                  dev,
                                  index,
                                  'right',
                                );

                                return (
                                  <div
                                    key={rightKey}
                                    className="vent-connect--details-label"
                                  >
                                    <span className="vent-connect--label">
                                      {VENT_CONNECT_LABELS.right[key]}:
                                    </span>
                                    <span className="vent-connect--values">
                                      {dev &&
                                        dev.hasOwnProperty(key) &&
                                        processConnectedDevValue(key, dev[key])}
                                    </span>
                                  </div>
                                );
                              },
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </List>
          ) : (
            <div className="no-conn-devices">
              <h6>{config?.strings?.warns?.noDevices}</h6>
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container className="device-container-grid">
        <Grid item xs={12} sm={12} className="device-details--section-title">
          <div>
            <h5>{config?.strings?.titles?.connectedSensors}</h5>
          </div>
        </Grid>
        <Grid item md={12} className="device-details--values">
          {
            localSensors.length > 0 ? (
              <List id="conn-sensors-list">
                {localSensors.map((sensor: any, _index: number) => {
                  const { cdss }: any = sensor || {};

                  return (
                    <div
                      key={sensor?.dmac + '--' + _index}
                      className="vent-connect--wrapper"
                    >
                      <ListItem
                        key={sensor?.dmac + '--' + _index}
                        alignItems="flex-start"
                        className={handleOpenedLine(
                          sensor, localSensorExpanded
                        )}
                        onClick={handleExpandLocalSensorRow.bind(null, sensor)}
                      >
                        <ListItemAvatar className="conn-devices-avatar">
                          <img
                            src={AirCyclerLogo}
                            alt="logo"
                            className="local-air--sensor"
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          <div className="vent-connect-wrapper">
                            <div className="vent-connect-l-display">
                              {sensor?.cail ? sensor.cail : sensor?.cdid}
                            </div>
                            <div className="vent-connect-r-display">
                              ({' '}
                              <span className="vent-connect-id">
                                {'LOCAL '}
                              </span>{' '}
                              )
                            </div>
                            <div className="vent-connect-rssi">
                              RSSI: {cdss ? cdss : 'N/A'}
                            </div>
                          </div>
                        </ListItemText>
                        <ListItemAvatar className="expand-line--wrapper-icon">
                          <div className="">
                            <ExpandMoreIcon
                              className={`${handleExpandLines(
                                sensor, localSensorExpanded
                              )}`}
                            />
                          </div>
                        </ListItemAvatar>
                        <ListItemAvatar className="conn-devices-avatar">
                          <Tooltip
                            title={
                              device?.details &&
                              device.details?.settings &&
                              device.details.settings?.conf &&
                              device.details.settings.conf?.lsnm &&
                              device.details.settings.conf.lsnm !== '' &&
                              device.details.settings.conf.lsnm.includes('AirCyclerAQ')
                                ? 'online'
                                : 'offline'
                            }
                            placement="top"
                          >
                            {
                              device?.details &&
                              device.details?.settings &&
                              device.details.settings?.conf &&
                              device.details.settings.conf?.lsnm &&
                              device.details.settings.conf.lsnm !== '' &&
                              device.details.settings.conf.lsnm.includes('AirCyclerAQ')
                                ? <ConnectedIcon />
                                : <DisconnectedIcon />
                            }
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <ActionsMenu data={sensor} parent={device} />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <div
                        className={`vent-connect--details ${handleExpandLines(
                          sensor,
                          localSensorExpanded,
                          2,
                        )}`}
                      >
                        <div className="vent-connect--details-container">
                          <div className="vent-connect--col-50">
                            {AIR_LOCAL_LABELS.left &&
                              Object.keys(AIR_LOCAL_LABELS.left).map(
                              (key: string, index: number) => {
                                const rightKey: string = keyFormatter(
                                  sensor, index, 'right',
                                );

                                return (
                                  <div
                                    key={rightKey}
                                    className="vent-connect--details-label"
                                  >
                                    <span className="vent-connect--label">
                                      {AIR_LOCAL_LABELS.left[key]}:
                                    </span>
                                    <span className="vent-connect--values">
                                      {sensor &&
                                        sensor.hasOwnProperty(key) &&
                                        processConnectedDevValue(key, sensor[key])}
                                    </span>
                                  </div>
                                );
                              },
                            )}
                          </div>
                          <div className="vent-connect--col-50">
                            {AIR_LOCAL_LABELS.right &&
                              Object.keys(AIR_LOCAL_LABELS.right).map(
                              (key: string, index: number) => {
                                const rightKey: string = keyFormatter(
                                  sensor, index, 'right',
                                );

                                return (
                                  <div
                                    key={rightKey}
                                    className="vent-connect--details-label"
                                  >
                                    <span className="vent-connect--label">
                                      {AIR_LOCAL_LABELS.right[key]}:
                                    </span>
                                    <span className="vent-connect--values">
                                      {sensor &&
                                        sensor.hasOwnProperty(key) &&
                                        processConnectedDevValue(key, sensor[key])}
                                    </span>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </List>
            ) : (
              <div className="no-conn-devices">
                <h6>{config?.strings?.warns?.noSensors}</h6>
              </div>
            )
          }
        </Grid>
      </Grid>

      <Grid container className="device-container-grid">
        <Accordion
          expanded={expanded === 'about-device'}
          onChange={handleExpandPanel('about-device')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="about-device-content"
            id="about-device-header"
          >
            <Typography
              style={{
                width: '40%',
                flexShrink: 0,
                fontSize: '18px',
                fontWeight: 500,
              }}
            >
              About
            </Typography>
            <Typography style={{ color: '#70707099' }}>
              Click to {expanded === 'about-device' ? 'hide' : 'show'} details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.firmware} </label>
                <div id="fmwv">{deviceSettings?.fmwv}</div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.macAddrr} </label>
                <div id="dmac">
                  {deviceSettings?.dmac || device?.details?.id}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="device-details--values"
            >
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.upTime} </label>
                <div id="uptm">
                  {Convertions.secToHours(deviceSettings?.uptm)}
                </div>
              </Grid>
              <Grid sm={12} item className="device-details--values-row">
                <label>{config?.strings?.labels?.conDevices} </label>
                <div id="cdvc">{deviceSettings?.conf?.cdvc}</div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>


      <Modal
        id="applyConfiguration-details"
        size="lg"
        show={applyConfigModal}
        onHide={handleCloseApplyModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply Configuration</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row className="text-msm-row">
              Select one of your configurations to and apply to your G3
            </Row>
            <Row className="icon-row">
              <FcDataConfiguration
                style={{
                  width: '120px',
                  height: 'auto',
                }}
              />
            </Row>
            <Form className="form-wrapper">
              <Form.Group className="mb-3" controlId="applyConfigCtrl">
                <Form.Label className="form-title">
                  <span>
                    Stored Configurations
                  </span>
                </Form.Label>

                <Row className="select-config-row">
                  <Row>
                    Select Configuration
                  </Row>
                  <Row>
                    <NativeSelect
                      className="select"
                      value={modalSelectConfig?.config_id}
                      onChange={handleSelectVentMode}
                    >
                      {configsList && configsList.map((config: any) => (
                        <option key={config.config_id} value={config.config_id}>
                          {config?.name || '. . .'}
                        </option>
                      ))}
                    </NativeSelect>
                  </Row>
                </Row>

                <Row className="select-target-row">
                  <Row>
                    Primary Exhaust Control
                  </Row>
                  <Row>
                    <NativeSelect
                      className="select"
                      value={modalSeletedCtrl?.cdid}
                      onChange={handleSelectTargetCtrl}
                    >
                      {targetOptions && targetOptions.map((target: any) => (
                        <option key={target.dmac} value={target.cdid}>
                          {target?.vent || '. . .'}
                        </option>
                      ))}
                    </NativeSelect>
                  </Row>
                </Row>

              </Form.Group>
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="default"
            className="btn btn-default"
            type="button"
            onClick={() => {
              handleCloseApplyModal();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary"
            type="button"
            onClick={handleApplyConfiguration}
            disabled={!enableApplyConfig}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
};




function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const {
    logs,
    loader,
    devices,
    userConfigs,
    systemSettings,
  } = dispatch || {};

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    setPeripheral: devices.setPeripheral,
    getDeviceLogs: logs.getDeviceLogs,
    listUserConfigs: userConfigs.listUserConfigs,
    showSystemSettings: systemSettings.showSystemSettings,
    handleUpdateSystemSetup: systemSettings.handleUpdateSystemSetup,
    handleUpdateSystemSettings: systemSettings.handleUpdateSystemSettings,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Aircycler);
