import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { IndoorAirProps } from './interface';
import { helpers } from './helpers';
import { DevicesUtils } from '../../utils';
import { io } from 'socket.io-client';
import { useAuth } from '../../../../../../../sessions/hooks/auth';
import { Summary, Details, SensorName } from './components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {
  TooltipView,
  Notification,
  Modal,
  DetailsHeader,
} from 'business/modules/admin/common';
import { FaExclamation } from 'react-icons/fa';
import { RiSave3Fill } from 'react-icons/ri';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import * as globalConfigs from '../../../../../../../../config/config';
import './styles.scss';

let INTERVALS: any = [];
let WIDGETSTATES_COPY: any = {};
let WEB_SOCKETS: any = {};
let REQUEST_STATES: any = {};

const MODEL_FORMAT: any = { ...config.model };
const MAIN_PROPS: any = ['pm25', 'co2', 'tVoc'];
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };
const ST_LABEL: string = config.strings.stLabel;

const IndoorAir: React.FC<IndoorAirProps> = ({
  indoorAirSettingsArr,
  indoorAirSettingsAreLoaded,
  sectionData,
  orderedSensors,
  connection,
  device,
  sensorName,
  sensorsAreLoaded,
  sensorsList,
  toggleUpdateMenuVisibility,
  endedLoading,
  editIndoorAirData,
  saveTheUpdatedIAValues,
  indoorAirAdminUpdatedValues,
  showMenu,
  valuesWereUpdated,
  modalMustClose,
  handleModalState,
  indoorAirSensorsDisplay,
  sensorsDisplayLoaded,
  saveSystemDetails,
  setup,
  setIAadmDetailsLoaded,
  indoorAirAdmDetails,
  designation,
  systemsCurrentStatus,
  viewSettings,
  mayDisplayBtn,
  extractedSettings,
}) => {
  const history = useHistory();
  const { adminToken } = useAuth();
  const [currentDeviceId, setCurrentDeviceId]: any = useState();
  const [periphData, setPeriphData]: any = useState({});
  const [sysSetup, setSysSetup] = useState<any>({});
  const [datapoints, setDataPoints]: any = useState([]);
  const [sysSettings, setSysSettings] = useState<object>({});
  const [showView, setShowView] = useState(config.showViewInitState);
  const [enabled, setIsEnabled]: any = useState(false);
  const [isOwnSetup, setIsOwnSetup] = useState<boolean>(false);
  const [currentFoDev, setCurrentFoDev] = useState<any>(null);
  const [currentFDChecked, setCurrentFDChecked] = useState<boolean>(false);
  const [onEditMode, setToEditMode] = useState<boolean>(false);
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isSensorSet, setIsSensorSet]: any = useState(false);
  const [widgetCkStates, setWidgetCkStates]: any = useState({});
  const [widgetStates, setWidgetStates]: any = useState({
    pm25: { ...MODEL_FORMAT },
    co2: { ...MODEL_FORMAT },
    tVoc: { ...MODEL_FORMAT },
  });

  const thereIsIAData: boolean | undefined =
    indoorAirSettingsArr &&
    indoorAirSettingsArr.length > 0 &&
    indoorAirSettingsAreLoaded;

  const clickWarningSign = (event: any) => {
    const { tagName, id } = event?.target;

    if (tagName !== null && tagName !== undefined) {
      if (
        (tagName === 'svg' || tagName === 'path') &&
        id === 'ci-warning-tooltip-adm'
      ) {
        saveSystemDetails(device);

        history.push({
          pathname: `/admin/dashboard/user-details/system-settings/${device?.device_id}`,
        });
      }
    }
  };

  const startSocket = async (
    _device: any,
    deviceId: string,
    data: any,
    channel: string,
  ) => {
    const { device_id }: any = _device || {};

    const _token: string = adminToken?.token;
    const { path, process } = data;

    let channelSTR: string = `${channel}-${deviceId}`;

    const socket: any =
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs &&
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0]
        ? io(globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0], {
            path: '/socket.io/',
            transports: ['websocket'],
            autoConnect: false,
            withCredentials: true,
            auth: {
              token: _token,
              path: path,
              process: process,
              channel: channel,
              name: deviceId,
              channelName: channelSTR,
            },
          })
        : null;

    if (socket) {
      WEB_SOCKETS[device_id] = {
        ...WEB_SOCKETS[device_id],
        [data.process]: {
          socket: socket.connect(),
          device: _device,
          channel: channelSTR,
          connected: false,
        },
      };

      WEB_SOCKETS[device_id][data.process].socket.on('connect', () => {
        if (
          WEB_SOCKETS.hasOwnProperty(device_id) &&
          WEB_SOCKETS[device_id][data.process].hasOwnProperty('connected')
        ) {
          WEB_SOCKETS[device_id][data.process].connected = true;
        }
      });

      WEB_SOCKETS[device_id][data.process].socket.on(
        'disconnect',
        (reason: any) => {},
      );

      WEB_SOCKETS[device_id][data.process].socket.on(
        'connect_error',
        (error: any) => {},
      );

      WEB_SOCKETS[device_id][data.process].socket.on(
        WEB_SOCKETS[device_id][data.process].channel,
        (data: any) => {
          const { datapoint } = data || {};

          if (datapoint) {
            let tempData: any = updateCurrentStates(device_id, datapoint);

            if (tempData && Object.keys(tempData).length > 0) {
              let tempStatesClone: any = _.cloneDeep(
                WIDGETSTATES_COPY[device_id],
              );

              for (const prop in tempData) {
                tempStatesClone = {
                  ...tempStatesClone,
                  [prop]: tempData[prop],
                };
              }

              setWidgetStates(_.cloneDeep(tempStatesClone));
            }
          }
        },
      );
    }
  };

  const destroySocket = (_device: any) => {
    destroyProcesses();
  };

  const destroyProcesses = () => {
    const processes = ['awair', 'airthings', 'Local.AirCycler', 'indoorAir'];

    Object.entries<any>(WEB_SOCKETS).map(([key, value]) => {
      processes.forEach((process: any) => {
        if (value.hasOwnProperty(process)) {
          value[process].socket.off('connect');
          value[process].socket.off('disconnect');
          value[process].socket.off('connect_error');

          if (value[process].channel) {
            value[process].socket.off(value[process].channel);
          }

          value[process].socket.disconnect();
        }
      });

      delete WEB_SOCKETS[key];
    });
  };

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
    localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
  };

  const toggleSection = (event: any) => {
    event.stopPropagation();
    setIsEnabled(!enabled);
    toggleUpdateMenuVisibility({ state: true, section: 'ia' });
  };

  const populateWidgetFields = (data: any, indoorAir: any, target: any) => {
    MAIN_PROPS.forEach((prop: string) => {
      if (data) {
        let tempCurrent: any = data.find(
          (item: any) => item._prop === prop && item.pointer === 'current',
        );

        if (tempCurrent && Object.values(tempCurrent).length > 0) {
          target[prop].current = tempCurrent.value;
        }
      }
      target[prop].max = indoorAir?.slider[prop] || -101;
      target[prop].init = true;
    });

    return target;
  };

  //updates the sensor values ('current' prop from widgetStates)
  const updateCurrentStates = (devId: string, data: any) => {
    const { datapoints, sensors } = data;

    const currentProps: any = ['pm25', 'co2', 'tVoc'];

    let prevValuesClone: any = _.cloneDeep(WIDGETSTATES_COPY[devId]);

    currentProps.forEach((prop: string) => {
      let tempIndex: number = sensors.findIndex(
        (sens: any) => sens === helpers.mapAirthingsDatapointProps(prop),
      );

      if (
        tempIndex &&
        datapoints &&
        datapoints.length > 0 &&
        datapoints[0][tempIndex] !== null &&
        datapoints[0][tempIndex] !== undefined
      ) {
        prevValuesClone[prop].current = datapoints[0][tempIndex];
      }
    });

    return _.cloneDeep(prevValuesClone);
  };

  const onChangeCO2 = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'ia' });

    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'co2');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    if (Object.values(periphData).length > 0) {
      setToEditMode(true);
    }
  };

  const onChangePm25 = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'ia' });

    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'pm25');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    if (Object.values(periphData).length > 0) {
      setToEditMode(true);
    }
  };

  const onChangeTvoc = (newValues: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'ia' });

    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'tVoc');

    if (tempValues) {
      const { prop, data }: any = tempValues;

      setWidgetStates((prevStates: any) => {
        return {
          ...prevStates,
          [prop]: {
            ...prevStates[prop],
            [data[0].pointer]: data[0].value,
            [data[1].pointer]: data[1].value,
          },
        };
      });
    }

    if (Object.values(periphData).length > 0) {
      setToEditMode(true);
    }
  };

  const onChangeWgtCk = (section: string, event: any, value: any) => {
    toggleUpdateMenuVisibility({ state: true, section: 'ia' });

    const { id } = event?.target;

    if (id === 'dmst' || id === 'fcst' || id === 'cfst') {
      setWidgetCkStates((prevVals: any) => {
        return {
          ...prevVals,
          [section]: {
            ...prevVals[section],
            [helpers.convertToStateProp(id)]: value,
          },
        };
      });
    } else {
      setWidgetCkStates((prevVals: any) => {
        return {
          ...prevVals,
          [section]: {
            ...prevVals[section],
            ['ventConnect']: {
              ...prevVals[section]['ventConnect'],
              [id]: value,
            },
          },
        };
      });
    }

    setToEditMode(true);
  };

  const handleNoSystemSettings = (devSensors: any) => {
    let fooDevData: any = devSensors.find((sensor: any) => {
      const { device_type } = sensor;

      return helpers.allowedSensor(device_type);
    });

    setCurrentFDChecked(true);

    setIsOwnSetup(false);
    return fooDevData;
  };

  const handleSystemSettings = (settings: any, devSensors: any) => {
    const hasSettings: boolean = Object.keys(settings).length > 0;

    if (hasSettings) {
      let sensorName: string =
        settings[Object.keys(settings)[0] as keyof typeof settings]?.add_sensor;
      let sensorId: string =
        settings[Object.keys(settings)[0] as keyof typeof settings]
          ?.add_sensor_id;

      if (sensorName && sensorName !== '' && sensorName !== 'Foobot') {
        let sensor: any = devSensors.find(
          (sens: any) =>
            sens.device_name === sensorName && sens.device_id === sensorId,
        );

        if (!sensor && devSensors.length > 0) {
          setIsSensorSet(false);
        } else {
          setIsOwnSetup(true);
        }

        setCurrentFDChecked(true);
        setCurrentFoDev(sensor);
        return sensor;
      }

      setCurrentFDChecked(true);
    }

    return;
  };

  const expandDetails = (value: any) => {
    switch (value) {
      case 'pm25':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            pm25: !showView.pm25,
          };
        });
        break;
      case 'co2':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            co2: !showView.co2,
          };
        });
        break;

      case 'tVoc':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            tVoc: !showView.tVoc,
          };
        });
        break;
      default:
        break;
    }
  };

  const checkIfSensorExists = (settingsObject: any) => {
    let sensorExists: any = [];
    let exists: boolean = false;

    if (sensorsAreLoaded && sensorsList && sensorsList.length > 0) {
      sensorExists = sensorsList.filter((item: any) => {
        return item.device_id === settingsObject['add_sensor_id'];
      });

      if (sensorExists.length > 0) {
        exists = true;
      } else {
        exists = false;
      }
    }
    return exists;
  };

  const renderIndoorAirValues = (deviceId: string) => {
    let settingsObject: any = {};

    if (thereIsIAData) {
      let _settings: any = {};
      let devSettings = indoorAirSettingsArr?.filter(
        (dev: any) => dev?.body?.data?.device_id === deviceId,
      );

      if (devSettings && devSettings.length > 0) {
        _settings = devSettings[0].body?.data?.settings || {};
      }

      if (_settings && Object.values(_settings).length > 0) {
        settingsObject = Object.values(_settings)[0];

        if (settingsObject.hasOwnProperty('add_sensor_id')) {
          //make sure the sensor that comes in settings object really exists yet:

          //if it doesn't:
          if (!checkIfSensorExists(settingsObject)) {
            return currentFoDev?.device_name;
          } else if (checkIfSensorExists(settingsObject)) {
            //if it does, return its name
            return settingsObject['add_sensor'];
          }
        } else if (
          !settingsObject.hasOwnProperty('add_sensor_id') &&
          settingsObject.hasOwnProperty('add_sensor')
        ) {
          let _theSensor = sensorsList.filter((sens: any) =>
            sens.device_name.startsWith(settingsObject['add_sensor']),
          );

          return _theSensor.length > 0 ? _theSensor[0].device_name : '';
        }
      } else {
        return currentFoDev?.device_name || '';
      }
    }

    return currentFoDev?.device_name || '';
  };

  const getSensorName = () => {
    if (sensorsDisplayLoaded) {
      let sensor: any =
        indoorAirSensorsDisplay && //make sure the array has length > 0 to access the [0]
        indoorAirSensorsDisplay.length > 0 &&
        indoorAirSensorsDisplay.filter(
          (item: any) => item.id === device.device_id,
        )[0];
      return <SensorName sensor={sensor} />;
    } else {
      return <Skeleton variant="text" height={20} width={100} />;
    }
  };

  const getCurrentValsForSensors = (
    sensorId: string,
    setIAadmDetailsLoaded: boolean,
    indoorAirAdmDetails: any,
  ) => {
    let data: any;
    if (setIAadmDetailsLoaded && indoorAirAdmDetails.length > 0) {
      indoorAirAdmDetails.map((item: any) => {
        if (item.deviceId === sensorId) {
          data = item;
        }
      });
    }

    return data || {};
  };

  const handleSavingValues = () => {
    let temporaryState = indoorAirAdminUpdatedValues;

    MAIN_PROPS.forEach((prop: string) => {
      temporaryState.setup.slider[prop] =
        temporaryState.setup.slider.hasOwnProperty(prop) &&
        temporaryState.setup.slider[prop] !== null &&
        temporaryState.setup.slider[prop] !== undefined &&
        temporaryState.setup.slider[prop] !== -101
          ? temporaryState.setup.slider[prop]
          : SLIDERS_DEFAULTS[prop];

      temporaryState.setup.checkbox[prop] = {
        ...temporaryState.setup.checkbox[prop],
      };
    });

    saveTheUpdatedIAValues(temporaryState);

    return temporaryState;
  };

  const handleUserOption = async (action: string) => {
    switch (action) {
      case 'cancel':
        toggleUpdateMenuVisibility({ state: false, section: 'ia' });

        return;
      case 'save':
        let handledValues: any = handleSavingValues();

        //DO not forget to comment this if some handledValues value tests are needed, so no real update is performed
        let result: any = await editIndoorAirData(handledValues);
        if (result && result?.statusCode === 200) {
          Notification({
            title: 'Success!',
            message: config?.notifications?.update?.success,
            type: 'success',
          });
          toggleUpdateMenuVisibility({ state: false, section: 'ia' });
        } else {
          Notification({
            title: 'Error!',
            message: config?.notifications?.update?.error,
            type: 'error',
          });
          toggleUpdateMenuVisibility({ state: false, section: 'ia' });
        }

        break;
      default:
        break;
    }
  };

  //this can be called on tab changing, since the API request was previously made on entering View
  const handleIndoorAirValues = async (device: any, connectedDevices: any) => {
    //it's not needed to call sysSettings here, because they come from VIEW component

    let deviceType: any;

    // viewSettings[device?.device_id], is === to sysSettings

    const { statusCode, body } = viewSettings[device?.device_id] || {};

    let widgetStatesClone: any = _.cloneDeep({ ...widgetStates });
    let currentSensor: any;
    let currentSlidersStates: any;

    if (helpers.settingsExist(statusCode, viewSettings, device)) {
      const { settings, setup } = viewSettings[device?.device_id]?.body?.data;

      setSysSettings({ ...settings });
      endedLoading(true, 'sys-set');

      if (settings && Object.keys(settings).length > 0) {
        currentSensor = handleSystemSettings(settings, orderedSensors);

        if (currentSensor) {
          const { details, device_type } = currentSensor;
          deviceType = device_type;

          //no need to call the processDevice fns because they are not from admin; they only work
          // on users dashboard
          // in admin we get those values other way

          if (device_type === 'foobot') {
            // currentSlidersStates = await processDeviceV2(
            //   details.uuid,
            //   widgetStatesClone,
            // );
          } else {
            // await processDevice(details.uuid, device_type, widgetStatesClone);
          }
        }
      }
      if (setup) {
        const { indoorAir } = setup || {};

        setSysSetup({ ...setup });

        if (Object.keys(connectedDevices).length > 0) {
          const { data } = currentSlidersStates || {};

          populateWidgetFields(data, indoorAir, widgetStatesClone); //OK

          if (indoorAir?.checkbox) setWidgetCkStates({ ...indoorAir.checkbox });

          if (deviceType && deviceType === 'foobot') {
            setWidgetStates((prevStates: any) => {
              return {
                ...prevStates,
                ...widgetStatesClone,
              };
            });
          }
        }
      }
    } else {
      let tempSensor: any = handleNoSystemSettings(orderedSensors);

      if (
        helpers.sensorAndSettings(statusCode, tempSensor, viewSettings, device)
      ) {
        const { setup } = body?.data || {};

        const { device_type } = tempSensor;
        deviceType = device_type;

        //the pprocessDevs function calls were removed because they are not from admin (no adm token)

        if (setup) {
          const { indoorAir } = setup || {};

          setSysSetup({ ...setup });

          if (Object.keys(connectedDevices).length > 0) {
            const { data } = currentSlidersStates || {};
            populateWidgetFields(data, indoorAir, widgetStatesClone);

            if (indoorAir?.checkbox)
              setWidgetCkStates({ ...indoorAir.checkbox });

            if (deviceType && deviceType === 'foobot') {
              setWidgetStates((prevStates: any) => {
                return {
                  ...prevStates,
                  ...widgetStatesClone,
                };
              });
            }
          }
        }
      }
    }

    setIsInit(true);
  };

  useEffect(() => {
    if (device) {
      let tempPeriphs: any = helpers.extractPeripherals(device.details);

      setPeriphData(tempPeriphs);

      setCurrentDeviceId(device.device_id);

      if (!isInit) {
        let tempStates: any = helpers.prepareCkboxStatesBySection(tempPeriphs);

        setWidgetCkStates(tempStates);

        //new fn to replace handleIAStates() :
        handleIndoorAirValues(device, tempPeriphs);
      }
    }
    return () => {};
  }, [device]);

  useEffect(() => {
    if (enabled && helpers.isDefined(currentDeviceId)) {
      let storedState: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (helpers.isDefined(storedState)) {
        setIsExpandedCtrl(storedState === 'true');
      }
    }
    return () => {};
  }, [enabled, currentDeviceId]);

  useEffect(() => {
    if (isInit) {
      (async () => {
        let widgetStatesClone: any = _.cloneDeep({ ...widgetStates });
        if (Object.keys(periphData).length > 0 && !onEditMode) {
          const { indoorAir } = sysSetup || {};

          MAIN_PROPS.forEach((prop: string) => {
            widgetStatesClone[prop].max =
              indoorAir?.slider &&
              indoorAir?.slider.hasOwnProperty(prop) &&
              helpers.isDefined(indoorAir?.slider[prop])
                ? indoorAir?.slider[prop]
                : -101;
            widgetStatesClone[prop].init = true;
          });

          if (indoorAir) {
            const { checkbox, turnedOn } = indoorAir;
            if (helpers.isDefined(checkbox)) {
              setWidgetCkStates({ ...checkbox });
            }
            if (helpers.isDefined(turnedOn)) {
              setIsEnabled(turnedOn);
            }
            startSocket(
              device,
              device?.device_id,
              { path: 'notifications', process: 'indoorAir' },
              `Admin-Setup-Update-indoorAir`,
            );
          }
          setWidgetStates((prevStates: any) => {
            return {
              ...prevStates,
              ...widgetStatesClone,
            };
          });
          if (currentFoDev) {
            const { details, device_type } = currentFoDev || {};
            const { device_id, device_name }: any = device || {};
            if (!REQUEST_STATES[device.device_id]) {
              REQUEST_STATES[device.device_id] = true;
            }
            if (isOwnSetup) {
              if (
                device_type === 'airthings' &&
                WEB_SOCKETS[device_id] &&
                !WEB_SOCKETS[device_id].hasOwnProperty(device_type)
              ) {
                startSocket(
                  device,
                  details.uuid,
                  { path: 'notifications', process: 'airthings' },
                  'Admin-AirThings',
                );
              } else if (
                (device_type === 'awair' ||
                  device_type === 'awair-element' ||
                  device_type === 'awair-omni') &&
                WEB_SOCKETS[device_id] &&
                !WEB_SOCKETS[device_id].hasOwnProperty('awair')
              ) {
                startSocket(
                  device,
                  details.uuid,
                  { path: 'notifications', process: 'awair' },
                  'Admin-Awair',
                );
              } else if (
                device_type === 'aircycler-local' &&
                WEB_SOCKETS[device_id] &&
                !WEB_SOCKETS[device_id].hasOwnProperty('Local.AirCycler')
              ) {
                startSocket(
                  device,
                  details.uuid,
                  { path: 'notifications', process: 'Local.AirCycler' },
                  'Admin-Local.AirCycler',
                );
              }
            }
          }
        }
      })();
    }
  }, [sysSetup, isInit]);

  //Set datapoints - pm25 / co2 / tVoc
  useEffect(() => {
    if (sensorName && sensorsAreLoaded) {
      let _sensor: any = helpers.returnSensor(sensorsList, sensorName);
      _sensor.length > 0 && setDataPoints(_sensor[0].details?.data?.datapoints);
    }
    return () => {};
  }, [sensorName, sensorsAreLoaded]);

  useEffect(() => {
    if (!enabled && isInit) {
      let vals: any = {
        system_id: device?.device_id,
        setup: {
          slider: {
            pm25: widgetStates.pm25.max,
            co2: widgetStates.co2.max,
            tVoc: widgetStates.tVoc.max,
          },
          checkbox: {
            pm25: widgetCkStates.pm25,
            co2: widgetCkStates.co2,
            tVoc: widgetCkStates.tVoc,
          },
          turnedOn: enabled,
        },
      };

      const update = async () => {
        let result: any = await editIndoorAirData(vals);
        if (result && result?.statusCode === 200) {
          Notification({
            title: 'Success!',
            message: config?.notifications?.update?.success,
            type: 'success',
          });
          toggleUpdateMenuVisibility({ state: false, section: 'ia' });
        } else {
          Notification({
            title: 'Error!',
            message: config?.notifications?.update?.error,
            type: 'error',
          });
          toggleUpdateMenuVisibility({ state: false, section: 'ia' });
        }
      };
      update();
    }
  }, [enabled]);

  useEffect(() => {
    if (
      sysSettings !== null &&
      sysSettings !== undefined &&
      Object.values(sysSettings).length > 0
    ) {
      const { add_sensor, add_sensor_id } = Object.values(sysSettings)[0];
      if (
        sensorsList &&
        sensorsList.length > 0 &&
        add_sensor !== '' &&
        add_sensor_id !== ''
      ) {
        const sysSensor: any = sensorsList.find(
          (sensor: any) =>
            sensor?.device_name === add_sensor &&
            sensor.device_id === add_sensor_id,
        );
        setIsSensorSet(sysSensor !== null && sysSensor !== undefined);

        if (sysSensor && setIAadmDetailsLoaded && device) {
          let result: any = getCurrentValsForSensors(
            sysSensor?.device_id,
            setIAadmDetailsLoaded,
            indoorAirAdmDetails,
          );
          if (Object.keys(result).length > 0) {
            if (sysSensor?.device_id === Object.values(result)[0]) {
              setWidgetStates((prevStates: any) => {
                return {
                  ...prevStates,
                  co2: {
                    ...prevStates.co2,
                    current: result?.co2,
                  },
                  pm25: {
                    ...prevStates.pm25,
                    current: result?.pm,
                  },
                  tVoc: {
                    ...prevStates.tVoc,
                    current: result?.tVoc,
                  },
                };
              });
            }
          }
        }
      } else {
        setIsSensorSet(add_sensor !== '' && add_sensor_id !== '');
      }
    }
  }, [sysSettings, device, setIAadmDetailsLoaded, indoorAirAdmDetails]);

  useEffect(() => {
    saveTheUpdatedIAValues({
      system_id: device?.device_id,
      setup: {
        slider: {
          pm25: widgetStates.pm25.max,
          co2: widgetStates.co2.max,
          tVoc: widgetStates.tVoc.max,
        },
        checkbox: {
          pm25: widgetCkStates.pm25,
          co2: widgetCkStates.co2,
          tVoc: widgetCkStates.tVoc,
        },
        turnedOn: enabled,
      },
    });
    return () => {};
  }, [widgetStates, widgetCkStates, enabled]);

  useEffect(() => {
    return () => {
      if (INTERVALS && INTERVALS.length > 0) {
        for (const interval of INTERVALS) {
          clearInterval(interval);
        }
        INTERVALS = [];
      }
      if (REQUEST_STATES && Object.keys(REQUEST_STATES).length > 0) {
        for (const key in REQUEST_STATES) {
          REQUEST_STATES[key] = false;
        }
      }
      destroySocket(device);
      setCurrentFoDev(null);
      setIsInit(false);
    };
  }, []);

  //this will be used on socket upd
  useEffect(() => {
    const { device_id }: any = device || {};
    if (
      widgetStates !== null &&
      widgetStates !== undefined &&
      device_id !== null &&
      device_id !== undefined
    ) {
      WIDGETSTATES_COPY[device_id] = _.cloneDeep({ ...widgetStates });
    }
  }, [widgetStates]);

  useEffect(() => {
    if (setup) {
      setSysSetup({ ...setup?.body?.data?.setup });
    }
  }, [setup]);

  return (
    <div className="acc-cont">
      <DetailsHeader
        designation={designation}
        sensorProp={''}
        icon={
          systemsCurrentStatus &&
          Object.keys(systemsCurrentStatus).length > 0 &&
          systemsCurrentStatus.hasOwnProperty(device?.device_id) &&
          systemsCurrentStatus[device?.device_id].statusIsLoaded &&
          systemsCurrentStatus[device?.device_id].stopped ? (
            <TooltipView title="Disconnected">
              <span>
                <div className="circle-excl">
                  <FaExclamation
                    size="0.8rem"
                    color="red"
                    className="system-status-icon"
                  />
                </div>
              </span>
            </TooltipView>
          ) : null
        }
      />

      <div className="mt16">
        <Accordion
          className={sectionData.className}
          square
          expanded={expandedCtrl && enabled}
          onChange={handleChange(``)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={''}
            id={''}
          >
            <Summary
              connection={connection}
              config={config}
              clickWarningSign={clickWarningSign}
              mayDisplayBtn={mayDisplayBtn}
              systemsCurrentStatus={systemsCurrentStatus}
              enabled={enabled}
              toggleSection={toggleSection}
              currentFoDev={currentFoDev}
              currentFDChecked={currentFDChecked}
              sensorsAreLoaded={sensorsAreLoaded}
              sensorsList={sensorsList}
              renderIndoorAirValues={renderIndoorAirValues}
              device={device}
              isSensorSet={isSensorSet}
              widgetStates={widgetStates}
              getSensorName={getSensorName}
            />
          </AccordionSummary>

          <AccordionDetails>
            <Details
              datapoints={datapoints}
              helpers={helpers}
              widgetStates={widgetStates}
              device={device}
              onChangePm25={onChangePm25}
              showView={showView}
              extractedSettings={extractedSettings}
              widgetCkStates={widgetCkStates}
              onChangeWgtCk={onChangeWgtCk}
              showMenu={showMenu}
              valuesWereUpdated={valuesWereUpdated}
              toggleUpdateMenuVisibility={toggleUpdateMenuVisibility}
              onChangeCO2={onChangeCO2}
              expandDetails={expandDetails}
              onChangeTvoc={onChangeTvoc}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        config={config}
        id={`indoor--air--changes`}
        title={config.titles.ia}
        icon={<RiSave3Fill className="edit" />}
        body={config.strings.confirmation}
        onClick={() => handleUserOption(`save`)}
        onClickCancel={() => {
          handleUserOption('cancel');
        }}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={config.labels.ia}
        className={`small-button ok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={`Are you sure you wish to proceed?`}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminSectionsState, adminDevicesState } = state;
  return {
    indoorAirSettingsAreLoaded: adminSectionsState.indoorAirSettingsAreLoaded,
    indoorAirSettingsArr: adminSectionsState.indoorAirSettingsArr,
    sensorsAreLoaded: adminDevicesState.sensorsAreLoaded,
    sensorsList: adminDevicesState.sensorsList,
    indoorAirAdminUpdatedValues: adminSectionsState.indoorAirAdminUpdatedValues,
    showMenu: adminSectionsState.indoorAirChangesMenuIsVisible,
    valuesWereUpdated: adminSectionsState.indoorAirAdminValuesUpdated,
    modalMustClose: adminSectionsState.modalMustClose,
    indoorAirSensorsDisplay: adminDevicesState.indoorAirSensorsDisplay,
    sensorsDisplayLoaded: adminDevicesState.sensorsDisplayLoaded,
    setIAadmDetailsLoaded: adminSectionsState.setIAadmDetailsLoaded,
    indoorAirAdmDetails: adminSectionsState.indoorAirAdmDetails,
    systemsCurrentStatus: adminDevicesState.systemsCurrentStatus,
    viewSettings: adminSectionsState.viewSettings,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminSectionsState, adminDevicesState } = dispatch;
  return {
    saveTheUpdatedIAValues: adminSectionsState.saveTheUpdatedIAValues,
    editIndoorAirData: adminSectionsState.editIndoorAirData,
    endedLoading: adminDevicesState.endedLoading,
    toggleUpdateMenuVisibility: adminSectionsState.toggleUpdateMenuVisibility,
    handleModalState: adminSectionsState.handleModalState,
    saveSystemDetails: adminDevicesState.saveSystemDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndoorAir);
