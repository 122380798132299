export default {
  strings: {
    title: 'Users e-mail list',
    breadcrumbList: `Get users\'s e-mail addresses`,
    select: 'SELECT',
    unSelect: 'UNSELECT',
    all: 'ALL',
    downlSelected: 'DOWNLOAD SELECTED',
  },
  emailsList: {
    mainTitle: `Download options`,
    pText: `Decide which users' e-mails you wish to include in the list`,
    breadcrumbItem: `/admin/dashboard/get-emails`,
    class: `jumbotron container-jumbo jmb-min-h--1`,
  },
  modalStrings: {
    title: `Information`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: ``,
    noUndo: ``,
    description: `The selected e-mails for download are the following:`,
    labelOK: ` OK`,
  },
  columns: [
    {
      title: 'First name',
      field: 'first_name',
      defaultSort: 'desc',
    },
    { title: 'Last name', field: 'last_name' },
    { title: 'E-mail', field: 'email' },
  ],
  //mock data for testing purposes
  mocktest: [
    {
      email: 'someEmail@email.com',
    },
    {
      email: '123someEmail@email.com',
    },
  ],
};
