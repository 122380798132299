import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

interface ActionsMenuProps {
  data?: any;
  onDelete?: any;
  onEdit?: any;
  onEditLocation?: any;
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  data,
  onDelete,
  onEdit,
  onEditLocation,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onclickActions = (zone: any, event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {data && (
        <>
          <IconButton
            id="more-button"
            aria-controls={`actions-menu-${data.device_id}`}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={onclickActions.bind(null, data)}
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            id={`actions-menu-${data.device_id}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
          >
            <MenuItem onClick={onDelete.bind(null, data, handleClose)}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default ActionsMenu;
