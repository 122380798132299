import React, { useState, useCallback, useEffect, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { PressureConnectProps } from './interface';
import _ from 'lodash';
import numeral from 'numeral';
import { helpers } from './helpers';
import { DevicesUtils } from './utils';
import { io } from 'socket.io-client';
import { useStatesReducer, statesReducer } from './hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {
  TooltipView,
  SwitchBtn,
  Slider,
  Modal,
  Notification,
  DetailsHeader,
} from 'business/modules/admin/common';
import * as globalConfigs from '../../../../../../../../config/config';
import { useAuth } from '../../../../../../../sessions/hooks/auth';
import { RiSave3Fill } from 'react-icons/ri';
import { AiOutlineControl } from 'react-icons/ai';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { FaExclamation } from 'react-icons/fa';
import './styles.scss';

const MODEL_FORMAT = { ...config.model };
const DEFAULT_EXTREMS = { ...config.sliderValues };
const ST_LABEL: string = config.strings.stLabel;
const SLIDERS_DEFAULTS: any = { ...config.defaults };
let WEB_SOCKETS: any = {};
let INTERVALS: any = [];
let REQUEST_STATES: any = {};

const PressureConnect: React.FC<PressureConnectProps> = ({
  device,
  setup,
  sectionData,
  element,
  setPCConfirmation,
  handleModalState,
  modalMustClose,
  editPressureConn,
  setCurrentDev,
  currentDev,
  loading,
  designation,
  systemsCurrentStatus,
}) => {
  const { adminToken } = useAuth();
  const [switches, setSwitches] = useState([]);
  const [expandedCtrl, setIsExpandedCtrl] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [sysSetup, setSysSetup] = useState<any>({});
  const [showSaveBtn, setShowSaveBtn] = useState<any>(false);
  const [peripherals, setPeripherals] = useState<any>({});
  const [widgetCkStates, setWidgetCkStates] = useState<any>({});
  const [isInit, setIsInit] = useState<boolean>(false);
  const [currentDeviceId, setCurrentDeviceId] = useState<any>();
  const [showView, setShowView] = useState<any>({
    pa: true,
  });
  const [widgetStates, setWidgetStates]: any = useStatesReducer(statesReducer, {
    pa: { ...MODEL_FORMAT },
  });

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    setIsExpandedCtrl(isExpanded);
    localStorage.setItem(`${ST_LABEL}${currentDeviceId}`, isExpanded);
  };

  const handleSavePreConnData = async () => {
    loading.start('Updating Pressure Connect information...');

    let response: any = await editPressureConn(currentDev);
    if (response?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Error',
        message: config?.notifications?.update?.error,
        type: 'error',
      });
    }

    loading.stop();
  };

  const toggleSection = () => {
    setPCConfirmation(true);
    setEnabled(!enabled);
    setShowSaveBtn(true);
  };

  const transformSingleSlider = useCallback((values: any) => {
    const { max, min, current } = values;
    return [current, max === -101 ? 150 : max];
  }, []);

  const onChangePressure = (newValues: any) => {
    setPCConfirmation(true);
    const tempValues: any = DevicesUtils.arrayPackageByProp(newValues, 'pa');

    setWidgetStates(tempValues);

    if (Object.values(peripherals).length > 0) {
      setShowSaveBtn(true);
    }
  };

  const expandDetails = (value: any) => {
    switch (value) {
      case 'pa':
        setShowView((prevState: any) => {
          return {
            ...prevState,
            pa: !showView.pa,
          };
        });
        break;
      default:
        break;
    }
  };

  const onChangeWgtCk = (label: string, event: any, value: any) => {
    setPCConfirmation(true);
    const { id } = event?.target;
    const props: any = id.split('-');

    if (props && props[0]) {
      if (id === 'dmst' || id === 'fanConnect' || id === 'cfcm') {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            [id]: value,
          };
        });
      } else {
        setWidgetCkStates((prevVals: any) => {
          return {
            ...prevVals,
            ['ventConnect']: {
              ...prevVals['ventConnect'],
              [props[0]]: value,
            },
          };
        });
      }
    }

    setShowSaveBtn(true);
  };

  const prepareValuesToSave = (
    sliderVals: any,
    ckValues: any,
    _device: any,
  ) => {
    const { device_id } = _device;
    let tempSetup: any = {};
    tempSetup.slider = sliderVals.pa.max;
    tempSetup = {
      ...tempSetup,
      checkbox: { ...ckValues },
      turnedOn: enabled,
    };

    return {
      system_id: device_id,
      setup: { pressureConnect: tempSetup },
    };
  };

  const startSocket = async (
    _device: any,
    deviceId: string,
    data: any,
    channel: string,
  ) => {
    const { device_id }: any = _device || {};
    const _token: string = adminToken?.token;
    const { path, process } = data;
    let channelSTR: string = `${channel}-${deviceId}`;

    const socket: any =
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs &&
      globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0]
        ? io(globalConfigs?.default?.apis?.jitBE?.baseSocketURLs[0], {
            path: '/socket.io/',
            transports: ['websocket'],
            autoConnect: false,
            withCredentials: true,
            auth: {
              token: _token,
              path: path,
              process: process,
              channel: channel,
              name: deviceId,
              channelName: channelSTR,
            },
          })
        : null;

    if (socket) {
      WEB_SOCKETS[device_id] = {
        socket: socket.connect(),
        device: _device,
        channel: channelSTR,
        connected: false,
      };

      WEB_SOCKETS[device_id].socket.on('connect', () => {
        WEB_SOCKETS[device_id].connected = true;
      });

      WEB_SOCKETS[device_id].socket.on('disconnect', (reason: any) => {});

      WEB_SOCKETS[device_id].socket.on('connect_error', (error: any) => {});

      WEB_SOCKETS[device_id].socket.on(
        WEB_SOCKETS[device_id].channel,
        (data: any) => {
          //Todo: add socket update states
        },
      );
    }
  };

  const destroySocket = (_device: any) => {
    const { device_id }: any = _device || {};

    if (
      WEB_SOCKETS[device_id] !== null &&
      WEB_SOCKETS[device_id] !== undefined
    ) {
      WEB_SOCKETS[device_id].socket.off('connect');
      WEB_SOCKETS[device_id].socket.off('disconnect');
      WEB_SOCKETS[device_id].socket.off('connect_error');

      if (WEB_SOCKETS[device_id].channel) {
        WEB_SOCKETS[device_id].socket.off(WEB_SOCKETS[device_id].channel);
      }

      WEB_SOCKETS[device_id].socket.disconnect();
      WEB_SOCKETS[device_id] = null;

      delete WEB_SOCKETS[device_id];
    }
  };

  const saveChanges = async (data: any) => {
    let response: any = await editPressureConn(data);
    if (response?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Error',
        message: config?.notifications?.update?.error,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (!enabled && isInit) {
      const devSetup: any = prepareValuesToSave(
        widgetStates,
        widgetCkStates,
        device,
      );

      let data: any = {
        system_id: devSetup?.system_id,
        setup: devSetup?.setup?.pressureConnect,
      };

      saveChanges(data);
    }

    if (enabled) {
      let storedState: any = localStorage.getItem(
        `${ST_LABEL}${currentDeviceId}`,
      );

      if (storedState !== null && storedState !== undefined) {
        setIsExpandedCtrl(storedState === 'true');
      }
    }
    return () => {};
  }, [enabled]);

  useEffect(() => {
    if (device) {
      const { cdvs } = device.details?.settings?.conf || {};
      let tempPeriphs: any = helpers.extractPeripherals(device.details);
      setPeripherals(tempPeriphs);
      setCurrentDeviceId(device.device_id);

      if (!isInit) {
        let tempStates: any = helpers.prepareCkboxStates(tempPeriphs);

        setWidgetCkStates(tempStates);
        setIsInit(true);
      }
    }
    return () => {};
  }, [device]);

  useEffect(() => {
    let modelClone: any = _.cloneDeep({ ...MODEL_FORMAT });

    if (Object.values(peripherals).length > 0) {
      const dfpsResult: any = DevicesUtils.getPressureValue({ ...device });

      const { pressureConnect } = sysSetup || {};

      modelClone.init = true;
      modelClone.max =
        pressureConnect && pressureConnect.hasOwnProperty('slider')
          ? pressureConnect.slider
          : -101;
      modelClone.current = dfpsResult;

      if (pressureConnect) {
        const { checkbox, turnedOn } = pressureConnect;

        if (checkbox !== null && checkbox !== undefined) {
          setWidgetCkStates({ ...checkbox });
        }
        if (turnedOn !== null && turnedOn !== undefined) {
          setEnabled(turnedOn);
        }
      } else {
        modelClone.init = true;
      }

      setWidgetStates({
        type: 'updateOnce',
        prop: 'pa',
        data: modelClone,
      });
    }
    return () => {};
  }, [peripherals, sysSetup]);

  useEffect(() => {
    let periphsData: any = helpers.getPeripheralsList(peripherals);
    setSwitches(periphsData);
  }, [peripherals]);

  useEffect(() => {
    setCurrentDev({
      ...currentDev,
      system_id: element?.device_id,
      setup: {
        slider: widgetStates?.pa?.max,
        checkbox: { ...widgetCkStates },
        turnedOn: enabled,
      },
    });
  }, [widgetCkStates, enabled, widgetStates]);

  useEffect(() => {
    if (setup) {
      setSysSetup({ ...setup?.body?.data?.setup });
    }
  }, [setup]);

  useEffect(() => {
    if (isInit) {
      (async () => {
        const { device_id }: any = device || {};

        if (sysSetup && Object.keys(sysSetup).length > 0) {
          if (!WEB_SOCKETS.hasOwnProperty(device_id)) {
            startSocket(
              element,
              element?.device_id,
              { path: 'notifications', process: 'pressureConnect' },
              `Admin-Setup-Update-pressureConnect`,
            );
          }
        }
      })();
    }
  }, [isInit, sysSetup]);

  useEffect(() => {
    return () => {
      if (INTERVALS && INTERVALS.length > 0) {
        for (const interval of INTERVALS) {
          clearInterval(interval);
        }
        INTERVALS = [];
      }

      if (REQUEST_STATES && Object.keys(REQUEST_STATES).length > 0) {
        for (const key in REQUEST_STATES) {
          REQUEST_STATES[key] = false;
        }
      }

      destroySocket(device);
      setIsInit(false);
    };
  }, []);

  return (
    <div className="press-connect-acc">
      <DetailsHeader
        designation={designation}
        sensorProp={''}
        icon={
          systemsCurrentStatus &&
          Object.keys(systemsCurrentStatus).length > 0 &&
          systemsCurrentStatus.hasOwnProperty(device?.device_id) &&
          systemsCurrentStatus[device?.device_id].statusIsLoaded &&
          systemsCurrentStatus[device?.device_id].stopped ? (
            <TooltipView title="Disconnected">
              <span>
                <div className="circle-excl">
                  <FaExclamation
                    size="0.8rem"
                    color="red"
                    className="system-status-icon"
                  />
                </div>
              </span>
            </TooltipView>
          ) : null
        }
      />

      <div style={{ marginTop: '16px' }}>
        <Accordion
          className={sectionData.className}
          square
          expanded={expandedCtrl && enabled}
          onChange={handleChange(``)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={''}
            id={''}
          >
            <div className={`container admin-section-container p-conn-acc`}>
              <div className="row pdg">
                <div className="col-xs-12 col-sm-12 col title-label-1">
                  <div className={`card crd-details`}>
                    <div
                      className={`card-body spec-crd-body pc-card-body crd-details`}
                    >
                      <h5 className={`card-title`}>
                        {config.strings.cardTitle}
                      </h5>
                      <p className={`card-subtitle mb-2 text-muted lead-sm`}>
                        <AiOutlineControl className="pconn-icon pc-icon-mr" />{' '}
                        {element?.device_name} {config.strings.settings}
                      </p>

                      <span
                        className={
                          enabled ? `values-pc-active` : `values-pc-inactive`
                        }
                      >
                        {numeral(widgetStates?.pa?.current).format(
                          config.formats.pa,
                        )}{' '}
                        {config.strings.pa}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-auto pdg pb-8">
                  <TooltipView
                    title={
                      <Fragment>
                        <span className="acc-tooltp-font">
                          {config?.strings?.title1}
                        </span>
                        <p className="tooltip-p">{config?.strings?.str3}</p>
                      </Fragment>
                    }
                  >
                    <div
                      className="row containing-switch-pc"
                      id="switch-box--pc"
                    >
                      <SwitchBtn
                        id={`${`pressure-connect-adm`}${device.device_id}`}
                        value={enabled}
                        onClick={toggleSection}
                        className={`enable-disable-admin`}
                        disabled={
                          systemsCurrentStatus &&
                          Object.keys(systemsCurrentStatus).length > 0 &&
                          systemsCurrentStatus.hasOwnProperty(
                            device?.device_id,
                          ) &&
                          systemsCurrentStatus[device?.device_id]
                            .statusIsLoaded &&
                          systemsCurrentStatus[device?.device_id].stopped
                            ? true
                            : false
                        }
                      />
                    </div>
                  </TooltipView>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="container pdg pconn-details">
              <div className="row">
                <div className="col-12 intro pdg pb-32">
                  <div className="row pdg">
                    <div className="col pdg">
                      <div className="row pdg">
                        <span className="slider-label2 pdg intro">
                          {config.strings.pa}
                        </span>
                      </div>
                      <div className="row pdg">
                        <span className="slider-label2--sm pdg">
                          {config.strings.paSettings}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Slider
                    symbol=""
                    id={`w-pa-adm`}
                    values={transformSingleSlider(widgetStates.pa)}
                    onChange={onChangePressure}
                    max={DEFAULT_EXTREMS.pa.max}
                    min={DEFAULT_EXTREMS.pa.min}
                    step={1}
                    col={2}
                    showLabel={true}
                    defaultMark={true}
                    defaultMarkValues={{
                      top: 20,
                      left: '75%',
                      value: SLIDERS_DEFAULTS.pa.max,
                    }}
                  />
                </div>
              </div>

              <div className="row">
                {Object.values(peripherals).length > 0 && (
                  <div
                    className="container up-expand-section"
                    onClick={() => expandDetails('pa')}
                  >
                    <div
                      className={`row section-ns-padding`}
                      onClick={() => expandDetails('pa')}
                    >
                      {showView.pa && (
                        <div
                          className={`container pdg`}
                          onClick={(event: any) => {
                            event.stopPropagation();
                          }}
                          id="pa-internal-1"
                        >
                          <div className={`row info-periphs2 to-the-left`}>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 left-mrg-lg">
                              {switches &&
                                Object.keys(switches).length > 0 &&
                                Object.entries<any>(switches)
                                  .slice(
                                    0,
                                    Math.ceil(
                                      Object.entries(switches).length / 2,
                                    ),
                                  )
                                  .map(([key, value], index) => {
                                    return (
                                      <div
                                        className="row row-label-switch"
                                        key={index}
                                      >
                                        {helpers.isVCorFC(value) ? (
                                          <div className="row pdg">
                                            <div
                                              className="col pdg"
                                              id="vent-conn-container-data-1"
                                            >
                                              <div className="row">
                                                <span className="label-per1 pdg float--left">
                                                  {value?.id}
                                                </span>
                                              </div>{' '}
                                              <div className="row lower-label-row-vc">
                                                <span className="pdg float--left lower-label1">
                                                  {helpers.renderLabel(value)}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-2 centered-vert-horiz">
                                              <SwitchBtn
                                                id={value.id}
                                                value={helpers.retrieveValue(
                                                  value.type,
                                                  widgetCkStates,
                                                  value.id,
                                                )}
                                                className="switch--style"
                                                onChange={onChangeWgtCk.bind(
                                                  null,
                                                  'pa',
                                                )}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row pdg">
                                            <div className="col pdg not-vc-label">
                                              <span className="label-per">
                                                {value.name}
                                              </span>
                                            </div>
                                            <div className="col-2 centered-vert-horiz">
                                              <SwitchBtn
                                                id={value.id}
                                                value={helpers.retrieveValue(
                                                  value.type,
                                                  widgetCkStates,
                                                  value.id,
                                                )}
                                                className="switch--style"
                                                onChange={onChangeWgtCk.bind(
                                                  null,
                                                  'pa',
                                                )}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 left-mrg-lg-2">
                              {switches &&
                                Object.keys(switches).length > 0 &&
                                Object.entries<any>(switches)
                                  .slice(
                                    Math.ceil(
                                      Object.entries(switches).length / 2,
                                    ),
                                  )
                                  .map(([key, value], index) => {
                                    return (
                                      <div
                                        className="row row-label-switch"
                                        key={index}
                                      >
                                        {helpers.isVCorFC(value) ? (
                                          <div className="row pdg">
                                            <div className="row pdg">
                                              <div
                                                className="col pdg"
                                                id="vent-conn-container-data-1"
                                              >
                                                <div className="row">
                                                  <span className="label-per1 pdg float--left">
                                                    {value?.id}
                                                  </span>
                                                </div>
                                                <div className="row lower-label-row-vc">
                                                  <span className="pdg float--left lower-label1">
                                                    {helpers.renderLabel(value)}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-2 centered-vert-horiz">
                                                <SwitchBtn
                                                  id={value.id}
                                                  value={helpers.retrieveValue(
                                                    value.type,
                                                    widgetCkStates,
                                                    value.id,
                                                  )}
                                                  className="switch--style"
                                                  onChange={onChangeWgtCk.bind(
                                                    null,
                                                    'pa',
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row pdg">
                                            <div className="col pdg not-vc-label">
                                              <span className="label-per">
                                                {value.name}
                                              </span>
                                            </div>

                                            <div className="col-2 centered-vert-horiz">
                                              <SwitchBtn
                                                id={value.id}
                                                value={helpers.retrieveValue(
                                                  value.type,
                                                  widgetCkStates,
                                                  value.id,
                                                )}
                                                className="switch--style"
                                                onChange={onChangeWgtCk.bind(
                                                  null,
                                                  'pa',
                                                )}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-12 setup-display-text">
                        {showView?.pa ? (
                          <span className="lead action-expand-btn">
                            {' '}
                            <ExpandLess className="the-expand-icon01" />{' '}
                            {config.strings.hideSetup}
                          </span>
                        ) : (
                          <span className="lead action-expand-btn">
                            <ExpandMoreIcon className="the-expand-icon01" />{' '}
                            {config.strings.showSetup}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {showSaveBtn && (
                <div id="floating--div--2" className="float-btn--wrapper2">
                  <div className="float-btn--content2 save--dv">
                    <div className="container pdg mg-b-25">
                      <div className="row pdg">
                        <div className="col-10 pdg"></div>
                        <div className="col-2 pdg c-2">
                          <button
                            className={`btn small-button ok`}
                            type="button"
                            data-toggle="modal"
                            data-target="#pressure--conn-changes"
                            onClick={() => {
                              setShowSaveBtn(false);
                            }}
                          >
                            <span className="btn-label btn-child-ft">
                              <RiSave3Fill className="svg-ii" /> SAVE{' '}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>{' '}
      </div>
      <Modal
        config={config}
        id={`pressure--conn-changes`}
        title={config.titles.pc}
        icon={<RiSave3Fill className="edit" />}
        body={config.strings.confirmation}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        onClick={handleSavePreConnData}
        label={config.labels.pc}
        className={`small-button ok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config.strings.sureToProceed}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminSectionsState, adminDevicesState } = state;
  return {
    modalMustClose: adminSectionsState.modalMustClose,
    currentDev: adminSectionsState.currentDev,
    systemsCurrentStatus: adminDevicesState.systemsCurrentStatus,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminSectionsState, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    setPCConfirmation: adminSectionsState.setPCConfirmation,
    handleModalState: adminSectionsState.handleModalState,
    editPressureConn: adminSectionsState.editPressureConn,
    setCurrentDev: adminSectionsState.setCurrentDev,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PressureConnect);
