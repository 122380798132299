import React from 'react';

interface SensorNameProps {
  sensor: any;
}

const SensorName: React.FC<SensorNameProps> = ({ sensor }) => {
  return (
    <div className="sensor--id">
      <div className="card">
        <div className="card-body">
          <p className="card-title">
            {' '}
            {sensor?.sensorName?.length > 0
              ? sensor?.sensorName
              : '_ _ _ _ _ _ _ _'}
          </p>
          <p className="card-subtitle text-muted">
            {(sensor?._name && sensor?._name === 'LOCAL') ||
            (sensor?._status && sensor?._status.includes('local'))
              ? 'Local'
              : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SensorName;
