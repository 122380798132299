import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FcDataConfiguration } from 'react-icons/fc';
import { List } from 'react-components';
import { ConfigTemplate } from 'business/modules/common';
import { ActionsMenu, EmptyList } from './components';
import { ConfigurationsListProps } from './interface';
import config from './config';
import './styles.scss';


const DEFAULT_STRS: any = { ...config.strings };
const DEFAULT_ALERTS: any = { ...config.alerts };

const ConfigurationsList: React.FC<ConfigurationsListProps> = ({
  listUserConfigs,
  loading,
  deleteUserConfig,
  storeId,
}) => {
  const history = useHistory();
  const [configsList, setConfigsList]: any = useState([]);
  const [listConfig]: any = useState(config?.listConfigState);
  const [showDeleteAlert, setShowDeleteAlert]: any = useState(false);
  const [configToDelete, setConfigToDelete]: any = useState();



  const onAddConfig = () => {
    history.push(`/dashboard/configurations/add`);
  };

  const onEditConfig = (row: any, event: any) => {
    const { config_id }: any = row || {};

    // saving id globally
    storeId(config_id);

    history.push(`/dashboard/configurations/edit?id=${config_id}`);
  };

  const onDeleteConfig = (row: any, event: any) => {
    setConfigToDelete(row);
    setShowDeleteAlert(true);
    event();
  };

  const onDeleteConfirmed = async () => {
    setShowDeleteAlert(false);
    const { config_id }: any = configToDelete || {};

    if (config_id) {
      loading.start(config?.strings?.loadingDelete);
      setConfigToDelete();

      let delResponse = await deleteUserConfig({ id: config_id });

      if (Object.keys(delResponse)) {
        let currentUpdList = await listUserConfigs();

        setConfigsList(currentUpdList);
        loading.stop();
      }
    }
  };

  const handleLoadConfigs = async () => {
    loading.start(config?.strings?.loadingHandleLoad);
    let response: any = await listUserConfigs();

    if (response) {
      setConfigsList(response);
      loading.stop(); //so loading stops as soon as data is loaded
    }
  };

  useEffect(() => {
    handleLoadConfigs();
  }, []);




  return (
    <ConfigTemplate id="user--config--list" title={DEFAULT_STRS.title}>
      <div className="user-clist-wrapper">
        <div className="user-clist--btn-container">
          <div className="use-btn-sub-container">
            <button
              type="button"
              className="small-btn btn-rounded"
              onClick={onAddConfig}
            >
              {config?.strings?.btnLabel}
            </button>
          </div>
        </div>

        <div className="user-clist--list-container">
          {
            configsList && configsList.length > 0 ? (
              <List
                name="configs-list"
                items={configsList}
                onRowClick={onEditConfig}
                config={listConfig}
                status={false}
                rowType="device"
                RowIcon={FcDataConfiguration}
                HeaderComponent={(_props: any) => {
                  return <></>;
                }}
                Component={(props: any) => {
                  return (
                    <ActionsMenu
                      {...props}
                      onDelete={onDeleteConfig}
                      onEdit={onEditConfig}
                    />
                  );
                }}
              ></List>
            ) : (
              <EmptyList
                title={config?.strings?.emptyListTitle}
              />
            )
          }
        </div>
      </div>
      <SweetAlert
        danger
        show={showDeleteAlert}
        showCancel
        confirmBtnText={DEFAULT_ALERTS?.delete?.btn?.confirm}
        confirmBtnBsStyle="danger"
        title={`${DEFAULT_ALERTS?.delete?.question} "${configToDelete?.name}"?`}
        onConfirm={onDeleteConfirmed}
        onCancel={() => setShowDeleteAlert(false)}
        focusCancelBtn
      >
        {DEFAULT_ALERTS?.delete?.msm}
      </SweetAlert>
    </ConfigTemplate>
  );
};

function mapStateToProps(state: any) {
  const { userConfigs } = state;
  return {};
}

function mapDispatchToProps(dispatch: any) {
  const { userConfigs, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    listUserConfigs: userConfigs.listUserConfigs,
    storeId: userConfigs.storeId,
    deleteUserConfig: userConfigs.deleteUserConfig,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsList);
