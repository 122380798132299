import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import _ from 'lodash';
import {
  Dashboard as Home,
  DeviceSettings,
  DeleteAccount,
} from './pages';
import DataLoggingRoutes from '../dataLogging';
import MyProfile from '../profile';
import Systems from '../systems';
import Devices from '../devices';
import Foobot from '../foobot';
import AirThings from '../airthings';
import Awair from '../awair';
import ConfigurationsRouter from '../configurations';
import { useAuth } from '../sessions/hooks/auth';




const base_path = '/dashboard';
const TOKEN_LABEL: string = '@aircyler:token';
let CURRENT_INTERVAL: any = null;
let USER_ACTIVE: any = false;
const ACTIVITY_EVENTS: any = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
const INACTIVITY_THRESHOLD_SECONDS: number = (60 + 30);
const CHECK_INTERVAL_MS: number = (1000 * 45);
let USER_LAST_ACTIVE_TIME: any = new Date();
let USER_LAST_ACTIVE_SECS: any = USER_LAST_ACTIVE_TIME.getTime();





interface DashboardProps {
  userAccount: any;
  isUserInit: any;
  systems: any;
  setUserInit: Function;
  loadFoobotUserData: Function;
  loadAirThingsUserData: Function;
  loadAwairState: Function;
  updateUserActivity: Function;
  getSystems: Function;
  getSystemStatus: Function;
}

const Dashboard: React.FC<DashboardProps> = ({ ...props }) => {
  const { token, user, accountType } = useAuth();





  const logActivity = (message: any) => {
    console.log('\n', message, '\n');
  };


  const _updateUserActivity = () => {
    USER_ACTIVE = true;
    USER_LAST_ACTIVE_TIME = new Date();
    USER_LAST_ACTIVE_SECS = USER_LAST_ACTIVE_TIME.getTime();

    // logActivity(`User is active, \n TIME: ${USER_LAST_ACTIVE_SECS}`);
  };


  const checkUserActivity = () => {
    const current_time: any = new Date();
    const time_difference: number = (current_time - USER_LAST_ACTIVE_TIME) / 1000;

    if (time_difference > INACTIVITY_THRESHOLD_SECONDS) {
      USER_ACTIVE = false;

      logActivity(`- - -  User is inactive  -  TIME:  ${USER_LAST_ACTIVE_SECS}`);
    } else {
      USER_ACTIVE = true;

      handleUpdateSystemsActivityFlag(
        USER_LAST_ACTIVE_SECS / 1000,
      );
      logActivity(` - -  User is active  - -   [UPDATE]   TIME:  ${USER_LAST_ACTIVE_SECS}`);
    }
  };


  const handleUpdateSystemsActivityFlag = async (actFlag: any) => {
    let systemsClone: any = props?.systems?.length > 0 ? _.cloneDeep(props.systems) : await props.getSystems();

    if(systemsClone?.length > 0){
      for (let index = 0; index < systemsClone.length; index++) {
        const { device_name, device_id, details } = _.cloneDeep(systemsClone[index]);

        if(
          details?.settings?.fmwv &&
          parseFloat(details?.settings?.fmwv) >= 0.8
        ){
          console.log(
            '\n\n\n  ------------------    SYSTEM FIRMWARE   ----------------------  \n  ',
            device_name,
            '\n',
            details?.settings?.fmwv,
            '\n\n',
          );
          const isStopedRes: any = await props.getSystemStatus({
            system_id: device_id,
          });

          if(!isStopedRes?.stoped){
            await props.updateUserActivity({
              device_id,
              lfdt: parseInt(actFlag.toString()),
            });
          }
        }

      }
    }else{
      console.log(
        '\n\n    SYSTEMS NOT FOUND . . .  \n\n',
        systemsClone?.length,
        '\n\n',
      );
    }
  };


  const handleIntervals = () => {
    if(CURRENT_INTERVAL){
      clearInterval(CURRENT_INTERVAL);
      CURRENT_INTERVAL = null;
    }
  };










  useEffect(() => {
    if (props?.userAccount && !props?.isUserInit) {
      props.setUserInit();
      props.loadAirThingsUserData(props.userAccount);
      props.loadAwairState(props.userAccount);
    }
  }, [props?.userAccount]);


  useEffect(() => {
    props.loadFoobotUserData();


    ACTIVITY_EVENTS.forEach((event: any) => {
      window.addEventListener(event, _updateUserActivity, true);
    });
    window.addEventListener('beforeunload', handleIntervals);

    if(!CURRENT_INTERVAL){
      handleUpdateSystemsActivityFlag(
        (new Date()).getTime() / 1000
      );
      CURRENT_INTERVAL = setInterval(
        checkUserActivity, CHECK_INTERVAL_MS
      );
    }


    return () => {
      window.removeEventListener('beforeunload', handleIntervals);

      ACTIVITY_EVENTS.forEach((event: any) => {
        window.removeEventListener(event, _updateUserActivity, true);
      });
    };
  }, []);


  useEffect(() => {

    return () => {
      setTimeout(() => {
        const _token: any = localStorage.getItem(TOKEN_LABEL);

        if(_token === null || _token === undefined){
          if(CURRENT_INTERVAL){
            clearInterval(CURRENT_INTERVAL);
            CURRENT_INTERVAL = null;
          }
        }
      }, 2000);
    };
  }, []);






  return (
    <>
      <BreadcrumbsItem to={base_path}>Home</BreadcrumbsItem>
      <Switch>
        <Route exact path={base_path} component={Home} />
        <Route
          path={`${base_path}/system-settings/:id`}
          component={DeviceSettings}
        />
        <Route path={`${base_path}/systems`} component={Systems} />
        <Route path={`${base_path}/profile`} component={MyProfile} />
        <Route path={`${base_path}/devices`} component={Devices} />
        <Route path={`${base_path}/foobot`} component={Foobot} />
        <Route path={`${base_path}/airthings`} component={AirThings} />
        <Route path={`${base_path}/awair`} component={Awair} />
        <Route
          path={`${base_path}/data-logging`}
          component={DataLoggingRoutes}
        />
        <Route
          path={`${base_path}/configurations`}
          component={ConfigurationsRouter}
        />
        <Route
          path={`${base_path}/delete-account`}
          component={DeleteAccount}
        />
      </Switch>
    </>
  );
};

function mapStateToProps(state: any) {
  const { profile, env, devices } = state || {};

  return {
    isUserInit: env.isUserInit,
    userAccount: profile.user,
    systems: devices.systems,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    foobot, airthings, env,
    awair, devices
  } = dispatch || {};

  return {
    loadFoobotUserData: foobot.setFoobotUserData,
    loadAirThingsUserData: airthings.loadAirthingsState,
    setUserInit: env.setUserInit,
    loadAwairState: awair.loadAwairState,
    getSystems: devices.getSystems,
    updateUserActivity: devices.updateUserActivity,
    getSystemStatus: devices.getSystemStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
