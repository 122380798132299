export default {
  strings: {
    'user-details': `Back to accounts list`,
    'details-dev1': `Back to Systems information`,
    'user-details-adm': `Back to users list`,
    'companies-installers-list': 'Back to Companies list',
    'installers-ed': 'Back to Installers list',
    'installers-add': 'Back to Installers list',
    'installer-devices-list': 'Back to Installers list',
    'installers-devs--add': 'Back to devices list',
    'installers-devs': 'Back to devices list',
    'dashboard-create-company': 'Back to companies list',
    'dashboard-edit-company': 'Back to companies list',
    'update-location': 'Back to user details',
    'device-commands': 'Back to user details',
    'setup-edit-peripheral': 'Back to details',
    setup: 'Back to details',
    'setup-logs-list': 'Back to user details',
    'dashboard-create': 'Back to admin users list',
    'create-dev-user': "Back to clients' list",
    'edit-dev-user': "Back to clients' list",
    'search-results-list': 'Back to users list',
    'extract-users-emails': 'Back to users list',
  },
};
