import React, { Fragment, useEffect, useState } from 'react';
import { labels } from './config';
import { ObjType, ContentProps } from './interface';
import { helpers } from './helpers';
import { Data } from 'business/modules/admin/common';
import { DevDetails } from './components';
import './styles.scss';

const Content: React.FC<ContentProps> = ({
  id,
  config,
  resultType,
  orderedCurrResult,
}) => {
  const detailsReleventProps: string[] = ['name', 'rssi', 'settings'];

  const handleInitials = (resultType: string, orderedCurrResult: any) => {
    let name;

    if (
      resultType === 'device' &&
      orderedCurrResult &&
      orderedCurrResult?.hasOwnProperty('device_name') &&
      typeof orderedCurrResult?.device_name === 'string' &&
      orderedCurrResult?.device_name?.trim().length > 0
    ) {
      name = orderedCurrResult?.device_name.split(' ');
    } else if (
      (resultType === 'sensor' || resultType === 'company') &&
      orderedCurrResult &&
      orderedCurrResult?.hasOwnProperty('name') &&
      typeof orderedCurrResult?.name === 'string' &&
      orderedCurrResult?.name?.trim().length > 0
    ) {
      name = orderedCurrResult?.name.split(' ');
    }

    if (name && name.length === 1) {
      return <>{name[0][0].toUpperCase()}</>;
    } else if (name && name.length > 1) {
      // (2) ['Kitchen', ''] ,['Jacob', 'office']

      return (
        <>
          {name && name[0]?.trim().length > 0 ? name[0][0].toUpperCase() : ''}
          {name && name[1]?.trim().length > 0 ? name[1][0].toUpperCase() : ''}
        </>
      );
    }

    return '';
  };

  const filterObject = (ob: any, relevantProps: string[]) => {
    return Object.keys(ob)
      .filter(key => relevantProps.includes(key))
      .reduce((obj: ObjType, key: string) => {
        obj[key] = ob[key];
        return obj;
      }, {});
  };

  const handleImage = (item: any) => {
    if (item?.picture) {
      return item?.picture;
    }
    return '/avatar.png';
  };

  const renderValAccordingToType = (value: any) => {
    return !value ? (
      helpers.handleNoValues()
    ) : helpers.valIsBool(value) ? (
      <span className="lead span-key-040"> {value.toString()}</span>
    ) : helpers.valueIsStrOrNum(value) ? (
      <span className="lead span-key-040">{value}</span>
    ) : helpers.isOb(value) ? (
      handleObject(value)
    ) : Array.isArray(value) ? (
      <></>
    ) : (
      <span className="lead span-key-040">{JSON.stringify(value)}</span>
    );
  };

  const handleArray = (value: any[]) => {
    if (value && value.length === 0) {
      return helpers.handleNoValues();
    } else {
      return (
        <div className="container pdg">
          {value &&
            value.map((item: any, idx: number) => {
              return (
                <div className="row" key={idx}>
                  {helpers.isOb(item) ? (
                    Object.entries(item).map(
                      ([k, v]: [string, any], i: number) => {
                        return (
                          <Data
                            key={i}
                            cnt="container pdg"
                            row="row mlmr0  item-padding"
                            col1="col-12 pdg  item-padding"
                            col2="col pdg  item-padding"
                            firstContent={
                              <span className="inline-label lead lead-sm label-result-sp bolder">
                                {labels[k]}
                              </span>
                            }
                            secondContent={
                              helpers.valIsBool(v) ? (
                                <span className="lead span-key-040">
                                  {v.toString()}
                                </span>
                              ) : helpers.valueIsStrOrNum(v) ? (
                                <span className="lead span-key-040">{v}</span>
                              ) : helpers.isOb(v) ? (
                                Object.entries(v).map(
                                  ([_k, _v]: [string, any], i: number) => {
                                    return (
                                      <div className="container pdg " key={i}>
                                        <div className="row mlmr0  item-padding">
                                          {helpers.renderLargerLabel(
                                            _k,
                                            labels[_k],
                                          )}

                                          <div className="col-12 pdg  item-padding">
                                            {renderValAccordingToType(_v)}
                                          </div>
                                        </div>
                                        <div className="row-sep"></div>
                                      </div>
                                    );
                                  },
                                )
                              ) : Array.isArray(v) ? (
                                <></>
                              ) : (
                                <span className="lead span-key-040">
                                  {JSON.stringify(v)}
                                </span>
                              )
                            }
                            optionalEl={
                              k === 'latest' ? (
                                <div className="row-sep"></div>
                              ) : null
                            }
                          />
                        );
                      },
                    )
                  ) : (
                    <span className="lead span-key-040">
                      {JSON.stringify(item)}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      );
    }
  };

  const handleObject = (val: any) => {
    return Object.entries(val).map(
      ([key, value]: [string, any], idx: number) => {
        return (
          <Data
            key={idx}
            cnt={`container pdg `}
            row={`row mlmr0  item-padding`}
            col1={`col-6 pdg  item-padding`}
            col2={`col pdg  item-padding`}
            firstContent={
              <span className="inline-label lead lead-sm label-result-sp bolder">
                {labels[key]}
              </span>
            }
            secondContent={
              helpers.valueIsStrOrNum(value) ? (
                <span className="lead span-key-040">{value}</span>
              ) : helpers.valIsBool(value) ? (
                <span className="lead span-key-040"> {value.toString()}</span>
              ) : helpers.isOb(val) ? (
                <span className="lead span-key-040">
                  {JSON.stringify(value)}
                </span>
              ) : (
                <span className="lead span-key-040">
                  {JSON.stringify(value)}
                </span>
              )
            }
          />
        );
      },
    );
  };

  const handleElement = (key: string, value: any) => {
    if (
      !value ||
      (Array.isArray(value) && value.length === 0) ||
      (helpers.isOb(value) && Object.keys(value).length === 0)
    ) {
      return helpers.handleNoValues(key);
    }
    if (key === 'picture') return null;
    if (helpers.valIsBool(value))
      return (
        <div className="form-control input-alike">
          <span className="lead span-key-040">{value.toString()}</span>
        </div>
      );
    if (helpers.valueIsStrOrNum(value)) {
      return (
        <div className="form-control input-alike">
          <span className="lead span-key-040">{value}</span>
        </div>
      );
    }

    //health requires a specific layout because it has a lot of nested objects
    // whose props take too much space
    if (key === 'health') {
      return (
        <div className="pdg">
          {value &&
            helpers.isOb(value) &&
            Object.entries(value).map(([k, v]: [any, any], i: number) => {
              return (
                <div className="container pdg " key={i}>
                  <div className="row mlmr0  item-padding">
                    {helpers.renderLargerLabel(k, labels[k])}

                    <span className="lead lead--smaller ftw600 mt025">
                      {labels[k]}
                    </span>
                  </div>

                  <div className="row mlmr0 item-padding">
                    <div className="col-12 pdg item-padding">
                      {!v ? (
                        helpers.handleNoValues()
                      ) : v && helpers.valueIsStrOrNum(v) ? (
                        <span className="lead span-key-040">{v}</span>
                      ) : v && helpers.valIsBool(v) ? (
                        <span className="lead span-key-040">
                          {v.toString()}
                        </span>
                      ) : v && Array.isArray(v) ? (
                        <> </>
                      ) : (
                        v &&
                        helpers.isOb(v) &&
                        Object.entries(v).map(
                          ([key, value]: [string, any], index: number) => {
                            return (
                              <div className="container pdg" key={index}>
                                <div className="row  item-padding">
                                  {(value && helpers.valueIsStrOrNum(value)) ||
                                  (value && helpers.valIsBool(value)) ? (
                                    <Fragment>
                                      <div className="col-6 pdg  item-padding">
                                        <span className="inline-label lead lead-sm label-result-sp bolder">
                                          {labels[key]}
                                        </span>
                                      </div>

                                      <div className="col pdg  item-padding">
                                        <span className="lead span-key-040">
                                          {value
                                            ? value.toString()
                                            : 'No value'}
                                        </span>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {key === 'firmwareVersions' ? (
                                        helpers.renderLargerLabel(
                                          key,
                                          labels[key],
                                        )
                                      ) : (
                                        <div className="col-12 pdg inline-label lead lead-sm label-result-sp aliceblue-label">
                                          <span className="inline-label lead lead-sm label-result-sp bolder">
                                            {labels[key]}
                                          </span>
                                        </div>
                                      )}

                                      <div className="col-12 pdg  item-padding">
                                        {value &&
                                        Array.isArray(value) &&
                                        value?.length === 0 ? (
                                          helpers.handleNoValues()
                                        ) : value &&
                                          helpers.valueIsStrOrNum(value) ? (
                                          <span className="lead span-key-040">
                                            {value}
                                          </span>
                                        ) : value &&
                                          helpers.valIsBool(value) ? (
                                          <span className="lead span-key-040">
                                            {value.toString()}
                                          </span>
                                        ) : value &&
                                          Array.isArray(value) &&
                                          value?.length > 0 ? (
                                          <span className="lead span-key-040">
                                            {handleArray(value)}
                                          </span>
                                        ) : (
                                          value &&
                                          Object.entries(value).map(
                                            (
                                              [item, val]: [string, any],
                                              i: number,
                                            ) => {
                                              return (
                                                <Data
                                                  key={i}
                                                  cnt={`container pdg `}
                                                  row={`row mlmr0 item-padding`}
                                                  col1={`col-6 pdg item-padding`}
                                                  col2={`col pdg item-padding`}
                                                  firstContent={
                                                    <span className="inline-label lead lead-sm label-result-sp bolder">
                                                      {labels[item]}
                                                    </span>
                                                  }
                                                  secondContent={
                                                    helpers.valueIsStrOrNum(
                                                      val,
                                                    ) ? (
                                                      <span className="lead span-key-040">
                                                        {val}
                                                      </span>
                                                    ) : helpers.valIsBool(
                                                        val,
                                                      ) ? (
                                                      <span className="lead span-key-040">
                                                        {val.toString()}
                                                      </span>
                                                    ) : helpers.isOb(val) ? (
                                                      <span className="lead span-key-040">
                                                        <></>
                                                      </span>
                                                    ) : (
                                                      <span className="lead span-key-040">
                                                        {JSON.stringify(val)}
                                                      </span>
                                                    )
                                                  }
                                                />
                                              );
                                            },
                                          )
                                        )}
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            );
                          },
                        )
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }

    if (Array.isArray(value)) {
      return (
        value &&
        value.map((item: any, index: number) => {
          return Object.entries(item).map(
            ([k, v]: [string, any], i: number) => {
              return (
                <Data
                  key={i}
                  cnt={`container pdg `}
                  row={`row`}
                  col1={`col${k === 'data' ? '-12' : '-6'} pdg  item-padding`}
                  col2={`col pdg  item-padding`}
                  firstContent={
                    k === 'data' ? (
                      helpers.renderLargerLabel(k, labels[k])
                    ) : (
                      <span className="inline-label lead lead-sm label-result-sp bolder">
                        {labels[k]}
                      </span>
                    )
                  }
                  secondContent={
                    v && helpers.valueIsStrOrNum(v) ? (
                      <span className="lead span-key-040">{v}</span>
                    ) : v && Array.isArray(v) ? (
                      v.map((elem: any, i: number) => {
                        return (
                          helpers.isOb(elem) &&
                          Object.entries(elem).map(
                            ([key, value]: [string, any], i: number) => {
                              return (
                                <Data
                                  key={i}
                                  cnt={`container pdg  `}
                                  row={`row mlmr0  item-padding`}
                                  col1={`col pdg  item-padding`}
                                  col2={`col pdg  item-padding`}
                                  firstContent={
                                    <span className="inline-label lead lead-sm label-result-sp bolder">
                                      {labels[key]}
                                    </span>
                                  }
                                  secondContent={
                                    <span className="lead span-key-040">
                                      {helpers.valueIsStrOrNum(value)
                                        ? value
                                        : JSON.stringify(value)}
                                    </span>
                                  }
                                  optionalEl={
                                    key === 'value' && (
                                      <div className="row-sep"></div>
                                    )
                                  }
                                />
                              );
                            },
                          )
                        );
                      })
                    ) : v && helpers.isOb(v) ? (
                      <> </>
                    ) : (
                      <span className="lead span-key-040">
                        {value.toString()}
                      </span>
                    )
                  }
                />
              );
            },
          );
        })
      );
    }
    if (value && helpers.isOb(value)) {
      return (
        <div className="pdg">
          {Object.entries(value).map(([_k, _v]: [string, any], idx: number) => {
            return (
              <Data
                key={idx}
                cnt={`container pdg  `}
                row={`row mlmr0`}
                col1={`col${
                  _k === 'start' || _k === 'end' ? '-12' : '-6'
                } pdg  item-padding`}
                col2={`col${helpers.getCol(_k)} pdg  item-padding`}
                firstContent={
                  <span className="inline-label lead lead-sm label-result-sp item-padding bolder">
                    {labels[_k]}
                  </span>
                }
                secondContent={
                  _v && Array.isArray(_v) && _v.length > 0 ? (
                    <div className="container pdg ">
                      {_v.map((elem: any, i: number) => {
                        return helpers.valueIsStrOrNum(elem) ? (
                          <span
                            className="lead span-key-040 item-padding pdg"
                            key={i}
                          >
                            {elem}
                          </span>
                        ) : Array.isArray(elem) ? (
                          elem.map((item: any, index: number) => {
                            return (
                              <Fragment key={index}>
                                {item && helpers.valueIsStrOrNum(item) ? (
                                  <span className="lead span-key-040 item-padding pdg">
                                    {item}
                                  </span>
                                ) : !item ? (
                                  <span className="lead span-key-040 item-padding pdg">
                                    No values
                                  </span>
                                ) : (
                                  <span className="lead span-key-040 item-padding pdg">
                                    {JSON.stringify(item)}
                                  </span>
                                )}
                              </Fragment>
                            );
                          })
                        ) : (
                          <div className="row mlmr0  item-padding" key={i}>
                            <span className="lead span-key-040 item-padding pdg">
                              {JSON.stringify(elem)}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  ) : _v && Array.isArray(_v) && _v.length === 0 ? (
                    <span className="lead span-key-040 item-padding pdg">
                      No values to display
                    </span>
                  ) : _v && helpers.valueIsStrOrNum(_v) ? (
                    <span className="lead span-key-040 item-padding pdg">
                      {_v}
                    </span>
                  ) : (
                    <div className="container pdg ">
                      {_v && helpers.isOb(_v) ? (
                        Object.entries(_v).map(
                          ([key, val]: [any, any], i: number) => {
                            return (
                              <div className="container pdg" key={i}>
                                <div className="row item-padding">
                                  <div className="col pdg item-padding">
                                    <span className="inline-label lead lead-sm label-result-sp bolder">
                                      {labels[key]}
                                    </span>
                                  </div>
                                  <div className="col pdg item-padding">
                                    {val && helpers.valueIsStrOrNum(val) ? (
                                      <span className="lead span-key-040">
                                        {val}
                                      </span>
                                    ) : val && helpers.isOb(val) ? (
                                      <></>
                                    ) : val && Array.isArray(val) ? (
                                      <></>
                                    ) : (
                                      <span className="lead span-key-040">
                                        {JSON.stringify(val)}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        )
                      ) : (
                        <>
                          {value && helpers.valueIsStrOrNum(value) ? (
                            value
                          ) : value && Array.isArray(value) ? (
                            <> </>
                          ) : value && helpers.isOb(value) ? (
                            <div className="container pdg">
                              <div className="row mlmr0  item-padding">
                                {value &&
                                  Object.entries(value).length > 0 &&
                                  Object.entries(value).map(
                                    ([key, val]: [string, any], i: number) => {
                                      return (
                                        <Fragment key={i}>
                                          <div
                                            className={`col${helpers.getCol(
                                              key,
                                            )} pdg item-padding`}
                                          >
                                            <span className="inline-label lead lead-sm label-result-sp bolder">
                                              {labels[key]}
                                            </span>
                                          </div>
                                          <div className="col pdg item-padding">
                                            <span className="lead span-key-040">
                                              {val && helpers.valIsBool(val) ? (
                                                val.toString()
                                              ) : val &&
                                                helpers.valueIsStrOrNum(val) ? (
                                                val
                                              ) : !val ? (
                                                <>No values</>
                                              ) : val &&
                                                Array.isArray(val) &&
                                                val.length > 0 &&
                                                !Array.isArray(val[0]) ? (
                                                <div>
                                                  {val?.map(
                                                    (elem: any, i: number) => {
                                                      return (
                                                        <Fragment key={i}>
                                                          {elem &&
                                                          helpers.valIsBool(
                                                            elem,
                                                          ) ? (
                                                            <span className="lead span-key-040 item-padding pdg">
                                                              {elem.toString()}
                                                            </span>
                                                          ) : elem &&
                                                            helpers.valueIsStrOrNum(
                                                              elem,
                                                            ) ? (
                                                            <span className="lead span-key-040 item-padding pdg">
                                                              {elem}
                                                            </span>
                                                          ) : !elem ? (
                                                            <span className="lead span-key-040 item-padding pdg">
                                                              No values
                                                            </span>
                                                          ) : null}
                                                        </Fragment>
                                                      );
                                                    },
                                                  )}
                                                </div>
                                              ) : val &&
                                                Array.isArray(val) &&
                                                key === 'datapoints' ? (
                                                val.map(
                                                  (item: any, i: number) => (
                                                    <div
                                                      className="container pdg"
                                                      key={i}
                                                    >
                                                      <div className="row mlmr0  item-padding">
                                                        <span
                                                          className="lead span-key-040 item-padding pdg"
                                                          key={i}
                                                        >
                                                          {item},
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ),
                                                )
                                              ) : helpers.isOb(val) ? (
                                                <> </>
                                              ) : null}
                                            </span>
                                          </div>
                                        </Fragment>
                                      );
                                    },
                                  )}
                              </div>
                            </div>
                          ) : (
                            JSON.stringify(value)
                          )}
                        </>
                      )}
                    </div>
                  )
                }
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className="card" id={id}>
      {resultType === 'user' ? (
        <img
          className="card-img-top result-img mrg-btm0"
          src={handleImage(orderedCurrResult)}
          alt="Card image cap"
          onError={helpers.handleImgErr}
        />
      ) : (
        <div className="card-img-top centered-item-a">
          <div className="result-view-avt">
            {handleInitials(resultType, orderedCurrResult)}
          </div>
        </div>
      )}

      <div className="card-body spec-card-body--1">
        <h5 className="card-title">{config.modal.view.sectionTitle}</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          {config?.modal?.view?.sectionSubtitle}
        </h6>
        <div className="card-text">
          <div className="div-informations results-scrollable">
            <ul className="list-group" id="view-result-detail">
              {orderedCurrResult &&
                helpers.isOb(orderedCurrResult) &&
                Object.keys(orderedCurrResult).length > 0 &&
                Object.entries<any>(orderedCurrResult).map(
                  ([key, value], idx) => {
                    return (
                      <div
                        className={`container pdg break-w ${`class-${key}`}`}
                        key={idx}
                      >
                        <div
                          className={`row pdg contents  ${
                            helpers.specSection(key)
                              ? 'extra-mb lim-height'
                              : ''
                          }`}
                        >
                          {key === 'details' ? (
                            <DevDetails
                              value={filterObject(value, detailsReleventProps)}
                              labels={labels}
                              helpers={helpers}
                            />
                          ) : (
                            <>
                              {key === 'picture' ? null : helpers.specSection(
                                  key,
                                ) ? (
                                <div className="lead lead--smaller ftw600 pdg spec-label">
                                  {labels[key]}
                                </div>
                              ) : (
                                <>
                                  {key === 'device_city' ||
                                  key === 'device_state' ||
                                  key === 'device_zipcode' ? (
                                    <div className="lead lead--smaller ftw600 location-label">
                                      {labels[key]}
                                    </div>
                                  ) : (
                                    <span className="lead lead--smaller ftw600 pdg">
                                      {labels[key]}
                                    </span>
                                  )}
                                </>
                              )}
                              {handleElement(key, value)}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
