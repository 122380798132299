//@ts-nocheck
import _ from 'lodash';
import config from './config';

const relevantProps = ['cdvs', 'fcst', 'dmst', 'cfst', 'cfcm'];
const DEFAULT_EXTREMS = { ...config.sliderValues };
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };

const isDefined = (data: any) => {
  if (data !== null && data !== undefined) {
    return true;
  } else return false;
};

const getFuncByType = (type: any, value: any) => {
  switch (type) {
    case 'airthings':
      return {
        func: 'requestAirthingsData',
        payload: { id: value },
      };
    case 'awair':
    case 'awair-element':
    case 'awair-omni':
      return {
        func: 'requestAwairData',
        payload: { id: value },
      };
    case 'foobot':
    default:
      return {
        func: 'requestData',
        payload: { device_id: value },
      };
  }
};

const filterParams = (type: any, data: any) => {
  switch (type) {
    case 'airthings':
    case 'awair':
    case 'awair-element':
    case 'awair-omni':
      const { datapoint } = data;
      return { ...datapoint };
    case 'foobot':
    default:
      return { ...data };
  }
};

const respOK = (response: any) => {
  if (response?.statusCode === 200) {
    return true;
  } else {
    return false;
  }
};

const normalizeMaxValues = (
  prop: string,
  maxValue: any,
  currentValues: any,
) => {
  let normalizedValue: any = maxValue;
  const { current, init }: any = currentValues || {};
  let gainA: number = 100;

  switch (prop) {
    case 'co2':
    case 'tVoc':
      gainA = 200;
      break;
    case 'pm25':
    default:
      break;
  }

  if (init && current && current > maxValue) {
    const factor: number = parseInt(current).toString().length;
    let gain: number = Math.pow(10, factor - 1);
    normalizedValue = current + 100;
    if (gain > 0) normalizedValue = parseInt(normalizedValue / gain) * gain;
  }

  return normalizedValue;
};

const transformSingleSlider = (values: any, id: string) => {
  const { max, min, current } = values;

  return [current, max === -101 ? SLIDERS_DEFAULTS[id] : max];
};

const returnSensor = (sensorsList: any, sensorName: any) => {
  let s: any = sensorsList.filter(
    (sensor: any) => sensor.device_name === sensorName,
  );

  return s;
};

const getSensorDataForDevice = (
  deviceId: string,
  indoorAirSettingsArr: any,
  indoorAirSettingsAreLoaded: boolean,
) => {
  let sensorObjectData: any = {};
  let sensorName: string = '';

  if (
    indoorAirSettingsArr &&
    indoorAirSettingsArr.length > 0 &&
    indoorAirSettingsAreLoaded
  ) {
    let sensorValues;

    let deviceData = indoorAirSettingsArr.filter(
      (dev: any) => dev?.body?.data?.device_id === deviceId,
    );

    if (deviceData && deviceData.length > 0) {
      sensorValues = deviceData[0].body?.data?.settings || {};
    }

    if (sensorValues && Object.values(sensorValues).length === 0) {
      sensorName = 'MachineShop';
    } else if (sensorValues) {
      sensorObjectData = Object.values(sensorValues)[0];

      if (!sensorObjectData.hasOwnProperty('add_sensor_id')) {
        sensorName = sensorObjectData['add_sensor'];
      }
      if (sensorObjectData.hasOwnProperty('add_sensor_id')) {
        if (sensorObjectData['add_sensor'] === '') {
          sensorName = 'MachineShop';
        } else {
          sensorName = sensorObjectData['add_sensor'];
        }
      }
    }
  }

  return sensorName;
};

const convertToStateProp = (id: string) => {
  switch (id) {
    case 'cfst':
      return 'cfcm';
    case 'fcst':
      return 'fanConnect';
    case 'dmst':
      return 'dmst';
    default:
      return '';
  }
};

const allowedSensor = (devType: any) => {
  switch (devType) {
    case 'awair':
    case 'awair-element':
    case 'awair-omni':
    case 'airthings':
    case 'foobot':
    case 'aircycler-local':
      return true;
    default:
      return false;
  }
};

const extractPeripherals = (_details: any) => {
  const { conf } = _details?.settings || {};
  const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];

  return {
    ventConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerVC'),
    ),
    fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
    damper: true,
    pressureConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerPC'),
    ),
    freshConnect: cdvsClone.filter(
      (item: any) =>
        item.cdid.startsWith('AirCyclerFH') ||
        item.cdid.startsWith('AirCyclerFT'),
    ),
    centralFan: true,
  };
};

const prepareCkboxStates = (connectedDevs: any) => {
  let tempStates: any = {};
  if (Object.values(connectedDevs).length > 0) {
    Object.keys(connectedDevs).forEach((key: string) => {
      if (key === 'fanConnect') {
        tempStates.fanConnect = false;
      } else if (key === 'damper') {
        tempStates.dmst = false;
      } else if (key === 'centralFan') {
        tempStates.cfcm = false;
      } else {
        connectedDevs[key].forEach((cDev: any) => {
          const { cdid } = cDev;
          if (!tempStates[key]) tempStates[key] = {};
          tempStates[key][cdid] = false;
        });
      }
    });
  }
  return tempStates;
};

const prepareCkboxStatesBySection = (connectedDevs: any) => {
  const defaultSections = Object.keys(DEFAULT_EXTREMS).map(
    (section: string) => section,
  );
  let cksDefaults: any = {};
  defaultSections.forEach((prop: string) => {
    cksDefaults[prop] = prepareCkboxStates(connectedDevs);
  });

  return cksDefaults;
};

const handleDevNamesSpecialCases = (deviceName: string | boolean) => {
  switch (deviceName) {
    case !deviceName:
      return '';
    case 'office':
    case 'office Foobot':
      return 'office Foobot';
    default:
      return deviceName || '';
  }
};

const dataIsReady = (
  numberOfDevs: any,
  indoorAirCounter: any,
  numberOfDevsRegistered: any,
) => {
  if (numberOfDevs === indoorAirCounter && numberOfDevsRegistered) {
    return true;
  }
};

const filterObject = (settings: any) => {
  return Object.keys(settings.details.settings.conf)
    .filter(key => relevantProps.includes(key))
    .reduce((obj, key) => {
      obj[key] = settings.details.settings.conf[key];

      return obj; // will return an obj with these props: ['cdvs', 'fcst', 'dmst', 'cfst', 'cfcm']
    }, {});
};

const mapAirthingsDatapointProps = (prop: string) => {
  switch (prop) {
    case 'pm25':
      return 'pm';
    case 'tVoc':
      return 'voc';
    default:
      return prop;
  }
};

const settingsExist = (statusCode: number, viewSettings: any, device: any) => {
  if (
    statusCode === 200 &&
    viewSettings[device?.device_id] &&
    viewSettings[device?.device_id]?.body &&
    viewSettings[device?.device_id]?.body?.data &&
    viewSettings[device?.device_id]?.body?.data?.settings &&
    Object.keys(viewSettings[device?.device_id]?.body?.data?.settings).length >
      0
  ) {
    return true;
  } else {
    return false;
  }
};

const sensorAndSettings = (
  statusCode: number,
  tempSensor: any,
  viewSettings: any,
  device: any,
) => {
  if (
    statusCode === 200 &&
    tempSensor &&
    viewSettings[device?.device_id] &&
    viewSettings[device?.device_id]?.body?.data
  ) {
    return true;
  } else return false;
};

export const helpers = {
  dataIsReady: dataIsReady,
  handleDevNamesSpecialCases: handleDevNamesSpecialCases,
  prepareCkboxStatesBySection: prepareCkboxStatesBySection,
  extractPeripherals: extractPeripherals,
  allowedSensor: allowedSensor,
  convertToStateProp: convertToStateProp,
  getSensorDataForDevice: getSensorDataForDevice,
  returnSensor: returnSensor,
  isDefined: isDefined,
  getFuncByType: getFuncByType,
  filterParams: filterParams,
  respOK: respOK,
  normalizeMaxValues: normalizeMaxValues,
  transformSingleSlider: transformSingleSlider,
  filterObject: filterObject,
  mapAirthingsDatapointProps: mapAirthingsDatapointProps,
  settingsExist: settingsExist,
  sensorAndSettings: sensorAndSettings,
};
