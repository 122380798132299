import _ from 'lodash';
import moment from 'moment';
// import config from '../config';

const csvConverterUtil: any = {
  downloadCsvFile: (urlData: any) => {
    const strDate: any = moment().format('MM-DD-YY');
    const fileName: string = `AirCycler-User_Emails_List_-${strDate}.csv`;
    const link = document.createElement('a');
    link.setAttribute('href', urlData);
    link.setAttribute('download', fileName);
    link.click();
  },
  formatCsvdata: (csvContent: any) => {
    const blob: any = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8,',
    });
    const objUrl: any = URL.createObjectURL(blob);

    return objUrl;
  },
  convertDataValues: (data: any) => {
    let emailsData = [];

    for (let i = 0; i < data.length; i++) {
      let item: any;
      let emails: any;

      if (Object.keys(data[i].length > 0)) {
        item = Object.values(data[i])[0];

        //checked that it also works with more than one email address in the user acc
        emails = item?.emails.map((em: any) => em.email);

        // emails = config.mocktest.map((em: any) => em.email);
        emailsData.push([item?.first_name, item?.last_name, emails]);
      }
    }

    return emailsData;
  },
  convertCsvContent: (titles: any, content: any) => {
    let csvContent: string = '';
    const allData: any = [titles].concat(content);

    allData.forEach((row: any) => {
      csvContent = csvContent + row.join(',') + '\n';
    });

    return csvContent;
  },
  exportCsv: (data: any) => {
    if (data && Array.isArray(data) && data.length > 0) {
      let titleKeys: string[] = ['First name', 'Last name', 'E-mails'];

      let keysValues: any = csvConverterUtil.convertDataValues(data);

      const csvContent: any = csvConverterUtil.convertCsvContent(
        titleKeys,
        keysValues,
      );

      const dataUrl: any = csvConverterUtil.formatCsvdata(csvContent);

      csvConverterUtil.downloadCsvFile(dataUrl);
    }
  },
};

export default csvConverterUtil;
