

export default {
  isDataURL: (str: string) => {
    if (str === null) {
      return false;
    }
    const regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+;=\-._~:@/?%\s]*\s*$/i;
    return !!str.match(regex);
  },
  loadImageURL: async (imageURL: string, crossOrigin: string) => {
    const that: any = this;

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      if (that && that.isDataURL && that.isDataURL(imageURL) === false && crossOrigin) {
        image.crossOrigin = crossOrigin;
      }
      image.src = imageURL;
    });
  },
  loadImageFile: async (imageFile: any) => {
    const that: any = this;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e: any) => {
        try {
          const image = that.loadImageURL(e.target.result);
          resolve(image);
        } catch (e) {
          reject(e);
        }
      }
      reader.readAsDataURL(imageFile)
  });
  },
};
