import React, { Fragment } from 'react';
import { helpers } from './helpers';
import { relevantProps, labels, strings } from './config';
import { cols } from '../config';
import { Card } from '..';
import { FaFan } from 'react-icons/fa';
import { MdBusinessCenter, MdOutlineSensors } from 'react-icons/md';
import { Row } from 'business/modules/admin/common';
import './styles.scss';

interface DetailSectionProps {
  resultType: string;
  item: any;
}

const DetailSection: React.FC<DetailSectionProps> = ({ item, resultType }) => {
  const isOb = (item: any) => {
    if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
      return true;
    } else return false;
  };

  const renderHealthParams = (value: any) => {
    return (
      value &&
      isOb(value) &&
      Object.entries(value).map(([key, val]: [any, any], i: number) => {
        return (
          <div className="container pdg">
            <Row
              key={i}
              outClass={`d-inline`}
              firstClass={`inline-label lead lead-sm`}
              firstContent={
                key === 'Health parameters' && <div className="row"></div>
              }
              secondClass={`lead lead-sm`}
              secondContent={
                <>
                  {value &&
                    isOb(value) &&
                    Object.entries(value).map(
                      ([k, v]: [any, any], i: number) => {
                        return (
                          <Row
                            key={i}
                            outClass={`d-inline`}
                            firstClass={`inline-label lead lead-sm`}
                            firstContent={formatKey(k, resultType)}
                            secondClass={`lead lead-sm`}
                            secondContent={handleValue(k, v)}
                          />
                        );
                      },
                    )}
                </>
              }
            />
          </div>
        );
      })
    );
  };

  const handleValue = (key: string, value: any) => {
    if (!value) return 'No value';
    if (typeof value === 'boolean') return value.toString();
    if (
      helpers.isPhone(key, value) &&
      Array.isArray(value) &&
      value?.length > 0
    ) {
      return (value[0].hasOwnProperty('phone') && value[0]?.phone) || '';
    } else if (
      helpers.isAddress(key, value) &&
      Array.isArray(value) &&
      value?.length > 0
    ) {
      if (
        value[0].hasOwnProperty('address_line_one') &&
        value[0]?.address_line_one &&
        typeof value[0]?.address_line_one === 'string' &&
        value[0]?.address_line_one.length > 0 &&
        value[0].hasOwnProperty('address_line_two') &&
        value[0]?.address_line_two &&
        typeof value[0]?.address_line_two === 'string' &&
        value[0]?.address_line_two.length > 0
      ) {
        return `${value[0]?.address_line_one.concat(
          value[0]?.address_line_two,
        )}, ${value[0]?.city}, ${value[0]?.zip_code}, ${value[0]?.state}`;
      }

      return Array.isArray(value) &&
        value.length > 0 &&
        value[0].hasOwnProperty('address_line_one')
        ? `${value[0]?.address_line_one}, ${value[0]?.city}, ${value[0]?.zip_code}, ${value[0]?.state}`
        : '';
    } else if (
      (key === 'device_state' || key === 'device_city') &&
      value &&
      value?.name
    ) {
      return value?.name || '';
    } else if (key === 'device_zipcode' && value && value?.zip) {
      return value?.zip || '';
    } else if (key === 'isSystem') {
      return value?.toString();
    } else if (key === 'name' || key === 'uuid' || key === 'mac') {
      return value;
    } else if (key === 'health') {
      return renderHealthParams(value);
    } else {
      return typeof value === 'string' || typeof value === 'number'
        ? value
        : typeof value === 'boolean'
        ? value.toString()
        : JSON.stringify(value);
    }
  };

  const formatKey = (key: string, resultType: string) => {
    return labels[resultType as keyof typeof labels][key] || '';
  };

  const renderDetails = (item: any) => {
    let ordered = helpers.orderItemProperties(item, resultType);

    let divider: number = 0;

    if (resultType === 'device') {
      divider = 7;
    } else {
      divider =
        Object.entries(ordered).length > 0 ? Object.entries(ordered).length : 3;
    }

    return (
      <Fragment>
        <div className={cols[resultType]['first']}>
          {ordered &&
            Object.entries(ordered)
              .slice(0, helpers.returnLimit(resultType, ordered))
              .map(([key, value]: [any, any], i: number) => {
                return (
                  <Row
                    key={i}
                    outClass={`d-inline`}
                    firstClass={`inline-label lead lead-sm label-result-sp`}
                    firstContent={formatKey(key, resultType)}
                    secondClass={`lead lead-sm`}
                    secondContent={handleValue(key, value)}
                  />
                );
              })}
        </div>
        <div className={cols[resultType]['second']}>
          {ordered &&
            Object.entries(ordered)
              .slice(helpers.returnLimit(resultType, ordered), divider)
              .map(([key, value]: [any, any], i: number) => {
                return (
                  <Row
                    key={i}
                    outClass={`d-inline`}
                    firstClass={`inline-label lead lead-sm label-result-sp`}
                    firstContent={formatKey(key, resultType)}
                    secondClass={`lead lead-sm`}
                    secondContent={handleValue(key, value)}
                  />
                );
              })}
        </div>
      </Fragment>
    );
  };

  const handleDisplayedData = (resultType: string) => {
    switch (resultType) {
      case 'user':
        return (
          <Card
            img={
              <img
                className="card-img-top result-img mrg-btm0"
                src={helpers.handleImage(item)}
                alt="Card image cap"
                onError={helpers.handleImgErr}
              />
            }
            handleError={helpers.handleImgErr}
            title={strings?.userTitle}
            content={renderDetails(
              helpers.handleRelevantProps(item, relevantProps.user),
            )}
          />
        );

      case 'device':
        return (
          <Card
            img={
              <div className="card-img-top result-img mrg-btm0">
                <div className="dev-avt">
                  <FaFan className="result-icon-avt" />
                </div>
              </div>
            }
            handleError={() => {}}
            title={strings?.devTitle}
            content={renderDetails(
              helpers.handleRelevantProps(item, relevantProps.device),
            )}
          />
        );

      case 'sensor':
        return (
          <Card
            img={
              <div className="card-img-top result-img mrg-btm0">
                <div className="dev-avt">
                  <MdOutlineSensors className="result-icon-avt" />
                </div>
              </div>
            }
            handleError={() => {}}
            title={strings?.sensorTitle}
            content={renderDetails(
              helpers.handleRelevantProps(item, relevantProps.sensor),
            )}
          />
        );

      case 'company':
        return (
          <Card
            img={
              <div className="card-img-top result-img mrg-btm0">
                <div className="dev-avt">
                  <MdBusinessCenter className="result-icon-avt" />
                </div>
              </div>
            }
            handleError={() => {}}
            title={strings?.companyTitle}
            content={renderDetails(
              helpers.handleRelevantProps(item, relevantProps.company),
            )}
          />
        );

      default:
        return <></>;
    }
  };
  return (
    <div className="container pdg res-detail-information">
      <div className="row">
        <div className="col-12 pdg">{handleDisplayedData(resultType)}</div>
      </div>
    </div>
  );
};

export default DetailSection;
