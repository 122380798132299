import React from 'react';
import config from './config';
import { DetailsProps } from './interface';
import { Sliders, Switches } from '..';
import { RiSave3Fill } from 'react-icons/ri';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

const SLIDER: any = { ...config.sliderValues };
const SLIDERS_DEFAULTS: any = { ...config.sliders_defaults };

const Details: React.FC<DetailsProps> = ({
  datapoints,
  helpers,
  widgetStates,
  device,
  onChangePm25,
  showView,
  extractedSettings,
  widgetCkStates,
  onChangeWgtCk,
  showMenu,
  valuesWereUpdated,
  toggleUpdateMenuVisibility,
  onChangeCO2,
  expandDetails,
  onChangeTvoc,
}) => {
  return (
    <div className="container slider-display acc-row">
      <div className="row acc-row">
        <div className="col-12 slider-col">
          <Sliders
            step={1}
            datapoints={datapoints}
            id="w-pm25"
            values={helpers.transformSingleSlider(widgetStates?.pm25, 'pm25')}
            max={helpers.normalizeMaxValues(
              'pm25',
              SLIDER.pm25.max,
              widgetStates.pm25,
            )}
            min={SLIDER.pm25.min}
            device={device}
            onChange={onChangePm25}
            sliderDefaults={SLIDERS_DEFAULTS}
            defaultMarkValues={{
              top: 20,
              left: '29%',
              value: SLIDERS_DEFAULTS.pm25,
            }}
          />
        </div>

        {showView.pm25 && (
          <div className="col-12 switch-values-display">
            <Switches
              settings={extractedSettings}
              ckData={widgetCkStates?.pm25}
              onChange={onChangeWgtCk.bind(null, 'pm25')}
            />
          </div>
        )}

        <div className="col-12 hide-show" onClick={() => expandDetails('pm25')}>
          <div className="alert alert-light" role="alert">
            {showView.pm25 ? (
              <span className="lead handle-expand">
                <ExpandLess /> {config?.strings?.hide}
              </span>
            ) : (
              <span className="lead handle-expand">
                <ExpandMoreIcon /> {config?.strings?.show}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="row acc-row">
        <div className="col-12 slider-col">
          <Sliders
            step={10}
            datapoints={datapoints}
            id="w-co2"
            values={helpers.transformSingleSlider(widgetStates?.co2, 'co2')}
            max={helpers.normalizeMaxValues(
              'co2',
              SLIDER.co2.max,
              widgetStates.co2,
            )}
            min={SLIDER.co2.min}
            device={device}
            onChange={onChangeCO2}
            sliderDefaults={SLIDERS_DEFAULTS}
            defaultMarkValues={{
              top: 20,
              left: '18%',
              value: SLIDERS_DEFAULTS.co2,
            }}
          />
        </div>

        {showView.co2 && (
          <div className="col-12 switch-values-display">
            <Switches
              settings={extractedSettings}
              ckData={widgetCkStates?.co2}
              onChange={onChangeWgtCk.bind(null, 'co2')}
            />
          </div>
        )}

        <div className="col-12 hide-show" onClick={() => expandDetails('co2')}>
          <div className="alert alert-light" role="alert">
            {showView.co2 ? (
              <span className="lead handle-expand">
                <ExpandLess /> {config?.strings?.hide}
              </span>
            ) : (
              <span className="lead handle-expand">
                <ExpandMoreIcon /> {config?.strings?.show}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="row acc-row">
        <div className="col-12 slider-col">
          <Sliders
            step={10}
            datapoints={datapoints}
            id="w-tvoc"
            values={helpers.transformSingleSlider(widgetStates?.tVoc, 'tVoc')}
            max={helpers.normalizeMaxValues(
              'tVoc',
              SLIDER.tVoc.max,
              widgetStates.tVoc,
            )}
            min={SLIDER.tVoc.min}
            device={device}
            onChange={onChangeTvoc}
            sliderDefaults={SLIDERS_DEFAULTS}
            defaultMarkValues={{
              top: 20,
              left: '25%',
              value: SLIDERS_DEFAULTS.tVoc,
            }}
          />
        </div>

        {showView.tVoc && (
          <div className="col-12 switch-values-display">
            <Switches
              settings={extractedSettings}
              ckData={widgetCkStates?.tVoc}
              onChange={onChangeWgtCk.bind(null, 'tVoc')}
            />
          </div>
        )}

        <div className="col-12 hide-show" onClick={() => expandDetails('tVoc')}>
          <div className="alert alert-light" role="alert">
            {showView.tVoc ? (
              <span className="lead handle-expand">
                <ExpandLess /> {config?.strings?.hide}
              </span>
            ) : (
              <span className="lead handle-expand">
                <ExpandMoreIcon /> {config?.strings?.show}
              </span>
            )}
          </div>
        </div>
      </div>

      {showMenu && !valuesWereUpdated && (
        <div id="floating--div" className="float-btn--wrapper2">
          <div className="float-btn--content2 save--dv">
            <div className="container pdg mg-b-25">
              <div className="row pdg">
                <div className="col-10 pdg"></div>
                <div className="col-2 pdg c-2">
                  <button
                    className={`btn small-button ok`}
                    type="button"
                    data-toggle="modal"
                    data-target="#indoor--air--changes"
                    onClick={() => {
                      toggleUpdateMenuVisibility({
                        state: false,
                        section: 'ia',
                      });
                    }}
                  >
                    <span className="btn-label btn-child-ft">
                      <RiSave3Fill className="svg-ii" /> SAVE{' '}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
