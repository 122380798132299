import React, { useState, useEffect, ChangeEvent } from 'react';
import { ListProps } from './interface';
import { functions } from './helpers';
import { ListRow, ListHeader } from './components';
import Pagination from '@material-ui/lab/Pagination';
import { NoResults } from 'business/modules/admin/common';
import './styles.scss';

const List: React.FC<ListProps> = ({
  id,
  loaded,
  list,
  itemsPerPage,
  handles,
  orderedList,
  orders,
  manageListOrder,
  sectionCounter,
  sectionCount,
  updateOrderState,
  incremented,
  current,
  unorderedDefault,
  config,
  secondFun,
  fourthFunction,
  setItem,
  retrieveColValue,
  returnAccordionDetails,
  firstFun,
  thirdFun,
  rowClasses,
  helperOrderData,
  noMenu,
  icon,
  onlyCollapse,
  listWithoutHeader,
  iconWithInits,
  displayResultTypeIcon,
}) => {
  const [currentItems, setCurrentItems] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);

  const handlePaginate = (items: any) => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  const handlePageClick = (event: ChangeEvent<unknown>, value = 0) => {
    let calculatedNumber = value - 1;
    const newOffset = (calculatedNumber * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (loaded && list && list.length > 0) {
      handlePaginate(list);
    }
  }, [itemOffset, itemsPerPage, list, loaded]);

  useEffect(() => {
    return () => {
      manageListOrder(config.resettedCounter, 0);
    };
  }, []);

  //used for list ordering
  useEffect(() => {
    //Default case:
    if (current.trim().length === 0 || orderedList) {
      handlePaginate([...unorderedDefault]);
    }

    if (current && current.trim().length > 0 && !orderedList) {
      let result = functions.orderListByCriteria(
        current,
        list,
        helperOrderData,
        id,
      );

      if (functions.counterIsEven(sectionCounter[current])) {
        let reversed = result.reverse();

        handlePaginate([...reversed]);
      } else if (!functions.counterIsEven(sectionCounter[current])) {
        handlePaginate([...result]);
      }
    }
  }, [
    orders,
    sectionCounter,
    sectionCount,
    orderedList,
    current,
    helperOrderData,
  ]);

  return (
    <div className="container listed-items" id={`${id}--list`}>
      {!loaded ? (
        <div className="setup-internal">
          <></>
        </div>
      ) : loaded && list && list.length === 0 ? (
        <div className="alert alert-light pdg" role="alert">
          <NoResults noResultsTitle={`No information to display.`} />
        </div>
      ) : loaded && list && list.length > 0 ? (
        <div className="row setup-internal">
          {listWithoutHeader ? null : (
            <div className="container each-line header--cont">
              <div className="row separated-item">
                <ListHeader
                  id={`${id}--header`}
                  handleCounterAndListOrder={manageListOrder}
                  defineOrderType={updateOrderState}
                  counter={sectionCount}
                  intendedCounter={sectionCounter}
                  incremented={incremented}
                  config={config}
                />
              </div>
            </div>
          )}

          <div className="custom-list--body">
            {currentItems.map((item: any, index: number) => {
              return (
                <ListRow
                  id={`${id}--list-item`}
                  key={index}
                  index={index}
                  item={item}
                  {...handles}
                  functions={functions}
                  config={config}
                  setItem={setItem}
                  retrieveColValue={retrieveColValue}
                  returnAccordionDetails={returnAccordionDetails}
                  firstFun={firstFun}
                  secondFun={secondFun}
                  thirdFun={thirdFun}
                  fourthFunction={fourthFunction}
                  rowClasses={rowClasses}
                  noMenu={noMenu}
                  onlyCollapse={onlyCollapse}
                  circle={
                    <div className="circle">
                      <>
                        {icon ? (
                          icon
                        ) : iconWithInits && displayResultTypeIcon ? (
                          displayResultTypeIcon(item || '')
                        ) : (
                          <>
                            <span className="initials">
                              {functions.returnInit('f', item)}
                            </span>
                            <span className="initials">
                              {functions.returnInit('s', item)}
                            </span>{' '}
                          </>
                        )}
                      </>
                    </div>
                  }
                />
              );
            })}
          </div>

          <div className="d-flex justify-content-center custom-list--pagination">
            <Pagination count={pageCount} onChange={handlePageClick} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default List;
