import React, { useEffect, useState, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { EmailListProps } from './interface';
import { csvConverterUtil as CsvConverter } from './utils';
import { useHistory } from 'react-router-dom';
import { Modal, Template } from 'business/modules/admin/common';
import { Column } from './components';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { RiSave3Fill } from 'react-icons/ri';
import { MdErrorOutline } from 'react-icons/md';
import './styles.scss';

const ExtractEmails: React.FC<EmailListProps> = ({
  _emails,
  getUsersList,
  setEmails,
  loading,
  updateEmailsCheck,
  allSelected,
  updateSingleElement,
  saveFilteredEmails,
  filteredEmailsToGet,
  loadingErrors,
}) => {
  const history = useHistory();
  const [checkForm, setCheckForm] = useState<string>('initVal');
  const [accordionStates, setAccordionStates] = useState<any>({});
  const [someItemIsPicked, setSomeItemPicked] = useState<boolean>(false);

  const isObj = (item: any) => {
    if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
      return true;
    } else return false;
  };

  const toggleAcc = (itemId: any) => {
    setAccordionStates((prevState: any) => {
      return {
        ...prevState,
        [itemId]: { open: !accordionStates[itemId]?.open },
      };
    });
  };

  const fetchUsers = async () => {
    loading.start('Loading e-mail list...');
    const response: any = await getUsersList();

    if (response && response?.statusCode === 200) {
      const { body }: any = response || {};

      if (
        body &&
        body?.data &&
        Array.isArray(body?.data) &&
        body?.data?.length > 0
      ) {
        let _emails = body?.data.map((user: any) => {
          let item = {
            [user?.account_id]: {
              first_name: user?.first_name,
              last_name: user?.last_name,
              emails: user?.emails,
              selected: user?.download,
              account_id: user?.account_id,
            },
          };

          setAccordionStates((prevState: any) => {
            return {
              ...prevState,
              [user?.account_id]: { open: false },
            };
          });

          return item;
        });

        let selectedEmails =
          _emails &&
          _emails.length > 0 &&
          Object.entries(_emails).filter(([key, value]: [any, any]) => {
            let values: any = Object.values(value);

            return (
              values &&
              Array.isArray(values) &&
              values?.length > 0 &&
              values[0]?.selected
            );
          });

        //enable download btn if the user did not select nothing manually,
        // but there are already selected emails (in user page)
        if (selectedEmails && selectedEmails?.length > 0) {
          setSomeItemPicked(true);
        }

        setEmails(_emails);
      }
    }

    loading.stop();
  };

  const handleChange = (toggledChecked: boolean, key: string) => {
    setSomeItemPicked(true);

    const _newKey = Date.now().toString();

    setCheckForm(_newKey);

    let emails = _emails;

    let changed = {
      emails: [],
      first_name: '',
      last_name: '',
      selected: false,
      account_id: '',
    };

    emails &&
      emails.length > 0 &&
      emails.map((elem: any) => {
        return Object.entries(elem).filter(([_k, _v]: [any, any]) => {
          if (key === _k) {
            changed = _v;
          }
        });
      });

    changed.selected = toggledChecked;

    // check if currently there is something selected, to handle the downl btn
    let selectedEmails =
      emails &&
      emails.length > 0 &&
      Object.entries(emails).filter(([key, value]: [any, any]) => {
        let values: any = Object.values(value);

        return (
          values &&
          Array.isArray(values) &&
          values?.length > 0 &&
          values[0]?.selected
        );
      });

    if (selectedEmails && selectedEmails?.length > 0) {
      setSomeItemPicked(true);
    } else {
      setSomeItemPicked(false);
    }

    updateSingleElement({ key, changed });
  };

  const filterSelected = () => {
    let emailsToExtract = [];

    let data = Object.entries(_emails).filter(
      ([, value]: [any, any], i: number) => {
        return (
          value &&
          isObj(value) &&
          Object.values(value)?.length > 0 &&
          Object.values<any>(value)[0]?.selected === true
        );
      },
    );

    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.length > 0) {
          emailsToExtract.push(data[i][1]);
        }
      }
    }
    saveFilteredEmails(emailsToExtract);
  };

  const confirmEmailDwnld = () => {
    CsvConverter.exportCsv(filteredEmailsToGet);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setEmails(_emails);
  }, [_emails, allSelected]);

  useEffect(() => {
    return () => {
      setSomeItemPicked(false);
    };
  }, []);

  useEffect(() => {
    if (allSelected) {
      setSomeItemPicked(true);
    } else {
      setSomeItemPicked(false);
    }
  }, [allSelected]);

  useEffect(() => {
    return () => {
      setEmails([]);
    };
  }, []);

  return (
    <Template
      title={config?.strings?.title}
      id="extract-users-emails"
      goBack={() => {
        history.push('/admin/dashboard');
      }}
    >
      <BreadcrumbsItem to={config?.emailsList?.breadcrumbItem}>
        {config.strings.breadcrumbList}
      </BreadcrumbsItem>

      <div className={config.emailsList.class} id="emails-options--screen">
        <h4 className="centered">{config?.emailsList?.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config?.emailsList?.pText}</p>
        <hr className="my-4" />
        {loadingErrors &&
          loadingErrors?.usersListErr &&
          loadingErrors?.usersListErr?.length > 0 && (
            <div id="errors-display--5">
              <div className="alert alert-info" role="alert">
                <MdErrorOutline className="alert-icon" />
                <span className="lead err-string">
                  {loadingErrors?.usersListErr}
                </span>
              </div>
            </div>
          )}
        <div className="container spec-pdg mb-3" key={checkForm}>
          <div className="row">
            {_emails?.length > 0 && (
              <div className="col-12">
                <button
                  className={`small-button ok2 ${
                    allSelected ? `unselect-class` : `select-class`
                  }`}
                  onClick={() => {
                    updateEmailsCheck(!allSelected);
                  }}
                >
                  {allSelected
                    ? config?.strings?.unSelect
                    : config?.strings?.select}{' '}
                  {config?.strings?.all}
                </button>
                <button
                  disabled={someItemIsPicked ? false : true}
                  onClick={() => {
                    filterSelected();
                  }}
                  className="small-button ok"
                  data-toggle="modal"
                  data-target="#download-users-emails"
                >
                  {config?.strings?.downlSelected}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div
            className="row d-flex justify-content-center"
            style={{ minHeight: '55vh' }}
          >
            <Column
              _emails={_emails}
              handleChange={handleChange}
              toggleAcc={toggleAcc}
              accordionStates={accordionStates}
              start={0}
              end={Math.ceil(_emails.length / 2)}
            />

            <Column
              _emails={_emails}
              handleChange={handleChange}
              toggleAcc={toggleAcc}
              accordionStates={accordionStates}
              start={Math.ceil(_emails.length / 2)}
            />
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`download-users-emails`}
        title={config.modalStrings.title}
        icon={<RiSave3Fill className="edit" />}
        body={
          <div className="container pdg">
            <div className="row">
              <div className="col-12 pdg">
                {config?.modalStrings?.description}
              </div>
            </div>
          </div>
        }
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={``}
        additionalBody={
          <div className="container pdg">
            <div className="row">
              <div className="col-12 pdg scrollable-section">
                {Object.entries(filteredEmailsToGet).map(
                  ([_k, _v]: [any, any], idx: number) => {
                    return (
                      <Fragment key={idx}>
                        {Object.values(_v).map((elem: any, idx: number) => {
                          return (
                            <div className="card modal-result" key={idx}>
                              <div className="card-body">
                                <h6 className="card-title">
                                  {elem?.first_name} {elem?.last_name}
                                </h6>

                                <div className="card-text">
                                  {elem?.emails && elem?.emails?.length > 0 ? (
                                    elem?.emails?.map(
                                      (email: any, idx: number) => {
                                        return (
                                          <div className="row mb-2" key={idx}>
                                            <span className="lead lead-sm pdg">
                                              {email?.email}
                                            </span>
                                          </div>
                                        );
                                      },
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        }
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
        onClickCancel={() => {}}
        onClick={() => {
          confirmEmailDwnld();
        }}
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminUsersState } = state;
  return {
    _emails: adminUsersState._emails,
    allSelected: adminUsersState.allSelected,
    filteredEmailsToGet: adminUsersState.filteredEmailsToGet,
    loadingErrors: adminUsersState.loadingErrors,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminUsersState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getUsersList: adminUsersState.getUsersList,
    setEmails: adminUsersState.setEmails,
    updateEmailsCheck: adminUsersState.updateEmailsCheck,
    updateSingleElement: adminUsersState.updateSingleElement,
    saveFilteredEmails: adminUsersState.saveFilteredEmails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtractEmails);
