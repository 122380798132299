import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './styles.scss';


interface HeaderMenuProps {
  data?: any;
  options?: any;
  enabled?: boolean;
  functions?: any;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ data, options, enabled, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleMenuClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: any) => {
    setAnchorEl(null);
  }, []);

  const handleOptionAction = useCallback((options: any, event: any) => {
    const { _function }: any = options || {};

    props.functions[_function](event);
    handleCloseMenu(event);
  }, []);


  return (
    <div id="air-header-menu">
        <IconButton
          id="vert-more-btn"
          aria-controls={`actions-menu-${data?.device_id}`}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={!enabled}
          onClick={handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`actions-menu-${data?.device_id}`}
          className="header-actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'more-button',
          }}
        >
          {options && options.length > 0 && options.map((option: any, index: number) => {
            return (
              <MenuItem key={`menu-item-${index}`} onClick={handleOptionAction.bind(null, option)}>
                {option?.text || '. . .'}
              </MenuItem>
            );
          })}
        </Menu>
    </div>
  );
};

export default HeaderMenu;
