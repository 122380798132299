import React from 'react';
import { Form } from 'react-bootstrap';
import { MdAlternateEmail } from 'react-icons/md';

interface ColumnProps {
  _emails: any;
  handleChange: any;
  toggleAcc: Function;
  accordionStates: any;
  start: number;
  end?: number;
}

const Column: React.FC<ColumnProps> = ({
  _emails,
  handleChange,
  toggleAcc,
  accordionStates,
  start,
  end,
}) => {
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      {_emails?.length > 0 &&
        _emails.slice(start, end).map((element: any, index: number) => {
          return Object.entries<any>(element).map(([key, val], i) => {
            return (
              <div className="container" key={i}>
                <div className="row">
                  <div className="col-12 centered-cnt">
                    <div className="row extract-email-options">
                      <Form.Check
                        inline
                        label={`${val?.first_name} ${val?.last_name}`}
                        name="group1"
                        type={'checkbox'}
                        checked={val?.selected}
                        onClick={() => handleChange(!val?.selected, key)}
                        onChange={() => {}}
                      />
                    </div>
                  </div>

                  <div className="col-12 centered-cnt email-details-dwnl">
                    <div className="accordion" id={`accordion-item-email${i}`}>
                      <div className="card email-card">
                        <div className="card-header" id={val?.account_id}>
                          <h2 className="mb-0">
                            <button
                              onClick={() => {
                                toggleAcc(val?.account_id);
                              }}
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse-${val?.account_id}`}
                              aria-expanded={
                                accordionStates &&
                                accordionStates[val?.account_id] &&
                                accordionStates[val?.account_id]?.open
                              }
                              aria-controls={`collapse-${val?.account_id}`}
                            >
                              <span className="lead lead-sm">
                                <MdAlternateEmail className="bld-slategrey" />
                                {val &&
                                  val?.emails &&
                                  val?.emails?.length > 0 &&
                                  val?.emails[0]?.email}
                              </span>
                            </button>
                          </h2>
                        </div>

                        <div
                          id={`collapse-${val?.account_id}`}
                          className={
                            accordionStates &&
                            accordionStates[val?.account_id] &&
                            accordionStates[val?.account_id]?.open &&
                            accordionStates[val?.account_id].open
                              ? 'collapse show'
                              : 'collapse'
                          }
                          aria-labelledby={val?.account_id}
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            {val && val?.emails && val?.emails?.length === 1 ? (
                              <>
                                <span className="lead lead-sm">
                                  This user has only the email
                                </span>
                                <span className="email-sp">
                                  {val &&
                                    val?.emails &&
                                    val?.emails?.length > 0 &&
                                    val?.emails[0]?.email}
                                </span>
                                <span className="lead lead-sm">registered</span>
                              </>
                            ) : (
                              val &&
                              val?.emails &&
                              val?.emails?.length > 1 &&
                              val?.emails.map((email: any, idx: number) => {
                                return (
                                  <div className="container pdg" key={idx}>
                                    <div className="row">
                                      <div className="col-12 pdg email-list-item">
                                        <span className="lead lead-sm">
                                          {email?.email}
                                        </span>
                                        <div className="row"></div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        })}
    </div>
  );
};

export default Column;
