import config from '../../config/config';

export const jitBE = {
  basesURL: {
    baseURLs: config.apis.jitBE.baseURLs,
    baseSocketURLs: config.apis.jitBE.baseSocketURLs,
  },
  session: {
    auth: {
      path: '/session/loginWeb',
      description: 'loginWeb',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    authAdmin: {
      path: '/admin/auth',
      description: 'loginWebAdmin',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    registerWeb: {
      path: '/session/registerWeb',
      description: 'login -> admin',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    verifyToken: {
      path: '/session/verifyCode',
      description: 'login -> admin',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    resendCode: {
      path: '/session/resendCode',
      description: 'resendCode',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    recoverPassword: {
      path: '/session/recoverPassword',
      description: 'login -> admin',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    resetLostPassword: {
      path: '/session/resetLostPassword',
      description: 'login -> admin',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    resetPassword: {
      path: '/session/resetPassword',
      description: 'profile -> resetPassword',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  profile: {
    show: {
      path: '/profile/show',
      description: 'Profile|Show',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    update: {
      path: '/profile/update',
      description: 'Profile|update',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    uploadAvatar: {
      path: '/profile/uploadAvatar',
      description: 'Profile|uploadAvatar',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
  },
  devices: {
    create: {
      path: '/devices/create',
      description: 'save device',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    list: {
      path: '/devices/list',
      description: 'device list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    delete: {
      path: '/devices/delete',
      description: 'device delete',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    update: {
      path: '/devices/update',
      description: 'device update',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    update_attrr: {
      path: '/devices/update-attrr',
      description: 'device update attributes',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    show: {
      path: '/devices/show',
      description: 'show device details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  systems: {
    create: {
      path: '/systems/create',
      description: 'create system',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    list: {
      path: '/systems/list',
      description: 'list systems',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    dataChart: {
      path: '/systems/data-chart',
      description: 'get system chart stack',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    probeLastValues: {
      path: '/systems/probe/last-values',
      description: 'Get system probe values',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    status: {
      path: '/systems/status',
      description: 'Get System Status (running/stoped)',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },

    updateSettingsFlag: {
      path: '/system/flag/update',
      description: 'Update System Settings Flag',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },

    throtleUpdate: {
      path: '/systems/throtle/update',
      description: 'Update User Activity Flag',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },

  },
  zones: {
    list: {
      path: '/categories/list',
      description: 'zones list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/categories/show',
      description: 'show zone details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    create: {
      path: '/categories/create',
      description: 'create zone',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    delete: {
      path: '/categories/delete',
      description: 'delete zone',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    update: {
      path: '/categories/update',
      description: 'update/edit zone',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  foobot: {
    list: {
      path: '/third-party/foobot/list',
      description: 'devices list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/third-party/foobot/show',
      description: 'devices show',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    connect: {
      path: '/third-party/foobot/connect',
      description: 'check + save foobot user connection',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    updateDevices: {
      path: '/third-party/foobot/update-devices',
      description: 'update user foobot devices',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    disconnect: {
      path: '/third-party/foobot/disconnect',
      description: 'remove + update foobot user connection',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    lastDataPoint: {
      path: '/third-party/foobot/datapoint',
      description: 'foobot device last sensor values',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  weather: {
    feed: {
      path: '/third-party/weather/feed',
      description: 'weather data',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  systemSettings: {
    create: {
      path: '/system/settings/create',
      description: 'Create System Settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    list: {
      path: '/system/settings/list',
      description: 'List System Settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    delete: {
      path: '/system/settings/delete',
      description: 'Delete System Settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    show: {
      path: '/system/settings/show',
      description: 'Show System Settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    showBySysId: {
      path: '/system/settings/showBySysId',
      description: 'Show System Settings By System Id',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    update: {
      path: '/system/settings/update',
      description: 'Update System Settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  systemSetup: {
    create: {
      path: '/system/setup/create',
      description: 'Create System Setup',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    list: {
      path: '/system/setup/list',
      description: 'List System Setup',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    delete: {
      path: '/system/setup/delete',
      description: 'Delete System Setup',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    show: {
      path: '/system/setup/show',
      description: 'Show System Setup by settingsId',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    showBySysId: {
      path: '/system/setup/showBySysId',
      description: 'Show System Setup By System Id',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    update: {
      path: '/system/setup/update',
      description: 'Update System Setup',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  manualControl: {
    create: {
      path: '/manual-control/create',
      description: 'Create Manual Control',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    list: {
      path: '/manual-control/list',
      description: 'List Manual Control',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    delete: {
      path: '/manual-control/delete',
      description: 'Delete Manual Control',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    show: {
      path: '/manual-control/show?system_id={value}',
      description: 'Show Manual Control by settingsId',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    update: {
      path: '/manual-control/update',
      description: 'Update Manual Control',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  airthings: {
    connect: {
      path: '/third-party/airthings/connect',
      description: 'save Airthings user connection',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    disconnect: {
      path: '/third-party/airthings/disconnect',
      description: 'remove/update Airthings user connection',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    list: {
      path: '/third-party/airthings/list',
      description: 'devices list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/third-party/airthings/show',
      description: 'device show',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  awair: {
    connect: {
      path: '/third-party/awair/connect',
      description: 'save Awair user connection',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    disconnect: {
      path: '/third-party/awair/disconnect',
      description: 'remove/update Awair user connection',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    list: {
      path: '/third-party/awair/list',
      description: 'awair devices list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/third-party/awair/show',
      description: 'awair device show',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  airCyclerLocal: {
    list: {
      path: '/third-party/aircycler/local/list',
      description: 'AirCycler local sensor list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/third-party/aircycler/local/show',
      description: 'AirCycler local sensor show',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  installer: {
    listConfigs: {
      path: '/installer/configurations/list',
      description: 'configurations list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    showConfig: {
      path: '/installer/configurations/show',
      description: 'show configuration details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    createConfig: {
      path: '/installer/configurations/create',
      description: 'create a new configuration',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    deleteConfig: {
      path: '/installer/configurations/delete',
      description: 'delete configuration',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    updateConfig: {
      path: '/installer/configurations/update',
      description: 'update configuration',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    listSystems: {
      path: '/installer/systems/list',
      description: 'List installer systems',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    showSystem: {
      path: '/installer/systems/show',
      description: 'Show installer system',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    deleteSystem: {
      path: '/installer/systems/delete',
      description: 'Delete installer system',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
  },
  userConfigs: {
    listConfigs: {
      path: '/configurations/list',
      description: 'User configurations list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    createConfig: {
      path: '/configurations/create',
      description: 'Create a user configuration',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    showConfig: {
      path: '/configurations/show',
      description: 'Show configuration details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    updateConfig: {
      path: '/configurations/update',
      description: 'Update an user configuration',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    deleteConfig: {
      path: '/configurations/delete',
      description: 'Delete an user configuration',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
  },
  dataLogging: {
    list: {
      path: '/data-loggs/list',
      description: 'list system data-logs',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/data-loggs/show',
      description: 'show system data-log details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    listByPeriods: {
      path: '/data-loggs/listByPeriods',
      description: 'list system data-logs by periods size',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    listByDates: {
      path: '/data-loggs/listByDates',
      description: 'list system data-logs by range dates',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  userAlerts: {
    list: {
      path: '/alerts/list',
      description: 'list all alerts',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    show: {
      path: '/alerts/show',
      description: 'show unviewed alerts',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    updateStatus: {
      path: '/alerts/update/status',
      description: 'to mark alert as viewed.',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
  },
  deleteAccount: {
    request: {
      path: '/delete-account/request',
      description: 'request to delete account',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    confirm: {
      path: '/delete-account/request/confirm',
      description: 'Confirm delete account request',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
  },
  adminProfile: {
    show: {
      path: '/admin/show',
      description: 'Admin|Profile|Show',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
  admin: {
    adminDevicesShow: {
      path: '/admin/devices/show',
      description: 'Show device details by device_id',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    adminWeatherFeed: {
      path: '/admin/weather/feed',
      description: 'weather data admin',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    adminSystemSettingsShow: {
      path: '/admin/system-settings/show',
      description: 'Admin system settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    //system settings per user
    adminSystemSettingsListPerUser: {
      path: `/admin/system-settings/list`,
      description: `Admin system settings list per user`,
      cache: {
        enabled: false,
      },
      headers: {},
      method: `get`,
    },
    adminUsersList: {
      path: '/admin/users/list',
      description: 'List Users',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    adminUsersCreate: {
      path: 'admin/create',
      description: 'Create Users',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    adminUsersUpdate: {
      path: '/admin/users/update',
      description: 'Update user',
      cache: {
        enabled: true,
      },
      headers: {},
      method: 'put',
    },
    adminUsersDelete: {
      path: 'admin/delete',
      description: 'Delete Admin Users',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    adminUsersAdminList: {
      path: 'admin/list',
      description: 'List of Admin Users',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    adminUsersShow: {
      path: '/admin/show',
      description: 'Details about an admin user',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    commandsList: {
      path: '/admin/cmds/list',
      description: 'List Commands',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    devicesListPerUser: {
      path: '/admin/devices/list',
      description: 'List of devices per account',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    firmwareVersion: {
      path: '/admin/firmware/version',
      description: 'Admin | Firmware | Show Version',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    indoorAir: {
      path: '/admin/indoor-air/show',
      description: 'Show indoor air details of a device',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    indoorAirAdminEdit: {
      path: '/admin/indoor-air/edit',
      description: 'Update Indoor Air admin information',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    manualControl: {
      path: '/admin/manual-control/show',
      description: 'Show manual control details of a device',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    manualControlEdit: {
      path: '/admin/manual-control/edit',
      description: 'Edit manual control admin information',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    outsideAir: {
      path: '/admin/outside-air/show',
      description: 'Show outside air details of a device',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    outsideAirAdminEdit: {
      path: '/admin/outside-air/edit',
      description: 'Edit Outside Air admin information',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    pressConnectEdit: {
      path: '/admin/pressure-connect/edit',
      description: 'Edit Pressure Connect settings',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    pressConnectShow: {
      path: '/admin/pressure-connect/show',
      description: 'Show Pressure Connect settings by system id',
      cache: {
        enabled: false,
      },
      header: {},
      method: 'get',
    },
    saveFirmware: {
      path: '/admin/firmware/update',
      description: 'update firmware version',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    sensorsList: {
      path: '/admin/sensors/list',
      description: 'List Sensors',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    sensorsShow: {
      path: '/admin/sensors/show',
      description: 'Show Sensors',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    showUser: {
      //replaces adminUsers.showUser
      path: '/admin/users/show',
      description: 'Show details of an user',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    setupLogsList: {
      path: '/admin/conf-logs/list',
      description: 'List admin setup logs',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    setupLogsShow: {
      path: '/admin/conf-logs/show',
      description: 'Show admin setup logs details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    getGeneratedCode: {
      path: '/admin/company/code',
      description: 'Get generated code',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    createCompany: {
      path: '/admin/company/create',
      description: 'Create a new company',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    updateACompany: {
      path: '/admin/company/update',
      description: 'Update existing company',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    getCompaniesList: {
      path: '/admin/company/list',
      description: 'Get companies list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    deleteCompany: {
      path: '/admin/company/delete',
      description: 'Delete company',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    showCompany: {
      path: '/admin/company/show',
      description: 'Show company details',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    dataLoggingList: {
      path: '/admin/data-logs/list',
      description: "Get system's data logging list",
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    dataLoggingListByDates: {
      path: '/admin/data-logs/listByDates',
      description: 'Get data logs by date interval',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    dataLoggingListByPeriods: {
      path: '/admin/data-logs/listByPeriods',
      description: 'Get data logging by periods',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    getDeleteReqs: {
      path: `/admin/delete-account/requests/list`,
      description: 'Get the delete account requests list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    deleteRequestToDeleteAccount: {
      path: `/admin/delete-account/request/delete`,
      description: `Delete the request to delete the account`,
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    cancelRequestToDeleteAccount: {
      path: `/admin/delete-account/request/cancel`,
      description: `Cancel the request to delete the account`,
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    deleteUser: {
      path: `/admin/users/delete`,
      description: `Delete an user`,
      cache: {
        enabled: false,
      },
      headers: {},
      method: `delete`,
    },
    requestLogsBySystem: {
      path: '/admin/requests/logs-list',
      description: 'List system logs',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    requestSystemsByUser: {
      path: '/admin/requests/systems-list',
      description: 'List systems by user id',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    requestSensorsByUser: {
      path: '/admin/requests/sensors-list',
      description: 'List sensors by user id',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    getProbeLastValues: {
      path: '/admin/devices/probe/last-values',
      description: 'Get probe most recent values',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    getSystemStatus: {
      path: '/admin/systems/status',
      description: 'Get the G3 current status',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    getClientsList: {
      path: '/admin/ext-api/client/list',
      description: 'Get clients list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    getClientUserAndSecret: {
      path: '/admin/ext-api/auth/gen',
      description: 'Generate Client user and secret',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
    createClient: {
      path: '/admin/ext-api/auth/register',
      description: 'Register client',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'post',
    },
    editClient: {
      path: '/admin/ext-api/client/update',
      description: 'Edit client data',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'put',
    },
    deleteClient: {
      path: '/admin/ext-api/client/delete',
      description: 'Delete client',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'delete',
    },
    search: {
      path: `/admin/search`,
      description: 'Get search results',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  }, //end of admin object
  adminUsers: {
    show: {
      path: '/admin/users/show',
      description: 'Show Users',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    },
  },
};
