import React, { Fragment } from 'react';
import { keys, relevantProps, strings } from './config';
import { helpers } from './helpers';
import { Row } from 'business/modules/admin/common';
import './styles.scss';

interface SensorDataProps {
  item: any;
}

const SensorData: React.FC<SensorDataProps> = ({ item }) => {
  const itemHasAQ = item && item?.hasOwnProperty('airQuality');

  const handleKey = (key: string) => {
    return keys[key as keyof typeof keys] || '';
  };

  const handleValues = (value: any, key: string) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return <>No values to display</>;
    }

    if (!value) return 'No value';
    if (typeof value === 'boolean') return value.toString();
    if (Array.isArray(value) && !Array.isArray(value[0])) {
      return value.map((elem: any, index: number) => (
        <Fragment key={index}>{elem} </Fragment>
      ));
    } else if (Array.isArray(value) && Array.isArray(value[0])) {
      //datapoints
      return value[0].map((item: any, idx: number) => (
        <Fragment key={idx}>{item}, </Fragment>
      ));
    } else if (typeof value === 'string' || typeof value === 'number') {
      return value;
    } else {
      return Object.entries(
        helpers.handleRelevantProps(
          helpers.handleInternalObject(value, key),
          relevantProps[key as keyof typeof relevantProps],
        ),
      ).map(([key, value]: [any, any], i: number) => {
        return (
          <div className="d-inline" key={i}>
            <span className="inline-label lead lead-sm label-result-sp">
              {handleKey(key)}
            </span>

            {(key === 'firmwareVersions' || key === 'sensorsStats') && (
              <div className="row"></div>
            )}

            <span className="lead lead-sm">{renderElements(value, key)}</span>
            <div className="row"></div>
          </div>
        );
      });
    }
  };

  const itemsAreObjs = (currentValue: any) => helpers.isOb(currentValue);

  const renderElements = (element: any, key: string) => {
    if (helpers.isOb(element)) {
      if (key === 'stats') {
        return (
          element &&
          helpers.isOb(element) &&
          Object.entries(element).map(([key, value]: [any, any], i: number) => {
            return (
              <div className="container pdg" key={i}>
                <div className="row mlmr0">
                  <span className={`pdg inline-label2 lead lead-sm`}>
                    {handleKey(key)}
                  </span>

                  {value &&
                    helpers.isOb(value) &&
                    Object.entries(value).map(
                      ([key, val]: [any, any], i: number) => {
                        return (
                          <Row
                            key={i}
                            outClass={`d-inline pdg`}
                            firstClass={`inline-label lead lead-sm label-result-sp`}
                            firstContent={handleKey(key)}
                            secondClass={`lead lead-sm`}
                            secondContent={
                              helpers.isOb(val) || Array.isArray(val)
                                ? JSON.stringify(val)
                                : val
                            }
                          />
                        );
                      },
                    )}
                </div>
              </div>
            );
          })
        );
      }

      return <></>;
    }
    if (helpers.isArray(element)) {
      return element && element.length === 0 ? (
        <>No values to display</>
      ) : (
        element &&
          itemsAreObjs(element) &&
          Object.entries(element).map(([key, val]: [any, any], i: number) => {
            return (
              <Fragment key={i}>
                {helpers.isOb(val) ? (
                  Object.entries(val).map(
                    ([key, val]: [any, any], i: number) => {
                      return (
                        <div className="container pdg" key={i}>
                          <div className="row plpr0">
                            {key === 'firmwareType' && (
                              <div className="spaced-below"> </div>
                            )}
                            <Row
                              key={i}
                              outClass={`d-inline pdg`}
                              firstClass={`inline-label lead lead-sm label-result-sp`}
                              firstContent={handleKey(key)}
                              secondClass={`lead lead-sm`}
                              secondContent={
                                <>
                                  {helpers.isArray(val)
                                    ? JSON.stringify(val)
                                    : helpers.isOb(val)
                                    ? renderElements(val, key)
                                    : typeof val === 'boolean'
                                    ? val.toString()
                                    : val}
                                </>
                              }
                              optionalContent={
                                <>
                                  {key === 'latest' && (
                                    <div className="items-separator"> </div>
                                  )}
                                </>
                              }
                            />
                          </div>
                        </div>
                      );
                    },
                  )
                ) : helpers.isArray(val) ? (
                  <></>
                ) : (
                  val
                )}
              </Fragment>
            );
          })
      );
    } else {
      return typeof element === 'boolean'
        ? element.toString()
        : typeof element === 'number' && key === 'distanceToLocationInKm'
        ? helpers.truncVal(element)
        : element;
    }
  };

  const renderItemDetails = (item: any) => {
    return (
      <Fragment>
        <div className="row">
          {item && item.hasOwnProperty('datapoint') ? (
            <div
              className={`col-xs-12 col-sm-12 col-md-${itemHasAQ ? '4' : '6'}`}
            >
              <div className="card">
                <h5 className="card-title spec-card-title">
                  {strings?.firstSection}
                </h5>

                <div className="card-body mh-18rem1">
                  <div className="col-12">
                    {Object.entries(item?.datapoint)
                      .slice(
                        0,
                        Math.round(Object.entries(item?.datapoint).length / 2),
                      )
                      .map(([key, value]: [any, any], i: number) => {
                        return (
                          <Row
                            key={i}
                            outClass={`d-inline`}
                            firstClass={`inline-label lead lead-sm label-result-sp`}
                            firstContent={handleKey(key)}
                            secondClass={`lead lead-sm`}
                            secondContent={handleValues(value, key)}
                          />
                        );
                      })}
                  </div>
                  <div className="col-12">
                    {Object.entries(item?.datapoint)
                      .slice(
                        Math.round(Object.entries(item?.datapoint).length / 2),
                      )
                      .map(([key, value]: [any, any], i: number) => {
                        return (
                          <Row
                            key={i}
                            outClass={`d-inline`}
                            firstClass={`inline-label lead lead-sm label-result-sp`}
                            firstContent={handleKey(key)}
                            secondClass={`lead lead-sm`}
                            secondContent={handleValues(value, key)}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {item && item?.hasOwnProperty('airQuality') && (
            <div
              className={`col-xs-12 col-sm-12 col-md-${itemHasAQ ? '4' : '6'}`}
            >
              <div className="card">
                <h5 className="card-title spec-card-title">
                  {strings?.secondSection}
                </h5>

                <div className="card-body mh-18rem">
                  <div className="col-12 pdg">
                    {Object.entries(item?.airQuality)
                      .slice(0, 5) //show just some of them
                      .map(([key, value]: [any, any], idx: number) => {
                        return (
                          <div className="container pdg" key={idx}>
                            {Object.entries(value).map(
                              ([key, value]: [any, any], i: number) => {
                                return (
                                  <div
                                    key={i}
                                    className={`row mlmr0 ${
                                      key === 'time' && idx !== 0 ? 'mt5' : ''
                                    }`}
                                  >
                                    <div
                                      className={`d-inline pdg ${
                                        key === 'time' && i !== 0 ? 'mgt-4' : ''
                                      }`}
                                      key={i}
                                    >
                                      {helpers.renderRow(key) ? (
                                        <div
                                          className={`inline-label lead lead-sm label-result-sp`}
                                        >
                                          {handleKey(key)}
                                        </div>
                                      ) : (
                                        <span
                                          className={`inline-label lead lead-sm label-result-sp`}
                                        >
                                          {handleKey(key)}
                                        </span>
                                      )}

                                      <div
                                        className={`lead lead-sm  ${
                                          key === 'time' ? 'spaced-below' : ''
                                        }`}
                                      >
                                        {helpers.isOb(value)
                                          ? JSON.stringify(value)
                                          : helpers.isArray(value)
                                          ? Object.values(value).map(
                                              (el: any, idx: number) => {
                                                return Object.entries(el).map(
                                                  (
                                                    [k, v]: [any, any],
                                                    index: number,
                                                  ) => {
                                                    return (
                                                      <div
                                                        className="container pdg"
                                                        key={index}
                                                      >
                                                        <div
                                                          className={`row mlmr0 ${
                                                            k === 'sensor' &&
                                                            'pt4'
                                                          }`}
                                                        >
                                                          <Row
                                                            key={index}
                                                            outClass={`d-inline pdg`}
                                                            firstClass={`inline-label lead lead-sm label-result-sp`}
                                                            firstContent={handleKey(
                                                              k,
                                                            )}
                                                            secondClass={`lead lead-sm`}
                                                            secondContent={
                                                              <>
                                                                {helpers.isArray(
                                                                  v,
                                                                )
                                                                  ? JSON.stringify(
                                                                      v,
                                                                    )
                                                                  : helpers.isOb(
                                                                      v,
                                                                    )
                                                                  ? JSON.stringify(
                                                                      v,
                                                                    )
                                                                  : typeof v ===
                                                                    'boolean'
                                                                  ? v.toString()
                                                                  : v}
                                                              </>
                                                            }
                                                            optionalContent={
                                                              <>
                                                                {idx === 3 &&
                                                                  k ===
                                                                    'value' && (
                                                                    <div className="row">
                                                                      <hr className="items-separator" />
                                                                    </div>
                                                                  )}
                                                              </>
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  },
                                                );
                                              },
                                            )
                                          : Array.isArray(value) ||
                                            helpers.isOb(value)
                                          ? null
                                          : value}
                                      </div>
                                      <div className="row"></div>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {item && item?.hasOwnProperty('health') ? (
            <div
              className={`col-xs-12 col-sm-12 col-md-${itemHasAQ ? '4' : '6'}`}
            >
              <div className="card">
                <h5 className="card-title spec-card-title">
                  {strings?.thirdSection}
                </h5>

                <div className="card-body mh-18rem">
                  <div className="col-12 pdg">
                    {Object.entries(item?.health).map(
                      ([key, value]: [any, any], i: number) => {
                        return (
                          <div className={`d-inline`} key={i}>
                            {helpers.renderRow(key) ? (
                              <div
                                className={`inline-label lead lead-sm label-result-sp slategrey-ft`}
                              >
                                {handleKey(key)}
                              </div>
                            ) : (
                              <span
                                className={`inline-label lead lead-sm label-result-sp`}
                              >
                                {handleKey(key)}
                              </span>
                            )}

                            {helpers.renderRow(key) && (
                              <div className="row"></div>
                            )}
                            <span className="lead lead-sm">
                              {handleValues(value, key)}
                            </span>
                            <div className="row"></div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="container results-sensor-additional-info pdg">
      <div className="row mlmr0">
        <div className="col-12 pdg pdgtp">{renderItemDetails(item)}</div>
      </div>
    </div>
  );
};

export default SensorData;
