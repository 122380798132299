import _ from 'lodash';


export default {
  prepareSystemSettings: (sysSettings: any, selectedSysSettings: any) => {
    let sysSettingsClone: any = sysSettings?.settings ? _.cloneDeep(sysSettings.settings) : {};
    const { settings }: any = selectedSysSettings || {};

    if(
      sysSettingsClone &&
      Object.keys(sysSettingsClone).length > 0
    ){
      const sysSettingsKey: string = Object.keys(sysSettingsClone)[0];

      if(sysSettingsKey){
        sysSettingsClone[sysSettingsKey] = _.cloneDeep(settings);
      }
    }

    return sysSettingsClone;
  },
  prepareWidgetsSetups: (systemId: string, selectedSysSettings: any) => {
    const { setup, name }: any = selectedSysSettings || {};

    if(
      setup?.indoorAir &&
      setup?.outsideAir
    ){
      const { indoorAir, outsideAir }: any = setup || {};

      return {
        setup: {
          indoorAir: indoorAir ? _.cloneDeep(indoorAir) : {},
          outsideAir: outsideAir ? _.cloneDeep(outsideAir) : {},
        },
        system_id: systemId,
        ok: true,
      };
    }

    return {
      setup: {
        indoorAir: {},
        outsideAir: {},
      },
      system_id: systemId,
      ok: true,
    };
  },
  mergeCtrlTargetValues: (sysSettings: any, selectedTarget: any) => {
    let sysSettingsKey: string = Object.keys(sysSettings?.settings)[0];
    let sysSettingsClone: any = _.cloneDeep(sysSettings);

    if(
      sysSettingsKey !== null &&
      sysSettingsKey !== undefined
    ){
      let settingsClone: any = _.cloneDeep(sysSettings.settings);
      const { cdid }: any = selectedTarget || {};
      
      const {
        mner, mnsf, mvcr, vcwm,
        exwm, vcfr, vcdt, efdt,
        effr, tmzn,
      }: any = settingsClone[sysSettingsKey] || {};

      if(cdid === 'fanConnect'){
        const fanSettings: any = {
          ...settingsClone[sysSettingsKey],
          mner: mner || mvcr,
          efdt: efdt || vcdt,
          effr: effr || vcfr,
          tmzn: tmzn,
        };
        sysSettingsClone.settings = {};
        sysSettingsClone.settings['fanConnect'] = _.cloneDeep(fanSettings);
      }else{
        const ventSettings: any = {
          ...settingsClone[sysSettingsKey],
          mvcr: mvcr || mner,
          vcdt: vcdt || efdt,
          vcfr: vcfr,
          tmzn: tmzn,
        };
        sysSettingsClone.settings = {};
        sysSettingsClone.settings[cdid] = _.cloneDeep(ventSettings);
      }
    }

    return sysSettingsClone;
  },
  backgroundSystemSettingsUpdate: (handleUpdateSystemSettings: any, payload: any) => {
    try {
      setTimeout(() => {
        handleUpdateSystemSettings(payload);
      }, 1000 * 45);
    } catch (error) {
      console.log(
        '\n ERROR => ON BACKGROUND => UPDATE SYS SETTINGS   ',
        error,
      );
    }
  },
  prepareSystemNoneSettings: (
    systemId: string,
    selectedSysSettings: any,
    settingsKey: any
  ) => {
    const { settings }: any = selectedSysSettings || {};
    let sysSettings: any = {
      system_id: systemId,
      settings: {
        [settingsKey && settingsKey !== '' ? settingsKey : 'fanConnect']: _.cloneDeep(settings),
      },
    };

    return sysSettings;
  },


};
