export default {
  strings: {
    stLabel: '@indoor-air::',
    hide: `Hide setup`,
    show: `Show setup`,
    title: `Indoor Air Quality (IAQ)`,
    str1: `For the system to work as expected it is necessary to configure a sensor in order to have reference values.`,
    p: `IAQ parameters and data sourced from connected air quality monitor.`,
    p2: `Set preferred parameter limits and select how your AirCycler® Connect System responds to IAQ events.`,
    confirmation: `You are about to update the Indoor Air configurations.`,
  },
  sliders_defaults: {
    pm25: 150,
    co2: 750,
    tVoc: 500,
  },
  sliderValues: {
    pm25: {
      max: 500,
      min: 0,
    },
    co2: {
      max: 4000,
      min: 0,
    },
    tVoc: {
      max: 2000,
      min: 0,
    },
  },
};
