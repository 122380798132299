import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../modules/sessions/pages/SignIn';
import LandingPage from '../modules/landing-page/pages/LandingPage';
import SignInCode from '../modules/sessions/pages/SignInCode';
import SignUp from '../modules/sessions/pages/SignUp';
import RecoverPassword from '../modules/sessions/pages/RecoverPassword';
import SignUpConfirm from '../modules/sessions/pages/SignUpConfirm';
import SignUpConfirmed from '../modules/sessions/pages/SignUpConfirmed';
import ResetPassword from '../modules/sessions/pages/ResetPassword';
import DeleteRequestConfirmed from '../modules/sessions/pages/DeleteRequestConfirmed';
import DeleteAccount from '../modules/sessions/pages/DeleteAccount';
import Dashboard from '../modules/home';
import InstallerHome from '../modules/installer';
import AdminRoutes from '../modules/admin/routes';
import { useAuth } from '../modules/sessions/hooks/auth';


const Routes: React.FC = ({ ...props }) => {
  const { user, accountType }: any = useAuth();



  const RouteController = ({ account }: any) => {
    switch (account) {
      case 'installer':
        return (
          <Route
            path="/installer"
            component={InstallerHome}
            account={account}
            isPrivate
            isInstaller
          />
        );
      case 'customer':
      default:
        return (
          <Route
            path="/dashboard"
            component={Dashboard}
            account={account}
            isPrivate
          />
        );
    }
  };




  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/admin" component={AdminRoutes} isAdmin isPrivate />
      <Route path="/login" exact component={SignIn} />
      <Route
        path="/confirm/delete/request/:token"
        exact
        component={DeleteRequestConfirmed}
        isConfirm
      />
      <Route path="/delete-account" exact component={DeleteAccount} isSingle />
      <Route path="/login/token/:token" exact component={SignUpConfirmed} />
      <Route path="/login/code" exact component={SignInCode} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/signup/confirm" exact component={SignUpConfirm} />
      <Route path="/recover-password" exact component={RecoverPassword} />
      <Route
        path="/lost-password/token/:token"
        exact
        component={ResetPassword}
      />
      <RouteController account={accountType} />
    </Switch>
  );
};

export default Routes;
