import React, { useEffect, Fragment, useRef, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { InstallersProps } from './interface';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import {
  Template,
  List,
  Modal,
  TooltipView,
  GenTooltip,
  Notification,
} from 'business/modules/admin/common';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { IoAdd } from 'react-icons/io5';
import { FaRegCopy } from 'react-icons/fa';
import './styles.scss';

const Installers: React.FC<InstallersProps> = ({
  compItem,
  saveCompItemGlobally,
  defInstallerToEdit,
  installersCounter,
  installersIntendedCounter,
  orderedInstallersList,
  orders,
  updateOrderState,
  handleInstallersListCounterAndOrder,
  installerItem,
  saveInstallerItemGlobally,
  setToEdit,
}) => {
  const history = useHistory();
  const goToListRef = useRef<any>(null);
  const [installers, setInstallers]: any = useState([]);
  const [loadedInstallers, setLoadedInstallers] = useState<boolean>(false);
  const [initList, setInitList]: any = useState([]);
  const [current, setCurrent] = useState('');
  const [unorderedDefault, setUnorderedDefault] = useState([]);

  const classes: any = {
    status: `ia-left`,
    updatedAt: `ia-left`,
    ph: `ia-left`,
    city: `ia-left`,
    code: `ia-left`,
    name: `ia-right`,
    createdAt: `ia-right`,
    state: `ia-right`,
    zipcode: `ia-right`,
    em: `ia-right`,
  };

  const incremented: any = {
    name: installersIntendedCounter.name + 1,
    location: installersIntendedCounter.location + 1,
    devs: installersIntendedCounter.devs + 1,
  };

  const isObj = (item: any) => {
    if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
      return true;
    } else return false;
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //TODO: handle error copying to clipboard
    }
  };

  const copyToClipboard = (item: any) => {
    copyContent(item);

    Notification({
      title: 'Success',
      message: 'Code copied to clipboard',
      type: 'success',
    });
  };

  const viewInstalledDevs = (item: any) => {
    history.push(`/admin/dashboard/companies-list/company/installers/devices`);
  };

  const handleView = (item: any) => {
    defInstallerToEdit(item?.installer_id);

    setToEdit(item);

    history.push(
      `/admin/dashboard/companies-list/company/installers/edit-installer`,
    );
  };

  // emulate call to get the installers
  // note: since now we are using mock data,
  // all the companies have the same "installers"

  const getTheInstallers = () => {
    if (config.mockInstallers.statusCode === 200) {
      setInstallers(config.mockInstallers.body?.data);

      // keeps the default order:
      setInitList(config.mockInstallers?.body?.data);
      setLoadedInstallers(true);
    } else {
      //TODO handle error
    }
  };

  const addInstaller = () => {
    history.push(
      `/admin/dashboard/companies-list/company/installers/add-installer`,
    );
  };

  const goToList = () =>
    goToListRef && goToListRef.current.scrollIntoView({ behavior: 'smooth' });

  const goToSection = (id: string) => {
    id === `go-to-installers` ? goToList() : goToCompanies();
  };

  const goToCompanies = () => {
    history.push(`/admin/dashboard/companies-list`);
  };

  const filterRelevantProps = (compItem: any) => {
    let filteredObject: any = {};
    if (Object.keys(compItem).length > 0) {
      let filteredPropsArray = Object.entries(compItem).filter(
        (item: any, val: any) => {
          return (
            item[0] !== 'company_id' &&
            item[0] !== '_id' &&
            item[0] !== '__v' &&
            item[0] !== 'installers'
          );
        },
      );
      filteredPropsArray.forEach((item: any) => {
        filteredObject[item[0]] = item[1];
      });
    }
    return filteredObject;
  };

  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return (
          <div
            className={`col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 comp-name`}
          >
            {item.name}
          </div>
        );
      case '2':
        return (
          <div
            className={`col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 comp-location`}
          >
            {item.zipcode}, {item.state}
          </div>
        );
      case '3':
        return (
          <div
            className={`col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 center--dv--1`}
          >
            {item?.installedDevices?.length} devices.
          </div>
        );

      default:
        return ``;
    }
  };

  const formatDate = (date: any, key: string) => {
    if (key === `createdAt` || key === `updatedAt`) {
      return date ? moment(new Date(date)).format('MM-DD-YYYY, hh:mm A') : '';
    }
  };

  const returnAccordionDetails = (item: any) => {
    return (
      <div className="jumbotron installer-accordion-details">
        <h6 className="display-5"> {config.strings.main}</h6>
        <p className="lead smlead">
          {config.strings.lead}
          <span className="stronger-ft">{item?.name}</span>.
        </p>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title ct-csubt">{config.strings.cardTitle}</h5>
            <h6 className="card-subtitle text-muted ct-csubt">
              <>
                <IoInformationCircleOutline className="the-icon" />{' '}
                <span className="install-card-sub">
                  {config.strings.cardSubtitle}{' '}
                </span>
              </>
            </h6>
            <div className="card-text">
              <div className="container pdg">
                <div className="row mt-15p">
                  {Object.entries<any>(item).map(([key, value], idx) => {
                    return (
                      functions.isRelevantKey(key) && (
                        <div
                          className="col-xs-12 col-sm-12 col-md-4 info--cols-install"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead lead-smaller1">
                              {functions.returnLabel(key)}
                            </span>

                            {functions.isADate(key) ? (
                              <span className="lead lead-smaller1">
                                {formatDate(value, key)}
                              </span>
                            ) : key === `installedDevices` ? (
                              <span
                                className={`lead lead-smaller1 ${`break-word-all`}`}
                              >
                                {value.length}
                              </span>
                            ) : (
                              <span
                                className={`lead lead-smaller1 ${
                                  key === `installer_id` ? `break-word-all` : ``
                                }`}
                              >
                                {value}
                                {key === 'code' && (
                                  <TooltipView
                                    title={
                                      <GenTooltip
                                        title={`Grab the code`}
                                        textOne={`Copy the code to clipboard`}
                                      />
                                    }
                                  >
                                    <span>
                                      <button
                                        // data-backdrop="false"
                                        id="create-clt-fareg-btn1-instal"
                                        type="button"
                                      >
                                        <FaRegCopy
                                          style={{
                                            color: '#000000',
                                            strokeWidth: '30',
                                            stroke: 'white',
                                            marginLeft: '2px',
                                          }}
                                          className="copy-to-clipboard-icon fareg-i"
                                          onClick={() => copyToClipboard(value)}
                                        />{' '}
                                      </button>
                                    </span>
                                  </TooltipView>
                                )}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      )
                    );
                  })}
                  <Fragment>
                    {config._info.map((elem: any, index: number) => {
                      return (
                        <div className={elem.col} key={index}>
                          <span className={elem.cardValuesCls}>
                            <span className={elem.spanCls}>
                              {elem.spanLabel}
                            </span>
                          </span>
                          <span className={elem.spanCls2}>
                            {elem.spanLabel === `Phone`
                              ? item?.contact?.phone
                              : item?.contact?.email}
                          </span>
                        </div>
                      );
                    })}
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const returnIcon = (key: any) => {
    if (config.icons.hasOwnProperty(key)) {
      return config.icons[key as keyof typeof config.icons];
    }
    return <></>;
  };

  const returnKey = (key: string) => {
    if (config.relevantKeys.includes(key)) {
      let edited: string = key[0].toUpperCase().concat(key.substring(1));

      return edited;
    }
  };

  const returnValue = (val: any, k: string) => {
    if (config.relevantKeys.includes(k)) {
      return isContact(k) ? { phone: val?.phone, email: val?.email } : val;
    }
  };

  const isContact = (key: any) => {
    return key === `contact` ? true : false;
  };

  useEffect(() => {
    let stored: any = localStorage.getItem('@companyItem');

    if (stored) {
      let p: any = JSON.parse(stored);

      saveCompItemGlobally(p);
    }
  }, []);

  useEffect(() => {
    getTheInstallers();
  }, []);

  useEffect(() => {
    if (orders.orderInstallersListByLocation) {
      setCurrent(`location`);
    } else if (orders.orderInstallersListByDevs) {
      setCurrent(`devs`);
    } else if (orders.orderInstallersListByName) {
      setCurrent(`name`);
    } else if (
      orderedInstallersList &&
      loadedInstallers &&
      initList.length > 0
    ) {
      setCurrent(``);
    }
  }, [
    installersCounter,
    installersIntendedCounter,
    orderedInstallersList,
    orders,
  ]);

  useEffect(() => {
    return () => {
      handleInstallersListCounterAndOrder(config.resettedCounter, 0);
    };
  }, []);

  //preserves the default order
  useEffect(() => {
    if (initList && initList.length > 0) {
      let savedDefaultArr: any = [];
      initList.forEach((item: any) => {
        savedDefaultArr.push(item);
      });
      setUnorderedDefault(savedDefaultArr);
    }
  }, [initList]);

  return (
    <Template
      id="companies-installers-list"
      title={config.strings.title1}
      goBack={() => {
        history.push('/admin/dashboard/companies-list');
      }}
    >
      <div className="container cnt1" id="comp-and-installers-1">
        <h6> {config.strings?.companyData}</h6>

        <div className="row">
          <div className="col-12 cnt1">
            <div className="card no-visible-border">
              <div className="card-body pdg5">
                <h5 className="card-title">{compItem?.name} </h5>
                <h6 className="card-subtitle mb-2 text-muted"></h6>

                <div className="card-text">
                  <div className="container cnt1">
                    <div className="row">
                      {Object.entries<any>(filterRelevantProps(compItem))
                        .slice(
                          0,
                          Object.keys(filterRelevantProps(compItem)).length / 2,
                        )
                        .map(([key, value], i) => {
                          return functions.isContact(key) ? (
                            <div
                              className="col-xs-12 col-sm-12 col-md-6 cnt1 ptpb2"
                              key={i}
                            >
                              <span
                                className={`lead lead--smaller ftw600 label-${classes[key]}`}
                              >
                                {functions.returnKey(key)}s
                              </span>
                              <div
                                className={`form-control input-alike ${classes[key]}`}
                              >
                                {value?.phone} | {value?.email}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="col-xs-12 col-sm-12 col-md-6 cnt1 ptpb2"
                              key={i}
                            >
                              <span
                                className={`lead lead--smaller ftw600 label-${classes[key]}`}
                              >
                                {functions.returnKey(key)}
                              </span>
                              <div
                                className={`form-control input-alike ${classes[key]}`}
                              >
                                {key === `updatedAt` || key === `createdAt` ? (
                                  <span className="lead lead--smaller">
                                    {functions.formatDate(value)}
                                  </span>
                                ) : (
                                  <span className="lead lead--smaller">
                                    {value}
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}

                      {Object.entries<any>(filterRelevantProps(compItem))
                        .slice(
                          Object.keys(filterRelevantProps(compItem)).length / 2,
                        )
                        .map(([key, value], i) => {
                          return (
                            !functions.isContact(key) && (
                              <div
                                className="col-xs-12 col-sm-12 col-md-6 cnt1 ptpb2"
                                key={i}
                              >
                                <span
                                  className={`lead lead--smaller ftw600 label-${classes[key]}`}
                                >
                                  {functions.returnKey(key)}
                                </span>
                                <div
                                  className={`form-control input-alike ${classes[key]}`}
                                >
                                  {key === `updatedAt` ||
                                  key === `createdAt` ? (
                                    <span className="lead lead--smaller">
                                      {functions.formatDate(value)}
                                    </span>
                                  ) : (
                                    <span className="lead lead--smaller">
                                      {value}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )
                          );
                        })}
                      <Fragment>
                        {config?.info.map((elem: any, index: number) => {
                          return (
                            <div
                              className="col-xs-12 col-sm-12 col-md-6 cnt1 ptpb2"
                              key={index}
                            >
                              <span
                                className={`lead lead--smaller ftw600 label-${elem.key} `}
                              >
                                {elem.label}
                              </span>

                              <div
                                className={`form-control input-alike ${
                                  classes[elem.key]
                                }`}
                              >
                                <span className="lead lead--smaller">
                                  {elem.label === `Phone`
                                    ? filterRelevantProps(compItem)?.contact
                                        ?.phone
                                    : filterRelevantProps(compItem)?.contact
                                        ?.email}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="hr--list--separator" />

          <div className="row pdg">
            <div className="col-xs-12 col-sm-12 col-md-10 pdg">
              <h6>{config.h6Title}</h6>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-2 pdg">
              <button className="btn btn-installer1" onClick={addInstaller}>
                <IoAdd className="svg-inst1 btn--inst--ft" />
                <span className="btn-installer-label">
                  {config.strings.btn}
                </span>
              </button>
            </div>
          </div>

          <div ref={goToListRef} className="pdg">
            <List
              id="companies-list-l001-2"
              rowClasses={config.rowClasses}
              itemsPerPage={10}
              list={installers}
              loaded={loadedInstallers}
              current={current}
              orders={orders}
              manageListOrder={handleInstallersListCounterAndOrder}
              orderedList={orderedInstallersList}
              sectionCounter={installersIntendedCounter}
              sectionCount={installersCounter}
              updateOrderState={updateOrderState}
              incremented={incremented}
              unorderedDefault={unorderedDefault}
              handles={{}}
              config={config}
              firstFun={(item: any) => {}}
              secondFun={handleView}
              fourthFunction={viewInstalledDevs}
              setItem={(item: any) => {
                saveInstallerItemGlobally(item);
              }}
              retrieveColValue={retrieveColValue}
              returnAccordionDetails={returnAccordionDetails}
              helperOrderData={[]}
            />
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`delete-installer-modal-id`}
        title={config.modal.delete.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToDel}
        /*
         * handle modal state after delete an installer will use these states:     
           modalMustClose={modalMustClose}
           handleModalState={handleModalState}   
         */
        //delete installer when an endpoint is available
        onClick={() => {}}
        modalMustClose={false}
        handleModalState={() => {}}
        label={config?.modalStrings?.labelOK}
        className={`small-button nok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
      />

      <Modal
        config={config}
        id={`view-installer-modal-id`}
        title={config.modal.view.title}
        icon={<RxMagnifyingGlass className="edit" />}
        body={
          <>
            <div className="card" id="the-card-lc">
              <div className="card-body">
                <h5 className="card-title">{config.modal.view.sectionTitle}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {config.modal.view.sectionSubtitle}
                </h6>
                <div className="card-text">
                  <ul className="list-group installers-view-modal">
                    {Object.keys(installerItem).length > 0 &&
                      Object.entries<any>(installerItem)
                        .filter(
                          ([key, value]) =>
                            config.relevantKeys.indexOf(key) > -1,
                        )
                        .map(([key, value], idx) => {
                          if (key === 'contact') return null;

                          return (
                            <div className="container pdg break-w" key={idx}>
                              <div className="row pdg contents">
                                <span className={`lead lead--smaller ftw600`}>
                                  {returnKey(key)}
                                </span>

                                <div
                                  className={`form-control input-alike ${classes[key]}`}
                                >
                                  {!isContact(key) && (
                                    <span className="lead span-key-040">
                                      {returnValue(value, key)}{' '}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    {config._data.map((elem: any, index: number) => {
                      return (
                        <div className="container pdg break-w" key={index}>
                          <div className="row pdg contents">
                            <span className={`lead lead--smaller ftw600`}>
                              {returnKey(elem.keyArg)} {elem.label}
                            </span>

                            <div
                              className={`form-control input-alike ${
                                classes[elem.key]
                              }`}
                            >
                              <span className={elem.spanClass3}>
                                {elem.label === `Phone`
                                  ? installerItem?.contact?.phone
                                  : installerItem?.contact?.email}
                              </span>
                            </div>
                          </div>{' '}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        }
        onClick={() => {}}
        modalMustClose={false}
        handleModalState={() => {}}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={``}
        hasConfirmBtn={false}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminCompaniesState, adminInstallersState } = state;
  return {
    installerItem: adminInstallersState.installerItem,
    compItem: adminCompaniesState.compItem,
    installersCounter: adminInstallersState.installersCounter,
    installersIntendedCounter: adminInstallersState.installersIntendedCounter,
    orderedInstallersList: adminInstallersState.orderedInstallersList,
    orders: adminInstallersState.orders,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallersState, adminCompaniesState } = dispatch;
  return {
    defInstallerToEdit: adminInstallersState.defInstallerToEdit,
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
    updateOrderState: adminInstallersState.updateOrderState,
    handleInstallersListCounterAndOrder:
      adminInstallersState.handleInstallersListCounterAndOrder,
    saveInstallerItemGlobally: adminInstallersState.saveInstallerItemGlobally,
    setToEdit: adminInstallersState.setToEdit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Installers);
