interface ObjType {
  [key: string]: string;
}

const relevantProps: string[] = [
  'name',
  'latitude',
  'longitude',
  'zip',
  'city',
  'state',
  'country',
  'settings',
];

const renderSuitableError = (type: string, hasError: boolean) => {
  if (hasError) {
    switch (type) {
      case 'flowMeasuredHeatCall':
      case 'flowMeasuredCoolingCall':
      case 'flowMeasuredFanOnlyCall':
      case 'requiredContinuousAirFlow':
      case 'exhaustFanFlowRate':
        return `Value not allowed, it cannot exceed 250 CFM.`;
      case 'exhaustFanDelayTime':
      case 'adMxError':
      case 'minutesPerHourSupplyFlow':
      case 'minutesPerHourExhaustRun':
      case 'additionalMixTime':
        return 'Please, select a value between 0 and 60.';
      case 'hourStart':
        return 'Please, select a value between 0 and 23 hours.';
      case 'hourStop':
        return 'Please, select a value between 0 and 23 hours.';
      default:
        break;
    }
  } else {
    switch (type) {
      case 'exhaustFanDelayTime':
      case 'flowMeasuredFanOnlyCall':
      case 'requiredContinuousAirFlow':
      case 'exhaustFanFlowRate':
      case 'adMxError':
      case 'minutesPerHourSupplyFlow':
      case 'minutesPerHourExhaustRun':
      case 'flowMeasuredHeatCall':
      case 'flowMeasuredCoolingCall':
      case 'hourError':
      case 'endHourError':
      case 'additionalMixTime':
        return '';
      default:
        break;
    }
  }
};

const checkTypeOfDev = (item: any) => {
  if (
    item?.hasOwnProperty('device_id') &&
    (item?.hasOwnProperty('uuid') ||
      (item?.hasOwnProperty('details') &&
        item?.details?.hasOwnProperty('uuid')) ||
      item.hasOwnProperty('health') ||
      item?.hasOwnProperty('mac') ||
      item.hasOwnProperty('datapoint'))
  ) {
    return 'sensor';
  } else if (
    item?.hasOwnProperty('device_id') &&
    !item?.hasOwnProperty('uuid') &&
    !item.hasOwnProperty('health')
  ) {
    return 'noSensor';
  }
};

const filterObject = (ob: any) => {
  return Object.keys(ob)
    .filter(key => relevantProps.includes(key))
    .reduce((obj: ObjType, key: string) => {
      obj[key] = ob[key];
      return obj;
    }, {});
};

const rmvUnecessProps = (item: any) => {
  //handle the cases where the dev has no location props defined, so there is nothing to filter

  if (
    !item.hasOwnProperty('device_state') ||
    !item.hasOwnProperty('device_city') ||
    !item.hasOwnProperty('device_zipcode')
  ) {
    return item;
  }

  let baseState = {
    device_state: filterObject(item?.device_state) || {},
    device_city: filterObject(item?.device_city) || {},
    device_zipcode: filterObject(item?.device_zipcode) || {},
  };

  let _newItem = {};

  if (item) {
    _newItem = { ...item, ...baseState };
  }

  return _newItem;
};

const orderItemProperties = (item: any, type: string) => {
  let orderedItem;

  switch (type) {
    case 'company':
      orderedItem = {
        name: item?.name || '',
        code: item?.code || '',
        phone: item?.contact?.phone || '',
        email: item?.contact?.email || '',
        state: item?.state || '',
        city: item?.city || '',
        zipcode: item?.zipcode || '',
        status: item?.status || '',
      };

      return orderedItem;
    case 'user':
      orderedItem = {
        first_name: item?.first_name || '',
        last_name: item?.last_name || '',
        email: item?.email || '',
        address_line_one:
          item?.addresses &&
          item?.addresses?.length > 0 &&
          item?.addresses[0].address_line_one
            ? item?.addresses[0].address_line_one
            : '',
        address_line_two:
          item?.addresses &&
          item?.addresses?.length > 0 &&
          item?.addresses[0].address_line_one &&
          item?.addresses[0].address_line_two
            ? item?.addresses[0].address_line_two
            : '',

        state:
          item?.addresses &&
          item?.addresses?.length > 0 &&
          item?.addresses[0]?.state
            ? item?.addresses[0]?.state
            : '',

        city:
          item?.addresses &&
          item?.addresses?.length > 0 &&
          item?.addresses[0]?.city
            ? item?.addresses[0]?.city
            : '',
        zip_code:
          item?.addresses &&
          item?.addresses?.length > 0 &&
          item?.addresses[0]?.zip_code
            ? item?.addresses[0]?.zip_code
            : '',
        phone:
          item?.phones &&
          item?.phones?.length > 0 &&
          item?.phones[0] &&
          item?.phones[0].hasOwnProperty('phone')
            ? item?.phones[0]?.phone
            : '',

        //item?.picture?.profile?.url || '';
        picture:
          item?.picture && item?.picture?.profile && item?.picture?.profile?.url
            ? item?.picture?.profile?.url
            : '',
        status: item?.status || '',
      };

      return orderedItem;

    case 'device':
      orderedItem = {
        device_name: item?.device_name || '',
        device_type: item?.device_type || '',
        isSystem: item?.isSystem || '',
        status: item?.status || '',
        device_city: item?.device_city || '',
        device_state: item?.device_state || '',
        device_zipcode: item?.device_zipcode || '',
        details: item?.details || {},
      };

      return orderedItem;

    case 'sensor':
      orderedItem = {
        name: item?.name || '',
        device_id: item?.device_id || '',
        uuid: item?.uuid || '',
        mac: item?.mac || '',
        datapoint: item?.datapoint ? item?.datapoint : {},
        airQuality: item?.airQuality ? item?.airQuality : [],
        health: item?.health ? item?.health : {},
      };

      return orderedItem;

    default:
      return item;
  }
};

export const functions = {
  renderSuitableError: renderSuitableError,
  orderItemProperties: orderItemProperties,
  checkTypeOfDev: checkTypeOfDev,
  rmvUnecessProps: rmvUnecessProps,
};
